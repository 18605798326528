@import "../../../../styles/font-mixins";

.container {
  width: 100%;
  font-size: 14px;
  text-align: left;
  table-layout: fixed;
  border-collapse: separate;
  border: none;
  border-spacing: 0;
  overflow: hidden;

  th,
  td,
  tr {
    font-weight: normal;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tbody {
    overflow-y: auto;
  }

  thead {
    position: sticky;
  }

  tbody tr {
    height: 40px;
  }

  td {
    min-height: 40px;
    padding: 0 8px;
  }

  th {
    height: 28px;
    @include denseMedium;
    border-left: 1px solid var(--blue10);
    padding: 1 8px;
  }
}
