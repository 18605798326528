@import "../../../../../styles/font-mixins";

.row {
  display: flex;
  align-items: center;
}

.description {
  @include componentDense;
  color: var(--blue50);
}
