.dependencies {
  height: var(--s24);
  width: 100%;
  display: flex;
  align-items: center;
}

.first_dependency {
  height: 100%;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.more_dependencies {
  margin-left: auto;
}
