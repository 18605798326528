.product_home {
  display: flex;
  gap: 32px;
  height: calc(100% - 82px);
  // (82px = height of the PageTitle component)
}

.column {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-grow: 1;
  overflow-y: hidden;
  padding-right: 1px;
  padding-left: 1px;
}

.column_header {
  display: flex;
  align-items: center;
}

.properties {
  background-color: var(--grey0);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;
}

.title {
  :global(.bp4-icon) {
    margin-bottom: 4px;
  }
}

.activities {
  overflow-y: auto;
  margin-bottom: 0;
}
