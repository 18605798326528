.initial {
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color100);
  border-radius: 50%;
  color: var(--color100);
  background-color: white;
  font-size: 14px;
  width: 36px;
  height: 36px;

  &:not(.size--small) {
    margin-right: 8px;
  }

  &.size--small {
    margin-right: 4px;
  }

  &:not(.inline) {
    display: flex;
    flex-shrink: 0;
  }

  &.inline {
    display: inline-flex;
  }
}

.size--default {
  width: 36px;
  height: 36px;
  font-size: 20px;
  font-weight: 600;
}

.size--dense {
  width: 28px;
  height: 28px;
  font-weight: 600;
  font-size: 15px;
}

.size--small {
  width: 20px;
  height: 20px;
  font-weight: 600;
  font-size: 11px;
}
