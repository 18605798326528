.form {
  width: 660px;
}

.form_content {
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 32px;
  }
}

.file_input_container {
  align-self: center;
}

.file_input {
  position: relative;

  min-height: 100px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 8px;

  font-size: 12px;

  border: 1px solid var(--blue20);
  border-radius: 8px;
}

.actionnable {
  cursor: pointer;
}

.file_input_icon {
  color: var(--blue40);
}

.file_input_reset {
  position: absolute;
  top: 4px;
  right: 4px;
}

.file_input_text {
  text-align: center;
  word-break: break-word;
}

.form_icon {
  color: var(--blue40);
}

.form_options {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;

  > * {
    margin-bottom: 0;
  }
}

.switch {
  height: unset;
  line-height: unset;
  display: flex;
  align-items: center;
}

.form_section_header {
  font-weight: 500;
  border-bottom: 1px solid var(--blue20);
  margin-bottom: 8px;
}

.form_content_section {
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 16px;
  }
}

.select_row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.select_row_label {
  flex: 1;
}

.select_row_action {
  width: 350px;
}
