.searchbar {
  min-width: 140px;
}

.page {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
}

div table .row {
  tr,
  td {
    background-color: var(--grey0);
  }
}

th.owner_cell,
td.owner_cell {
  width: 20%;
}

th.date_cell,
td.date_cell {
  width: 15%;
}

th.status_cell,
td.status_cell {
  width: 175px;
}

.filterWrapper {
  display: flex;
  align-items: center;
}

.list {
  height: 100%;
  display: flex;
  flex-direction: column;

  .virtualizedList {
    height: 100%;
    margin-bottom: 10px;
  }
}

th.filler_th {
  padding: 0;
  border-left: none;
}
