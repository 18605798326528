@import "../../../../styles/font-mixins";

.table_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.subtitle {
  @include subtitle;
  margin-top: 2px;
}
