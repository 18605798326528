.table {
  display: flex;
  flex-direction: column;
  border-spacing: 0;
  width: 100%;
  min-height: 0;
}

.revision_name {
  margin-right: 10px;
}

.create_button {
  margin-top: 24px;
  display: flex;
}
