.summary {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.count_wrapper {
  display: flex;
  align-items: center;
  width: 13%;
}

.count {
  margin: 0 5px 0 0px;
}

.workflow_count {
  display: flex;
  align-items: center;
  width: 35%;
  flex-grow: 0;
}

.bar {
  flex-grow: 0;
  height: 28px;
  display: flex;
  border-radius: 4px;
  margin-right: 8px;
  overflow: hidden;
  width: 13%;

  > div {
    height: 100%;
  }

  .done {
    background-color: var(--green30);
  }

  .started {
    background-color: var(--yellow30);
  }

  .not_started {
    background-color: var(--red30);
    display: flex;
    flex-grow: 1;
  }
}
