@import "../../../../styles/font-mixins";

.formContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .formFields {
    margin-right: 80px;
    flex: 2;
  }
}

.popupWarning {
  margin-bottom: 20px;
}

.toaster_title {
  margin-top: 0;
}

.name_used {
  color: var(--red50);
  margin-left: 10px;
}

.actionsContainerTable {
  display: flex;
  top: 0px;
  right: 0px;
  margin-bottom: 20px;
}

.error_toast_msg {
  @include paragraph;
}
