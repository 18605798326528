@import "./font-mixins";

html {
  font-size: 100%;
}

body {
  > * :focus {
    outline: none;
  }
  @include paragraph;
}
