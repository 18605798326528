@import "../../../styles/font-mixins";

.container {
  font-size: 14px;
  line-height: 150%;

  &:global(.bp4-card) {
    padding: 8px;
    box-shadow: none;
  }

  em {
    font-style: normal;
    color: var(--grey50);
    font-weight: 500;
  }

  a {
    margin-left: 5px;
  }
}

.unread {
  background-color: var(--grey0);
}

.read {
  background-color: white;
}

.header {
  display: flex;
  margin-bottom: 10px;
}

.date {
  @include description;
}

.new {
  margin-top: 7px;
  margin-left: 16px;
}

.content {
  display: inline;
  line-height: 1.6;
  overflow-wrap: break-word;

  a {
    font-weight: 500;
  }
}

.empty {
  background-color: var(--grey0);
  height: 100%;
}
