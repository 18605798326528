
@import "../../../styles/font-mixins";

.deleted {
  color: var(--grey60);
  padding: 15px;
}

.drawer {
  padding: 0 16px;

  :global(.bp4-tab-panel) {
    margin-top: 16px;
  }

  .divider {
    margin: 16px 0 !important;
  }
}

.header_buttons {
  margin-left: 10px;
}

.properties {
  display: flex;

  .img {
    display: block;
    width: 160px;
    margin-right: 16px;
    flex: none;
  }

  .properties_inner {
    width: 100%;
  }
}

.tabs {
  margin-top: 16px;

  :global(.bp4-tab) {
    // overide padding as otherwise it will be larger than the panel
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
