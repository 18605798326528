.hover_button:global(.bp4-button) {
  transition: none;
  visibility: visible;
}

.no_data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create_button {
  margin-top: 24px;
  display: flex;
}
