@import "../../styles/font-mixins";

.container {
  display: flex;
  align-items: center;
  background-color: #000f55;
  height: 50px;
  padding: 0 16px;
  width: 100%;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);

  > * {
    margin-right: 10px;
  }

  > :last-child {
    margin-right: 0;
  }
}

.align_right {
  margin-left: auto;
  margin-right: 0;
}

.logo {
  cursor: pointer;
  height: 36px;
  width: auto;
  margin-right: 5px;
  vertical-align: middle;
}

.button_indicator_wrapper {
  position: relative;
}

.button_indicator_indicator {
  background-color: var(--red60);
  border-radius: 1rem;
  height: 16px;
  width: 16px;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 14px;
  line-height: 16px;
  pointer-events: none;
}

.divider {
  margin: 8px !important;
  width: inherit;
}

.dividerTitle {
  @include inputDescription;
  color: var(--grey40);
  margin: 8px;
  margin-top: 0px;
}

.dividerTitle:global(.bp4-menu-header) {
  margin: 0px 0px 8px 0px !important;

  h6:global(.bp4-heading) {
    @include inputDescription;
    color: var(--grey40);
  }
}

.userMenu {
  padding: 8px 0px;

  .menuTitle {
    @include inputDescription;
    color: var(--grey40);
    margin: 0px 8px;
  }
}

.menuItem {
  svg {
    color: var(--blue100);
  }
}

.arrow:global(.bp4-button) {
  padding-right: 16px;
}

.badge {
  margin-bottom: 4px;
  margin-left: 16px;
}

.search_history_label {
  @include subtitle;
}

.search_history_entry {
  :global(.bp4-icon) svg {
    fill: var(--blue100);
  }
}

.search_history,
.searchbar {
  width: 300px;
}
