@import "../../../../styles/font-mixins";

.dialog {
  &:global(.bp4-dialog) {
    width: 600px;
  }

  .checkbox_wrapper {
    margin-top: 20px;
  }
}

.checkbox_label {
  @include inputDescription;
}
