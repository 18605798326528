@import "../../../../styles/font-mixins";

.dialog {
  &:global(.bp4-dialog) {
    width: fit-content;
  }

  .dialog_contents > * + * {
    margin-top: 16px;
  }

  .tab {
    height: 40vh;
    overflow-y: auto;
  }
}
