
@import "../../../styles/font-mixins";

.drawer_body {
  padding: 0 16px;

  :global(.bp4-tab-panel) {
    margin-top: 16px;
  }

  .divider {
    margin: 16px 0 !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  .input {
    width: 264px;
  }

  .h2 {
    @include H2;
    color: var(--blue100);
    margin: 0;
  }
}

.properties {
  display: flex;

  .img {
    display: block;
    width: 160px;
    margin-right: 16px;
    flex: none;
  }

  .properties_inner {
    width: 100%;
  }
}
