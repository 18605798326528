@import "../../../../../styles/font-mixins";

.menu_section {
  @include subtitle;
}

.first_menu_section:global(.bp4-menu-header) {
  margin-top: unset !important;
  padding-top: unset !important;
}
