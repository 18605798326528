@import "../../styles/font-mixins";

.content {
  h1,
  h2,
  code,
  pre,
  div,
  span,
  h3,
  h4,
  h5,
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
  }
  h1 {
    line-height: 36px;
  }
  h2 {
    line-height: 30px;
  }
  pre {
    margin: 0px;
  }

  > :first-child {
    margin-top: 0px;
  }

  p {
    margin-bottom: 4px;
  }
}

.paddingLeft {
  padding-left: 16px;
}

.suggestion {
  @include denseMedium;
  color: var(--grey50);
}
