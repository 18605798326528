.deleted {
  color: var(--grey60);
}

.drawer {
  padding: 0 16px;

  :global(.bp4-tab-panel) {
    margin-top: 16px;
  }
}

.divider {
  margin: 16px 0 !important;
}

.properties {
  display: flex;

  .img {
    display: block;
    width: 160px;
    margin-right: 16px;
    flex: none;
  }

  .properties_inner {
    width: 100%;
  }
}

.tabs {
  div[class*="bp4-tab"][id*="bp4-tab-title"] {
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
  }
}
