.owners {
  width: 100%;
  display: flex;
  align-items: center;
}

.name_cell {
  padding: 6px 10px !important;
  display: flex;
  align-items: center;
}

.drag_icon {
  margin-right: 15px;

  svg {
    color: var(--grey30);
  }
}

.name_wrapper {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
