@import "../../../../styles/font-mixins";

.dialog {
  overflow-y: hidden !important;
}

.progress_bar {
  margin-top: 8px;
}

.progress_message {
  @include inputDescriptionDense;
  color: var(--color50);
}

.error_message {
  @include paragraph;
  color: var(--color100);
}

.attribute_wrapper {
  margin-top: 30px;
}

.table_header {
  margin-top: 16px;
  margin-bottom: 8px;
}

.table_wrapper {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.table,
.table_header {
  table-layout: fixed;

  .tag_col {
    width: 100px;
  }

  .revision_col {
    width: 15%;
  }

  th {
    padding-left: 8px;
  }
}

.batch_action {
  display: flex;
  align-items: center;

  > :global(.bp4-popover2-target),
  :global(.bp4-popover-wrapper) {
    margin-left: auto;
  }
}

.name_td {
  display: flex;
  align-items: center;

  &.ignore {
    color: var(--blue30);
  }

  :nth-child(2) {
    max-width: calc(100% - 27px);
  }
}

.duplicate_index_row {
  td {
    vertical-align: top;
    padding-top: 10px;
  }
}

.menu_section {
  @include subtitle;
}

.first_menu_section:global(.bp4-menu-header) {
  margin-top: unset !important;
  padding-top: unset !important;
}
