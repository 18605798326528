.dialog {
  &:global(.bp4-dialog) {
    width: 600px;
  }

  .searchInput {
    margin: 20px 0px;
  }

  .tab {
    height: 420px;
  }
}
