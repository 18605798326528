@import "../../../styles/font-mixins";

.subtitle {
  @include subtitle;
}

.list {
  margin-top: 0;
  margin-bottom: 16px;

  & > * {
    margin-bottom: 8px;
  }
}

.next_occurence {
  margin-bottom: 13px;
}
