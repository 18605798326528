@import "../../../../styles/font-mixins";

.workflow {
  @include paragraph;
  background-color: var(--grey0);
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 0 8px;
}

.header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .flex {
    display: flex;
    align-items: center;
    max-width: 400px;
  }
}

.fold_icon {
  cursor: pointer;
}

.title {
  font-size: 14px;
  font-weight: 700;
  color: inherit;
  margin: 0;
}

.menu_target {
  margin-left: auto;
}

.table {
  overflow: hidden;
  width: 100%;
  table-layout: fixed;
  margin-top: 8px;
  padding-right: 0;
  padding-bottom: 4px;
}

.row {
  background-color: white;
}
