.container {
  overflow-y: auto;
  min-height: 200px;
  max-height: 600px;
  width: 400px;
  padding: 5px;

  > :not(:last-child) {
    margin-bottom: 5px;
  }
}
