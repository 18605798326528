@import "../../styles/font-mixins";

.tree {
  position: relative;
  height: calc(100% - 40px);

  &.loading {
    overflow-y: hidden;
  }
}

.spin_tile {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  opacity: 0.7;
  background-color: white;
}

.highlighted {
  color: var(--blue50);
}

.column_header {
  height: 28px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  @include denseMedium;
  color: var(--blue100);
  border-left: 1px solid var(--blue10);
}

.columns {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 1px;

  > * {
    width: 50%;
  }
}

.button_group {
  > * {
    margin-left: 8px;
  }
}

.white_text {
  color: white !important;
}
