@import "../../styles/font-mixins";

.row {
  display: flex;
  align-items: center;
}

.description {
  @include componentDense;
  color: var(--blue50);
}

.edit_default {
  font-size: 12px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}
