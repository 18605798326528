.suggestion {
  color: var(--grey40);
}

.new {
  border-radius: 5px;
  border: 1px solid var(--grey40);
  padding: 8px;
  margin-bottom: 8px;
}

.edit {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid var(--grey40);

  :global(.bp4-button) {
    z-index: 0 !important;
  }
}

.flex {
  display: flex;
}

.editor {
  padding: 8px 5px;
  min-height: 100px;
  cursor: text;
  background-color: white;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.menu {
  border-radius: 5px;
  max-height: 250px;
  overflow-y: auto;
  color: var(--grey80);
  margin: 0;
  padding: 5px;
}
.menuHeader {
  border-radius: 2px;
  line-height: 20px;
  padding: 5px 7px;
  padding-top: 0px;
}

.menuItem {
  border-radius: 2px;
  line-height: 20px;
  padding: 5px 7px;

  &:hover {
    // Blueprint color
    background-color: rgba(#a7b6c2, 0.3);
  }
}
.mention {
  color: var(--grey40);
  position: relative;
}

.mentionEditWrapper {
  color: transparent;
  position: relative;
  display: inline-block;
}

.mentionEdit {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--grey40);
}

.draftH1 {
  line-height: 30px;
}

.draftH2 {
  line-height: 25px;
}
