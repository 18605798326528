:root {
  --step: 0.25rem;
  /* BREAKPOINTS */
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;

  /* SIZE STEPS */
  --s0: 0;
  --s1px: 1px;
  --sAuto: auto;
  --s100vh: 100vh;
  --s100vw: 100vw;
  --sFull: 100%;

  /*Percents*/
  --s10p: 10%;
  --s20p: 20%;
  --s25p: 25%;
  --s30p: 30%;
  --s33p: 33.333333%;
  --s40p: 40%;
  --s50p: 50%;
  --s60p: 60%;
  --s66p: 66.666667%;
  --s70p: 70%;
  --s75p: 75%;
  --s80p: 80%;
  --s90p: 90%;

  /*Steps*/
  --s4: 4px;
  --s8: 8px; /*8px*/
  --s12: 12px;
  --s16: 16px;
  --s20: 20px;
  --s24: 24px;
  --s28: 28px;
  --s32: 32px;
  --s36: 36px;
  --s40: 40px;
  --s44: 44px;
  --s48: 48px;
  --s52: 52px;
  --s56: 56px;
  --s60: 60px;
  --s64: 64px;
  --s68: 68px;
  --s72: 72px;
  --s76: 76px;
  --s80: 80px;
  --s84: 84px;
  --s88: 88px;
  --s92: 92px;
  --s96: 96px;
  --s100: 100px; /*100px*/
  --s128: 128px;
  --s160: 160px;
}
