.popover {
  .label {
    color: var(--grey50);
  }

  .multi_select_wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .filter_wrapper {
    display: flex;
    margin-bottom: 10px;
    p:first-child {
      color: var(--grey50);
      margin: auto 10px auto 0;
    }
    button {
      margin: 0;
    }
  }

  .table {
    width: 100%;
    th,
    tr,
    td {
      height: 30px !important;
      padding: 4px 0 !important;
    }
  }

  .action_cell {
    width: 50px;
  }

  .cell_content {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .unlink_button {
    margin-left: auto;
  }

  .link {
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  .tab {
    > :not(:first-child):not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .entity_list {
    width: 100%;
    display: flex;
    align-items: center;

    span,
    a {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    :global(.bp4-button-group) {
      margin-left: auto;
    }
  }

  .popoverContent {
    display: flex;

    .left_menu {
      width: 200px;
      display: flex;
      flex-direction: column;

      .divider {
        margin-top: auto;
      }
    }

    .panel {
      width: 300px;

      .search {
        padding: 5px;
        padding-bottom: 2px;
      }

      .menu {
        height: 260px;
        overflow-y: auto;
        padding-top: 3px;
      }

      .callout {
        width: 280px;
        margin: 8px;
      }
    }
  }
}
