.page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.tabs {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.tab_panel {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.callout {
  margin-bottom: 24px;
}
