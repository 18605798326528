.dialog {
  width: 600px;

  .maxWidth {
    width: 300px;
  }
}

.targets {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
