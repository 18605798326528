@import "../../../styles/font-mixins";

.select:global(.bp4-button) {
  font-weight: 400;
  font-size: 16px;
}

.select:global(.bp4-button):not(:global(.bp4-disabled)) {
  color: var(--color100);

  &:hover {
    color: var((--color70));
    box-shadow: 0 0 0 1px var(--color70);
  }

  svg {
    color: var(--blue30);
  }
}

.select:global(.bp4-button):global(.bp4-disabled) {
  background-color: white;
  box-shadow: 0 0 0 1px var(--color30);

  span,
  svg {
    color: var(--blue30);
  }
}
