@import "../../../../styles/font-mixins";

.dialog {
  width: 600px;
}

.container {
  > :not(:last-child) {
    margin-bottom: 20px;
  }

  .validation {
    display: flex;
    align-items: center;
    column-gap: 80px;

    .radio {
      display: flex;
      align-items: center;

      :global(.bp4-tag) {
        margin-left: 5px;
      }
    }
  }
}
