@import "../../styles/font-mixins";

.revision_tag {
  &:global(.bp4-tag) {
    display: flex;
    align-items: center;
    padding: 0 6px !important;
    font-size: 19;

    svg {
      margin-top: 1px;
    }

    span {
      font-size: 18;
      font-weight: normal;
    }
  }
}

.content {
  @include paragraph;
  color: var(--grey70);

  .center {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .schema {
      display: flex;
      margin-top: 16px;

      .icon svg {
        margin: 0px 16px;
        color: var(--color40);
      }
    }
  }

  .callout {
    margin-bottom: 16px;
  }
}
