@import "../../../../styles/font-mixins";

.dialog {
  width: 800px;
  max-width: 800px;

  .innerDialog {
    max-height: 70vh;
    overflow-y: auto;

    .array {
      &:not(:first-child) {
        margin-top: 20px;
      }

      &__header {
        @include defaultMedium;
        color: var(--color100);
        display: flex;
        align-items: center;
        vertical-align: middle;
      }

      .divider {
        margin-top: 8px !important;
        margin-bottom: 16px !important;
      }

      .row {
        margin-bottom: 8px;
      }
    }
  }
}
