@import "../../../../styles/font-mixins";

.project_list_item {
  padding: 14px 10px;
  background-color: var(--grey0);
  border-radius: 4px;
  margin-bottom: 10px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    height: 20px;
  }

  .versions {
    display: flex;
    align-items: baseline;
  }

  .version_list {
    margin-left: 7px;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;

    > * {
      margin-right: 5px;
    }
  }

  .label {
    @include paragraph;
    color: var(--grey50);
    min-width: 130px;
  }

  .version {
    @include denseRegular;
  }

  .bill {
    @include denseRegular;
    line-height: 24px;
  }
}

.link_product_button {
  margin-bottom: 16px;
}
