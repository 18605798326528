.stepperWrapper {
  display: flex;
  justify-content: space-around;

  .stepper {
    display: flex;
    align-items: center;
  }
}

.popover {
  &:global(.bp4-popover2) {
    margin-bottom: -24px !important;
  }
}
