.header {
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;

  color: var(--blue40);
}

.table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  margin-bottom: 8px;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.number_col {
  width: 110px;
  flex: none;
}
.name_col {
  flex: 1;
}
