@import "../../../../styles/font-mixins";

.columns {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 1px;

  > * {
    width: 50%;
  }
}

.column_header {
  height: 28px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  @include denseMedium;
  color: var(--blue100);
  border-left: 1px solid var(--blue10);
}

.part_count {
  line-height: 30px;
  margin-right: 5px;
}

.create_button {
  margin-left: 16px;
}

.unknown {
  color: var(--blue50);
}

.tree_wrapper {
  height: calc(100vh - 250px);
  overflow-y: auto;
}

.selectable {
  user-select: text !important;
  cursor: text;
}

.spinner {
  display: flex;
}