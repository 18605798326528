.grid {
  margin-top: 16px;
  box-sizing: border-box;
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 440px);
}

.center {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
