.content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.panel {
  flex: none;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 auto;
  padding: 0 32px 16px 32px;
  min-height: 100%;
}
