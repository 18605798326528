@import "../../../styles/font-mixins";

.contents {
  align-self: center;
  width: 100%;
  max-width: 800px;
}

.export_card {
  margin-bottom: 8px;
}

.export_info {
  display: flex;
  align-items: center;
  color: var(--grey50);
  margin-bottom: 16px;

  .expiration_date {
    margin-left: 16px;
  }
}

.archive_file_list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  > * + * {
    margin-top: 8px;
  }
}

.archive_file {
  @include denseMedium;
  height: 28px;
  padding-left: 8px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
}

.file_size {
  @include inputDescriptionDense;
  margin-left: 8px;
}

.date_range_container {
  display: flex;
  align-items: center;
  color: var(--grey50);

  svg {
    color: var(--grey50);
  }

  > :not(:last-child) {
    margin-right: 8px;
  }
}

.pending_message {
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }
}
