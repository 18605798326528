@import "../../styles/font-mixins";

.option_value {
  margin-left: 4px;
}

.first_line {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  gap: 40px;

  .right_col {
    flex: 1 1 auto;
  }
}

.dialog {
  width: 600px;
}

.description {
  margin-bottom: 16px;
}

.addOptions {
  margin-top: 8px;
}

.table {
  height: 40vh !important;
}

.subtitle {
  @include subtitle;
}

.delete_button:global(.bp4-disabled) {
  svg {
    fill: var(--color30);
  }

  :global(.bp4-button-text) {
    color: var(--color30);
  }
}
