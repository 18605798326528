@import "../../../../../styles/font-mixins";

.progress_bar {
  margin-top: 8px;
}

.progress_message {
  @include inputDescriptionDense;
  color: var(--color50);
}

.error_message {
  @include paragraph;
  color: var(--color100);
}
