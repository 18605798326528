.message {
  display: flex;
  gap: 8px;
  color: var(--grey50);
  align-items: center;
  font-size: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
