@import "../../../../styles/font-mixins";

.dialog {
  width: 600px;
}

.label {
  @include inputDescription;
  color: var(--grey50);
}

.text_area textarea {
  resize: vertical;
}

.caption {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  @include subtitle;
}

.name_version_wrapper {
  display: flex;
  margin-bottom: 10px;
}

.workflow_title {
  margin-top: 25px;
  display: flex;
  align-items: center;

  :global(.bp4-icon) {
    margin-right: 15px;
    svg {
      color: var(--color30);
    }
  }
}

.checkbox {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.tag {
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.dropdown {
  width: 100%;
}

.img {
  margin-right: 1rem;
  flex-shrink: 0;
}

.bill_definition_row {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  > div {
    flex-basis: 100%;
  }

  button {
    min-width: 100% !important;
  }
}
