@import "../../../../styles/font-mixins";

.dialog {
  overflow-y: hidden !important;
}

.progress_bar {
  margin-top: 8px;
}

.progress_message {
  @include inputDescriptionDense;
  color: var(--color50);
}

.error_message {
  @include paragraph;
  color: var(--color100);
}

.table_header {
  margin-top: 16px;
  margin-bottom: 8px;
}

.table_wrapper {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.table,
.table_header {
  color: var(--blue100);
  table-layout: fixed;

  .part_name,
  .project_desc {
    width: 20%;
  }

  .action {
    width: 15%;
  }

  .already_linked {
    width: 10%;
  }
}

.duplicate_name_row {
  td {
    vertical-align: top;
    padding-top: 10px;
  }
}

.product_count {
  color: var(--blue30);
  svg {
    color: var(--blue30);
  }
}
