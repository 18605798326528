@import "../../../../styles/font-mixins";

.list {
  margin-top: 16px;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
}

.row {
  @include paragraph;
  border-radius: 3px;
  background-color: var(--grey0);
  width: 100%;
  padding: 8px;

  .row_top {
    width: 100%;
    align-items: center;
    display: flex;

    .checkbox {
      margin-right: 8px;
    }

    .file_name {
      margin-right: 8px;
    }
  }

  .row_bottom {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 32px;
    margin-top: 16px;

    .label {
      @include subtitle;
      margin-right: 50px;
    }
  }
}

.actionbar_checkbox {
  &:global(.bp4-checkbox) {
    margin-left: 8px !important;
  }
}

.searchbar {
  width: 180px;
}

.empty_state {
  margin-top: 16;
}
