@import "../../../../../styles/font-mixins";

.header {
  @include H3;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.wrapper {
  grid-row: 1;
}

.card {
  height: 100%;
  padding: 16px;
  box-shadow: none;
  background-color: var(--grey0);

  .inner {
    position: relative;
    height: 100%;
  }

  .center {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
  }

  .no_data svg {
    color: var(--grey50);
  }
}
