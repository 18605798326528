

.link {
  display: inline;
}

.disabled {
  color: var(--blue30);
  cursor: default;

  :global(.bp4-icon) {
    svg {
      color: var(--blue30);
    }
  }
}
