@import "../../../../styles/font-mixins";


.container {
  width: 100%;

  .header {
    @include denseMedium;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 10px;
    color: var(--grey50);
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--grey0);
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 10px;
    height: 40px;

    .name {
      flex: 3 1 420px;
      white-space: nowrap;
      overflow: hidden;
    }

    .action {
      flex: 1 1 30px;
    }
  }

  .button:global(.bp4-button) {
    margin-right: 16px;
  }
}
