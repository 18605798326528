.container {
  display: flex;
  overflow-y: auto;
  width: 280px;
  flex-direction: column;
  padding: 16px;
  height: 100%;
  box-shadow: 0px 8px 16px 0px rgba(120, 120, 133, 0.16),
    0px 4px 8px 0px rgba(120, 120, 133, 0.16);
}

.tab {
  height: 28px;
  display: flex;
  align-items: center;

  span,
  svg {
    color: var(--color80);
  }

  &:hover {
    background-color: var(--color5) !important;
  }
}
