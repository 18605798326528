@import "../../../../styles/font-mixins";

.dialog {
  width: 800px;
}

.content {
  max-height: 60vh;
  overflow-y: auto;

  .wrapper {
    margin-bottom: 16px;

    .header {
      @include defaultMedium;
      color: var(--blue100);
      display: flex;
      align-items: center;
      vertical-align: middle;
      margin-bottom: 6px;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 1px 5px 8px;

      .left {
        display: flex;
        align-items: center;

        .icons {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 30px;
          height: 30px;
        }

        .infos {
          margin-left: 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .count {
            color: var(--grey40);
            font-size: 12px;
          }
        }
      }

      .buttonSpacing {
        margin-right: 8px;
      }
    }
  }
}
