:root {
  /*Fonts*/
  --font-primary: var(--standard);
  --font_primary: var(--standard);

  --font-secondary: var(--standard); /** Used for captions */
  --font_secondary: var(--standard);

  --font-family-button: var(--standard);
  --font_family_button: var(--standard);
}
