.shortcuts_header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    padding: 0;
    margin: 0;
  }
}

.shortcutsList {
  display: flex;
  flex-direction: column;
  margin: 20px;
  height: 360px;
  overflow: auto;
}

.card {
  padding: 8px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: var(--grey0);
  box-shadow: none;

  .row:not(:last-child) {
    margin-bottom: 10px;
  }
}

.search {
  margin-left: 8px;
  margin-right: 1px;
}
