.signature_dialog {
  width: 500px;
}

.signature_dialog_content {
  > * + * {
    margin-top: 16px;
  }
}

.signature_dialog_card_content {
  display: flex;
  align-items: center;
}

.signature_dialog_card_content_label {
  color: var(--blue50);
  min-width: 140px;
  font-size: 14;
  align-self: flex-start;
}

.radio_other {
  display: flex;
  gap: 10px;
}
