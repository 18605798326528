@import "../../../../../styles/font-mixins";

.helperContainer {
  @include inputDescription;
  margin-top: 16px;

  .row {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }

  .invalid svg {
    color: var(--grey50);
  }
}

.eye_button:global(.bp4-button) {
  padding-right: 16px;
}
