.refresh_icon {
  margin-left: 7px;

  &:hover:global(.bp4-icon) {
    svg {
      color: var(--grey50);
      cursor: pointer !important;
    }
  }
}

.edit_bom {
  margin-left: 7px;
  visibility: visible !important;
  position: absolute;
  right: 0;
}
