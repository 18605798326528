@import "../../../styles/font-mixins";

.button {
  text-align: left;
}

.name {
  font-weight: 700;
}

.tenant {
  @include paragraph;
  color: white;
}
