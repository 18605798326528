@import "../../../../../styles/font-mixins";

.table_header {
  margin-top: 16px;
  margin-bottom: 8px;
}

.table_wrapper {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.table,
.table_header {
  table-layout: fixed;

  .tag_col {
    width: 180px;
  }

  .action_col,
  .description_col {
    width: 20%;
  }

  .revision_col {
    width: 15%;
  }
}

.locked_action_col {
  padding-left: 20px !important;

  :global(.bp4-tag) {
    cursor: not-allowed;
  }
}

.locked {
  display: flex;
  color: var(--grey50);

  svg {
    color: var(--grey50);
  }
}

.batch_action {
  display: flex;
  align-items: center;

  > :global(.bp4-popover2-target),
  :global(.bp4-popover-wrapper) {
    margin-left: auto;
  }
}

.warning_row {
  td {
    padding-top: 10px;
    vertical-align: top;
  }
}

.part_name_used {
  p {
    font-size: 10px;
    color: var(--red50);
  }
  padding-top: 10px !important;

  p {
    margin: 5px 0 0 20px;
  }
}

.part_name {
  display: flex;
  align-items: center;

  &.ignored {
    color: var(--blue30);
    svg {
      color: var(--blue30);
    }
  }
}

.menu_section {
  @include subtitle;
}

.first_menu_section:global(.bp4-menu-header) {
  margin-top: unset !important;
  padding-top: unset !important;
}
