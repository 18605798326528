@import "../../../../styles/font-mixins";

.list {
  margin-bottom: 16px;

  .group_header {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 8px;

    > :last-child {
      margin-left: 8px;
    }
  }

  .title {
    margin: 0;
  }

  .row {
    background-color: var(--grey0);
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button {
    margin-top: 5px;
  }
}
