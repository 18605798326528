.tool_version {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tool_version_label {
  font-size: 12px;
  min-width: 160px;
  color: var(--grey50);
}

// Editor
.menu {
  > li + li {
    margin-top: 2px !important;
  }
}

.version_description {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: var(--grey50);
}

.warn {
  color: var(--yellow50);
}

.tool_card {
  background-color: white;
}

.tool_deleted {
  display: flex;
  color: var(--grey30);
  svg {
    color: var(--grey30);
  }
}

.menu_button {
  margin-left: 8px;
}
