.row {
  td:nth-child(3) {
    padding: 5px 11px;
  }

  td:nth-child(4) {
    padding: 5px 10px;
  }

  .dependencies {
    display: flex;
    flex-wrap: wrap;

    > :not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.selected {
  td {
    background-color: white !important;
  }
}

.owners {
  width: 100%;
  display: flex;
  align-items: center;
}
