@import "../../../../styles/font-mixins";

.title {
  height: 24px;
  margin-top: 8px;
  margin-bottom: 32px;
  align-items: center;
  display: flex;
}

.subtitle {
  @include denseMedium;
  color: var(--blue80);
  height: 28px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 0;
}

.menu {
  margin: -8px;
  margin-bottom: 0;
}
