@import "./../../../styles/font-mixins";

.operation_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.operation_name {
  letter-spacing: 0.033em;
}

.toaster_content {
  margin-top: 8px;
  @include defaultRegular;
}

.delete_button {
  margin-left: 8px;
}

.draft_operation {
  &:not(:hover) .delete_button:global(.bp4-button) {
    display: none;
    animation: none;
  }
}
