.placeholder {
  color: var(--blue40);
}

.menu {
  padding: 4px;
  max-height: 215px;
}

.dropdown {
  min-width: 160px;
}
