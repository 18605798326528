.container {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.notification {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: -4px;
  right: -4px;
  background-color: var(--yellow60);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.yellow {
  background-color: var(--yellow60);
}

.red {
  background-color: var(--red50);
}

.default {
  background-color: var(--color50);
}

.tag {
  &:global(.bp4-tag) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 6px !important;
    background-color: var(--color10);
    color: var(--color70);

    svg {
      margin-top: 1px;
    }
  }
}

.name {
  min-width: 24px;
}

.description {
  font-family: Inter;
  font-style: normal;
  line-height: 16px;
  margin-top: 4px;
}
