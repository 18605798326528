.datepicker_expired_tasks {
  ul:global(.bp4-daterangepicker-shortcuts) {
    li:first-child :global(.bp4-menu-item) {
      color: var(--yellow70);
      font-weight: 500;

      &:hover {
        background-color: var(--yellow0);
        box-shadow: var(--yellowShadow4dp);
      }
    }
  }
}
