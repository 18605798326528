@import "../../../../styles/font-mixins";

.new_task_button {
  margin-top: 5px;
  span {
    @include button;
  }
}

.drop_placeholder {
  background-color: var(var(--blue50));
  border: none;
  td {
    text-align: center;
    background-color: var(var(--blue50));
    border: none !important;
  }
}

.selected {
  td {
    background-color: white;
  }
}

.fill {
  width: 100%;
}

.open_card {
  padding-bottom: 4px;
}

.workflow_title {
  margin: 5px;
}

.creation_view {
  padding: 4px;
}
