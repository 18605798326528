.dialog {
  width: 70vw;
  height: 770px;
}

.dialog_contents {
  > :first-child {
    margin-bottom: 20px;
    // width: 100%;
  }

  > :nth-child(2) {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    > :first-child {
      margin-right: 20px;
    }
  }
}

.tile {
  width: 100%;
  height: 400px;
  overflow-y: auto;
}
