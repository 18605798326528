.dialog {
  align-self: stretch;
  &:global(.bp4-dialog) {
    width: 60vw;
  }
}

.subtitle {
  margin-bottom: 16px;
}

.content {
 height: 100%;
 display: flex;
 flex-direction: column;
}
