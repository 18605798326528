.container {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row: 2;
  margin-top: 8px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.table {
  margin-bottom: 16px;
}
