.control_buttons {
  align-self: center;
  position: absolute;
  top: 0;
}

.slider_wrapper {
  padding: 16px 24px;
}

.slider {
  width: 200px;
}
