

.button_wrapper {
  width: 100%;
  margin-top: 5px;
  display: flex;
}

.status_tag {
  &:global(.bp4-button):global(.bp4-disabled) {
    cursor: pointer;
    font-size: 12px;
    color: white;
  }

  .text {
    color: white;
  }
}

.initial {
  display: inline;
  align-items: baseline;
}
