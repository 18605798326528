
@import "../../../styles/font-mixins";

.VersionContainer {
  position: relative;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  padding-left: 22px;
}

.VersionContainer::before {
  position: absolute;
  top: 0;
  left: 0px;
  border: 2px solid var(--grey80);
  margin-right: 20px;
  margin-top: 5px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: white;
  content: "";
}

.VersionNameLink {
  font-size: 14px;
  color: var(--blue60);
  font-weight: 500;
  margin-bottom: 20px;
}

.VersionsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.VersionsContainer::before {
  position: absolute;
  margin-top: 10px;
  height: 100%;
  border-right: 2px solid var(--grey80);
  width: 6px;
  content: "";
}

.Term {
  @include subtitle;
}

.Section {
  margin-bottom: 20px;
}

.version_change_list {
  padding-left: 13px;
  > * {
    margin-bottom: 5px;
  }
}
