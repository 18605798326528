.page {
  margin-left: 100px;
  margin-top: 100px;

  .title {
    font-size: 60px;
    margin-bottom: 10px;
  }

  .subtitle {
    margin-bottom: 20px;
  }

  .buttonsWrapper {
    display: flex;
    margin-top: 20px;

    .spacedButton {
      margin-left: 20px;
    }
  }
}
