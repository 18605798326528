@import "../../styles/font-mixins";

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 8px;
  text-align: left;

  td,
  th {
    padding: 0 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    height: 20px;
    @include denseMedium;
    border-left: solid 2px var(--grey10);
  }

  tr td:first-child {
    border-radius: 5px 0 0 5px;
  }

  tr td:last-child {
    border-radius: 0 5px 5px 0;
  }

  td {
    height: 40px;
    background-color: var(--grey0);
  }
}
