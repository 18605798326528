.large_button,
.small_button {
  &:global(.bp4-button) {
    box-shadow: none !important;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &:global(.bp4-button):not(:global(.bp4-disabled)) {
    background-color: var(--color50) !important;
    span,
    svg {
      color: var(--color5);
    }
  }

  &:global(.bp4-button):global(.bp4-disabled) {
    background-color: var(--color10) !important;
    span,
    svg {
      color: var(--color50);
    }
  }
}

.large_button {
  &:global(.bp4-button) {
    width: 150px;
  }
}

.small_button {
  &:global(.bp4-button) {
    min-width: 70px;
  }
}
