@import "../../../../styles/font-mixins";

.dialog {
  width: 560px;
}

.divider {
  margin-top: 30px !important;
  margin-bottom: 10px !important;
}

.radio {
  padding-right: 10px;
}
