@import "../../../../styles/font-mixins";

.dialog {
  width: 600px;

  &__inner {
    > :not(:last-child) {
      margin-bottom: 16px;
    }

    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 40px;
      align-items: center;
    }

    .flexItem {
      flex: 1;
      margin-bottom: 0;
    }

    .filler {
      flex: 1 0 auto;
    }
  }

  .roleWrapper {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
    @include subtitle;

    .rolePicker {
      margin-left: 20px;
      border: 1px solid var(--grey40);
    }
  }
}
