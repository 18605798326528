@import "../../../../styles/font-mixins";

.config_type {
  @include paragraph;
  color: var(--grey50);
  margin-right: 16px;
}

.body {
  overflow-y: auto;

  > :not(:last-child) {
    margin-bottom: 20px;
  }
}

.values {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  :global(.bp4-tag):not(:last-child) {
    margin-right: 8px;
  }
}
