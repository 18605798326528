@import "~normalize.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

@font-face {
  font-family: "roboto";
  src: url("/assets/Roboto.woff2") format("woff2"),
    url("/assets/Roboto.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "inter";
  src: url("/assets/Inter.woff2") format("woff2"),
    url("/assets/Inter.woff") format("woff");
}

@font-face {
  font-family: "inter";
  src: url("/assets/Inter-Medium.woff2") format("woff2"),
    url("/assets/Inter-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "inter";
  src: url("/assets/Inter-Bold.woff2") format("woff2"),
    url("/assets/Inter-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@import "./styles/color-default.scss";
@import "./styles/grid.scss";
@import "./styles/common.scss";
@import "./styles/fonts.scss";
@import "./styles/palette.scss";
@import "./styles/setproduct.scss";

a {
  color: var(--blue70);
}
