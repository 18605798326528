@import "../../../styles/font-mixins";

.centered {
  width: 100%;
  max-width: 800px;
  align-self: center;
}

.cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 0;

  > :nth-child(odd) {
    margin-right: 20px;
  }

  > * {
    margin-bottom: 20px;
    display: flex;
    width: 40%;
    max-width: 50%;
    flex-grow: 1;
  }

  > :last-child:nth-child(odd) {
    padding-right: 10px;
  }
}

.config_type {
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 7px;
  }
}

.empty_state {
  margin: 24px;
}

.empty_state_button {
  display: flex;
  margin: 24px 0;
}
