@import "../../styles/font-mixins";

.attribute_label {
  &:not(:last-child) {
    margin: 0 0 16px 0;
  }

  &:last-child {
    margin: 0;
  }
}

.used_in {
  margin-right: 8px;

  :global(.bp4-icon) {
    margin-left: 8px;

    svg {
      color: var(--grey50);
    }
  }
}
