@import "../../../../styles/font-mixins";

.field_label {
  font-size: 14px;

  .description {
    @include inputDescription;
    margin-top: 8px;
    margin-bottom: 0px;
  }
}

.multiselect {
  margin-bottom: 16px;
}

.inline {
  margin-right: 8px;
}

.tag {
  span,
  svg {
    color: var(--grey100) !important;
  }

  :global(.bp4-icon):hover svg {
    color: var(--grey50) !important;
  }
}
