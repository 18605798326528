@import "../../../../styles/font-mixins";

.dialog {
  width: 800px;
  max-width: 800px;

  .innerDialog {
    max-height: 70vh;
    overflow-y: auto;

    .array {
      &:not(:first-child) {
        margin-top: 20px;
      }

      &__header {
        @include defaultMedium;
        color: var(--color100);
        display: flex;
        align-items: center;
        vertical-align: middle;
      }
    }
  }
}

.divider {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

.row {
  margin-bottom: 8px;
}

.row:last-child {
  margin-bottom: 16px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  &__left {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    .groupIcon {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 30px;
      height: 30px;
    }

    .userIcon {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 1px solid var(--grey40);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      color: var(--grey40);
    }

    .nameContainer {
      @include button;
      color: var(--blue100);
      margin-left: 10px;

      .name {
        display: flex;
        align-items: center;

        .name__icon {
          margin-left: 8px;
        }
      }
    }

    .info {
      @include description;
      color: var(--grey40);
      font-size: 12px;
      margin-top: 5px;
    }
  }
}
