.panel {
  width: 100%;
  max-width: 800px;
  margin: 18px auto;
}

.inner {
  display: flex;
  flex-direction: column;
}
