@import "../../../styles/font-mixins";

.page {
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 800px;
}

.actions {
  display: flex;
  align-items: center;
  @include paragraph;

  .checkbox:global(.bp4-checkbox) {
    margin-left: 8px;
  }
}

.first_error {
  margin-top: 16px;
}

.toggle {
  margin-top: 8px;
}
