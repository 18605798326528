.revision_info {
  display: flex;
}

.subtitle {
  margin-right: 64px;
}

.card {
  margin-bottom: 16px;
}
