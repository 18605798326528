.no_data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hover_button:global(.bp3-button) {
  transition: none;
}

.create_button {
  margin: 24px 0;
  display: flex;
}

.cell {
  display: flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  width: 100%;
}
