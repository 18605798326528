@import "../../../../styles/font-mixins";

.row {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 4px;
  padding: 0 8px;
  border-radius: 4px;

  .cell {
    vertical-align: middle;
  }

  .task {
    min-width: 0px;
    flex: 1 1 0px;
  }

  .assignee_col {
    flex: 0 0 140px;
  }

  .status_col {
    flex: 0 0 70px;
  }
}
