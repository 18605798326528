.button:global(.bp4-button) {
  font-weight: 400;
  color: var(--grey100);
  background-color: white;

  svg {
    color: var(--color40);
  }
}

.menu {
  width: 180px;
  margin-left: 8px;
}
