@import "../../styles/font-mixins";

.userSelect {
  padding: 8px;
  width: 500px;

  > :first-child {
    display: flex;
    align-items: center;

    :global(.bp4-button-group) {
      margin-left: 8px;
    }
  }

  .list {
    margin-top: 5px;
    max-height: 200px;
    overflow-y: auto;

    :global(.bp4-menu-item) {
      margin: 0;
      margin-bottom: 2px;
    }
  }
}

.button {
  @include button;
  margin-top: 8px;
}

.user_count {
  color: var(--grey50);
}
