@mixin H1 {
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.026em;
  color: var(--blue100);
}

@mixin H2 {
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.025em;
  color: var(--blue100);
}

@mixin H3 {
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: var(--blue100);
}

@mixin componentTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--grey100);
}

@mixin componentTitleDense {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.0075em;
  color: var(--grey100);
}

@mixin link {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.033em;
  color: var(--color70);

  &:hover {
    text-decoration-line: underline;
  }
}

@mixin button {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.054em;
  color: var(--color70);
}

@mixin buttonDense {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.018em;
  color: var(--color70);
}

@mixin dialogTitleDense {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.0075em;
  color: var(--color100);
}

@mixin dialogTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--color100);
}

@mixin inputDescription {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey50);
}

@mixin inputDescriptionDense {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
}

@mixin defaultMedium {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}

@mixin defaultRegular {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}

@mixin denseRegular {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}

@mixin denseMedium {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}

@mixin component {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0075em;
  color: var(--grey00);
}

@mixin componentDense {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}

@mixin description {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
}

@mixin descriptionDense {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.06em;
  color: var(--grey50);
}

@mixin paragraph {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0075em;
  color: var(--grey100);
}

@mixin subtitle {
  font-size: 12px;
  color: var(--grey50);
  font-weight: 400;
}
