
@import "../../../styles/font-mixins";

.flex {
  display: flex;
  align-items: center;
  overflow: hidden;

  .h2 {
    @include H2;
    color: var(--blue100);
    margin: 0;
  }
}

.deleted {
  color: var(--grey60);
}

.drawer {
  padding: 0 16px 16px 16px;

  .divider {
    margin: 16px 0 !important;
  }

  .property {
    min-height: 32px;
  }

  .user_select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .edit_buttons {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
  .model {
    @include paragraph;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status_wrapper {
    @include paragraph;
    display: flex;
    align-items: center;
    width: 100%;
  }

  :global(.bp4-tab-panel) {
    margin-top: 16px;
  }

  .tasks {
    border-radius: 5px;
    margin-top: 8px;
    padding: 0;

    > :last-child {
      margin-bottom: 0px;
    }
  }
}

.task_row {
  background-color: var(--grey0);
}
