.content {
  width: 100%;
  max-width: 800px;
  margin: 18px auto;

  > * + * {
    margin-top: 16px;
    margin-bottom: 0;
  }
}
