@import "../../../../styles/font-mixins";

.subtitle {
  @include denseMedium;
  color: var(--blue80);
  height: 28px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 0;
}

.top_buttons {
  height: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.product_image {
  align-self: center;
  margin-bottom: 16px;
}

.menu {
  margin: 0 -8px;

  li {
    padding: 0 !important;
  }
}

.product_name {
  @include button;
  color: var(--grey100);
  margin-bottom: 16px;
}

.product_description {
  margin-bottom: 32px;
}
