@import "../../../../styles/font-mixins";

.error_definition {
  @include subtitle;
  color: var(--red50);
}

.hover_button:global(.bp3-button) {
  transition: none;
}
