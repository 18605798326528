@import "../../../../styles/font-mixins";

.cell {
  width: 1/3;
}

.dep_cell {
  width: 1/3;
}

.status_cell {
  width: 175px;
}

.date_cell {
  width: 150px;
}

.action_cell {
  width: 50px;
}

.owner_cell_content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
