@import "../../styles/font-mixins";

.wrapper {
  align-items: center;

  &:not(.inline) {
    display: flex;
  }

  &.inline {
    display: inline;
  }

  .full {
    display: flex;
    flex-direction: column;

    .name {
      @include button;
      color: var(--color100);
    }

    .email {
      @include subtitle;
    }
  }

  .name {
    @include inputDescription;
    color: var(--color100);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
