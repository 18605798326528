.page {
  align-self: center;
  width: 100%;
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .list {
    .row {
      background-color: var(--grey0);
      margin-bottom: 8px;
      padding: 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      .row_right {
        display: flex;
        align-items: center;
      }
    }
  }
}
