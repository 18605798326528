.container {
  height: 30px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;
  }

  & > :last-child {
    margin-right: 0px;
  }
}
