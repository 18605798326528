@import "../../../../styles/font-mixins";

.dialog:global(.bp4-dialog) {
  width: fit-content;

  p {
    @include paragraph;
  }

  .dialog_contents > * + * {
    margin-top: 16px;
  }

  .table {
    height: 40vh;
    overflow-y: auto;
  }
}
