
@import "../../../styles/font-mixins";

.header_row_actions {
  background-color: var(--grey5);
  display: flex;
  align-items: center;
  vertical-align: center;

  .icon {
    display: flex;
    margin-right: 0px;
  }

  .select_cell {
    width: 40px;
    margin-left: 1px;
  }
}

.subtitle {
  @include subtitle;
}
