@import "../../../styles/font-mixins";

.tabs {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.tab_panel {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.action_bar {
  display: flex;
}
