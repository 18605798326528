.grah_tooltip_container {
  position: absolute;
  pointer-events: none;
  transition: all 0.1s ease;
  background: var(--grey60);
  border-radius: 3px;
  padding: 10px;
  color: white;

  .title {
    font-weight: 700;
  }

  .subtitle {
    margin-top: 5px;
  }
}
