@import "../../../../../styles/font-mixins";

.table_header {
  margin-top: 16px;
  margin-bottom: 8px;
}

.table_wrapper {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.table,
.table_header {
  .tag_col {
    width: 180px !important;
  }

  .action_col {
    width: 30%;
  }

  .action_col.invalid {
    padding-left: 20px !important;

    :global(.bp4-tag) {
      cursor: not-allowed;
    }
  }
}

.invalid {
  display: flex;
  color: var(--grey50);

  svg {
    color: var(--grey50);
  }
}

.batch_action {
  display: flex;
  align-items: center;
}

.part_name_used {
  p {
    font-size: 10px;
    color: var(--red50);
  }
  padding-top: 10px !important;

  p {
    margin: 5px 0 0 20px;
  }
}

.part_name {
  display: flex;
  align-items: center;

  &.ignored {
    color: var(--blue30);
    svg {
      color: var(--blue30);
    }
  }
}
