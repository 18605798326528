@import "../../../../styles/font-mixins";

.dialog_contents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cad_viewer {
  flex: 1;
  width: 100%;
  background-color: transparent;

  //hide "powered by CADExchanger"
  > div:not(:global(.cadex-viewcube)) {
    visibility: hidden;
    * {
      display: none;
    }
  }
}
