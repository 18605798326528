@import "../../../../styles/font-mixins";

.header_task {
  @include denseMedium;
  display: flex;
  height: 24px;
  margin-bottom: 8px;

  .task {
    width: 212px;
  }

  .assignee_col {
    width: 140px;
  }

  .status_col {
    width: 70px;
  }

  .divider {
    margin: 0px 8px !important;
  }
}
