@import "../../styles/font-mixins";

.panel {
  box-shadow: 0px 8px 16px 0px rgba(120, 120, 133, 0.16),
    0px 4px 8px 0px rgba(120, 120, 133, 0.16);
}

.header {
  display: block;
  box-shadow: none;
  padding: 16px;
}

.flex {
  height: 34px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.fill {
    width: 100%;
  }

  .input {
    margin-right: 8px;
  }

  .title {
    @include H3;
    color: var(--blue100);
    margin: 0;
    flex: 1;

    :global(.bp4-icon) {
      margin-right: 8px;
      svg {
        color: var(--blue100);
      }
    }
  }
}

.button_group {
  padding-right: 4px;
}
