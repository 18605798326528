
@import "../../styles/font-mixins";

.card {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 3px;
  background-color: var(--grey0);

  &.seen {
    background-color: var(--blue5);
  }
}

.header {
  margin-bottom: 7px;
  display: flex;
  align-items: baseline;

  .user {
    margin-right: 8px;
    font-size: 14px;
  }

  .date {
    @include description;
    font-weight: 400;
    color: var(--grey50);
  }
}

.header_button {
  margin-left: auto;
}
.content {
  @include paragraph;
  padding-left: 27px;
  overflow-wrap: break-word;
}
