.dialog_contents {
  > :not(:last-child) {
    margin-bottom: 16px;
  }
}

.table {
  height: 40vh !important;
}

.dialog_with_filters:global(.bp4-dialog) {
  width: fit-content;
}
