@import "../../../../styles/font-mixins";

.model_header {
  border-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 0;

  > :first-child {
    cursor: pointer;
  }

  > :last-child {
    margin-left: auto;
  }
}

.warning {
  margin-bottom: 10px;
}

.dependencies {
  > * {
    margin: 3px;
  }
}

.container {
  padding: 0;
  margin-bottom: 30px;
}

.owners {
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}
