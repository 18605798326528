.dialog {
  width: 80vw;
}

.revision_name {
  margin-right: 8px;
}

.table {
  height: 40vh !important;
}
