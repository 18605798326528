/* temp_stylePlugin:ni:sha-256;p-RzHKENbXlSNBB_fRuhehK8OcK4jPy0HInP3PFBkVg */
._primary_vdg39_2,
[class*=intent-primary] {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);
  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);
}
._danger_vdg39_23,
[class*=intent-danger] {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);
  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);
}
._success_vdg39_44,
[class*=intent-success] {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);
  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);
}
._warning_vdg39_65,
[class*=intent-warning] {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);
  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);
}
._warning_callout_vdg39_86 {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow5);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);
  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);
}
._default_vdg39_106 {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);
  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);
}
._primary_alt_vdg39_126 {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);
  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);
}
._danger_alt_vdg39_146 {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);
  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);
}
._success_alt_vdg39_166 {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);
  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);
}
._warning_alt_vdg39_186 {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);
  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);
}

/* temp_stylePlugin:ni:sha-256;YU-stkjv1klqOQrRsyyEuv0A9TZ5lcb2PvCS0lUn2Zc */
._default_1i8cc_1,
._dense_1i8cc_2 {
  font-family: var(--font-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}
._default_1i8cc_1:not([class*=bp4-intent-]),
._dense_1i8cc_2:not([class*=bp4-intent-]) {
  box-shadow: none;
  background-color: unset;
  background-image: none;
  color: unset;
}
._default_1i8cc_1:not([class*=bp4-intent-]):hover,
._dense_1i8cc_2:not([class*=bp4-intent-]):hover {
  box-shadow: none;
  background-clip: unset;
  background-color: unset;
}
._default_1i8cc_1 ._bp4-icon_1i8cc_20,
._dense_1i8cc_2 ._bp4-icon_1i8cc_20 {
  color: unset;
}
._default_1i8cc_1 {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  min-width: var(--s40);
  height: var(--s40);
  min-height: unset;
  padding: 0 1rem 0 1rem;
  line-height: var(--s16);
  border-radius: var(--s4);
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.054em;
  color: var(--color70);
}
._default_1i8cc_1._dense_1i8cc_2 {
  font-weight: normal;
  letter-spacing: var(--letter_spacing_body_small);
  height: var(--s28);
  min-width: var(--s28);
  padding: 0 0.75rem 0 0.75rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.018em;
  color: var(--color70);
}
._card_1i8cc_64 {
  font-size: var(--font_size_caption);
  font-family: var(--font_family_button);
  line-height: var(--s16);
  text-align: center;
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  min-width: var(--s80);
  height: var(--s56);
  display: initial;
  word-break: break-all;
  padding: var(--s8) 0;
  border-radius: var(--s4);
}
._card_1i8cc_64 p {
  margin: 0;
}
._card_1i8cc_64 > span {
  margin-right: 0;
}
._card_1i8cc_64 > span[class*=icon] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  margin-bottom: 0.25rem;
}
._card_1i8cc_64[class*=button] > div[class*=spinner] {
  position: initial;
  margin-top: 0.625rem;
}
._dense_1i8cc_2._card_1i8cc_64 {
  min-width: var(--s56);
  height: var(--s40);
  padding: var(--s6) 0;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._dense_1i8cc_2._card_1i8cc_64 > span[class*=icon] {
  margin-bottom: 0;
}
._dense_1i8cc_2._card_1i8cc_64[class*=button] > div[class*=spinner] {
  margin-top: 0.375rem;
}
._circle_1i8cc_117 {
  height: var(--s56);
  width: var(--s56);
  border-radius: 99px;
  outline: none;
}
._dense_1i8cc_2._circle_1i8cc_117 {
  height: var(--s40);
  width: var(--s40);
}
._action_1i8cc_130 {
  font-family: var(--font_family_button);
  font-style: normal;
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  height: var(--s56);
  border-radius: 99px;
  padding: 0.75rem 1rem;
}
._action_1i8cc_130 span {
  margin: 0;
}
._action_1i8cc_130 span:first-child[class*=icon] {
  text-align: left;
  margin-right: 0.75rem;
}
._action_1i8cc_130 span:last-child[class*=icon] {
  margin-left: 0.75rem;
}
._dense_1i8cc_2._action_1i8cc_130 {
  font-weight: normal;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body_bold);
  height: var(--s40);
  border-radius: 99px;
  padding: 0.5rem 0.75rem;
}
._dense_1i8cc_2._action_1i8cc_130 span:first-child[class*=icon] {
  margin-right: 0.625rem;
}
._dense_1i8cc_2._action_1i8cc_130 span:last-child[class*=icon] {
  margin-left: 0.625rem;
}
._icon_1i8cc_172 {
  width: var(--s36);
  height: var(--s36);
  min-width: 0;
  min-height: 0;
  padding: 0;
  outline: none;
}
._dense_1i8cc_2._icon_1i8cc_172 {
  width: var(--s28);
  height: var(--s28);
}
._fill_1i8cc_186 {
  width: 100% !important;
  justify-content: space-between;
}

/* temp_stylePlugin:ni:sha-256;oXvXPi6Vn_RfIgfQwO_5aA9En9-p5nM-WkaZtbdeEVs */
._flat_1gtbv_2,
._flat_1gtbv_2[class*=button],
._flat_1gtbv_2:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._flat_1gtbv_2:hover,
._flat_1gtbv_2[class*=button]:hover,
._flat_1gtbv_2:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._flat_1gtbv_2:active,
._flat_1gtbv_2[class*=button]:active,
._flat_1gtbv_2[class*=button][class*=active],
._flat_1gtbv_2[class*=button]._focused_1gtbv_22 {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._flat_1gtbv_2 svg {
  color: var(--color70);
}
._flat_1gtbv_2[class*=disabled]:disabled,
._flat_1gtbv_2[class*=disabled]:disabled svg,
._flat_1gtbv_2[class*=disabled]:disabled:hover,
._flat_1gtbv_2[class*=disabled]:disabled:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0);
  box-shadow: none;
}
._filled_1gtbv_44[class*=button],
._filled_1gtbv_44[class*=button]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._filled_1gtbv_44 svg {
  color: #ffffff;
}
._filled_1gtbv_44[class*=button]:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._filled_1gtbv_44[class*=button]:active,
._filled_1gtbv_44[class*=button][class*=active]:active,
._filled_1gtbv_44[class*=button]._focused_1gtbv_22 {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._filled_1gtbv_44[class*=disabled]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
._filled_1gtbv_44[class*=disabled]:disabled svg {
  color: var(--color40);
}
._smooth_1gtbv_83[class*=button],
._smooth_1gtbv_83[class*=button]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._smooth_1gtbv_83[class*=button] svg {
  color: var(--color70);
}
._smooth_1gtbv_83[class*=button]:hover {
  color: var(--color80);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: none;
}
._smooth_1gtbv_83[class*=button]:active,
._smooth_1gtbv_83[class*=button]:active,
._smooth_1gtbv_83[class*=button][class*=active],
._smooth_1gtbv_83[class*=button]._focused_1gtbv_22 {
  background: var(--color20);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._smooth_1gtbv_83[class*=button]:hover svg,
._smooth_1gtbv_83[class*=button]:active svg {
  color: var(--color80);
}
._smooth_1gtbv_83[class*=disabled]:disabled,
._smooth_1gtbv_83[class*=disabled]:disabled:hover {
  color: var(--color30);
  background-color: var(--color5);
  opacity: 1;
}
._smooth_1gtbv_83[class*=button]:disabled svg,
._smooth_1gtbv_83[class*=button]:disabled:hover svg {
  color: var(--color30);
}
._outlined_1gtbv_130[class*=button],
._outlined_1gtbv_130[class*=button]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._outlined_1gtbv_130 svg {
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._outlined_1gtbv_130[class*=button]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1gtbv_130[class*=button]:active,
._outlined_1gtbv_130[class*=button]._focused_1gtbv_22 {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1gtbv_130[class*=button][class*=active] {
  color: var(--color100);
  background-color: #ffffff;
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1gtbv_130[class*=disabled]:disabled {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
._outlined_1gtbv_130[class*=disabled]:disabled svg {
  color: var(--color30);
}
._raised_1gtbv_176[class*=button],
._raised_1gtbv_176[class*=button]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._raised_1gtbv_176 svg {
  color: var(--color70);
}
._raised_1gtbv_176[class*=button]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._raised_1gtbv_176[class*=button]:active,
._raised_1gtbv_176[class*=button]._focused_1gtbv_22 {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._raised_1gtbv_176[class*=disabled]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
._raised_1gtbv_176[class*=disabled]:disabled svg {
  color: var(--color40);
}
._minimal_1gtbv_212[class*=button] svg {
  color: var(--color70);
}
._minimal_1gtbv_212[class*=button]:hover {
  color: var(--color80);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: none;
}
._minimal_1gtbv_212[class*=button]:active,
._minimal_1gtbv_212[class*=button]:active,
._minimal_1gtbv_212[class*=button][class*=active],
._minimal_1gtbv_212[class*=button]._focused_1gtbv_22 {
  background: var(--color20);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._minimal_1gtbv_212[class*=button]:hover svg,
._minimal_1gtbv_212[class*=button]:active svg {
  color: var(--color80);
}
._minimal_1gtbv_212[class*=button]:disabled svg,
._minimal_1gtbv_212[class*=button]:disabled:hover svg {
  color: var(--color5);
}

/* temp_stylePlugin:ni:sha-256;4LSPC0hDFXSjmo35XzON8X7Jnd24lxPxuknGhEMghgY */
._popover_18p0l_1 {
  border: none;
  padding: 0;
  box-shadow: var(--shadow8dp);
}
._popover_18p0l_1 .bp4-popover-content {
  padding: 0;
}
._children_18p0l_10[class*=open] a[class*=minimal][class*=button] {
  background: var(--color10);
  box-shadow: none;
}

/* temp_stylePlugin:ni:sha-256;vBKHHc4d7x61nBBOKBckJ9o2iO_QYLFpixE7Vr9kPM8 */
._default_9mf39_3 {
  width: 100%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
  padding: 40px !important;
}
._title_9mf39_11 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--color100);
}
._header_9mf39_20 {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}
._content_9mf39_26 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}
._header_9mf39_20 span[class*=icon] {
  margin-right: 1rem;
}
._footer_9mf39_40 {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
._dense_9mf39_46 {
  width: 100%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
  padding: 16px !important;
}
._dense_9mf39_46._header_9mf39_20 {
  margin-bottom: 0.75rem;
}
._dense_9mf39_46._header_9mf39_20 span[class*=icon] {
  margin-right: 0.75rem;
}
._dense_9mf39_46 ._title_9mf39_11 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--color100);
}
._dense_9mf39_46 ._content_9mf39_26 {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}
._dense_9mf39_46 ._footer_9mf39_40 {
  margin-top: 16px;
}
._close-button_9mf39_84,
._close-button_9mf39_84 span[class*=icon] {
  margin-right: 0;
  margin-left: auto;
}

/* temp_stylePlugin:ni:sha-256;Yw8nuSxNLv9lyAArz9iflPyWbpQR-aKWcjCfYx43jts */
._smooth_1ahr2_3 {
  background: var(--color5);
  box-shadow: none;
}
._raised_1ahr2_9 {
  background: var(--white);
  box-shadow: var(--shadow24dp);
}
._filled_1ahr2_15 {
  background: var(--color50);
  box-shadow: none;
}
._filled_1ahr2_15 div[class*=header] > div,
._filled_1ahr2_15 div[class*=body] {
  color: var(--color0);
}
._filled_1ahr2_15 div[class*=header] span[class*=icon] svg,
._raised_1ahr2_9 div[class*=header] span[class*=icon] svg,
._smooth_1ahr2_3 div[class*=header] span[class*=icon] svg {
  fill: var(--color30);
}
._filled_1ahr2_15 div[class*=header] div[class*=close-button] svg,
._raised_1ahr2_9 div[class*=header] div[class*=close-button] svg,
._smooth_1ahr2_3 div[class*=header] div[class*=close-button] svg {
  fill: var(--color30);
  transition: all 150ms ease-in-out;
}
._raised_1ahr2_9 div[class*=header] div[class*=close-button]:hover svg,
._smooth_1ahr2_3 div[class*=header] div[class*=close-button]:hover svg {
  fill: var(--color60);
  transition: all 150ms ease-in-out;
}
._filled_1ahr2_15 div[class*=header] div[class*=close-button]:hover svg {
  fill: var(--color5);
  transition: all 150ms ease-in-out;
}
._filled_1ahr2_15 div[class*=header] div[class*=close-button]:hover,
._raised_1ahr2_9 div[class*=header] div[class*=close-button]:hover,
._smooth_1ahr2_3 div[class*=header] div[class*=close-button]:hover {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

/* temp_stylePlugin:ni:sha-256;UxuTPlD06nudX2BzNCiTcC2PwqoIMXrDbP2tzmAzJCk */
._default_1gbyu_1,
._dense_1gbyu_2 {
  font-family: var(--font-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}
._default_1gbyu_1:not([class*=bp4-intent-]),
._dense_1gbyu_2:not([class*=bp4-intent-]) {
  box-shadow: none;
  background-color: unset;
  background-image: none;
  color: unset;
}
._default_1gbyu_1:not([class*=bp4-intent-]):hover,
._dense_1gbyu_2:not([class*=bp4-intent-]):hover {
  box-shadow: none;
  background-clip: unset;
  background-color: unset;
}
._default_1gbyu_1 ._bp4-icon_1gbyu_20,
._dense_1gbyu_2 ._bp4-icon_1gbyu_20 {
  color: unset;
}
._default_1gbyu_1 {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  min-width: var(--s40);
  height: var(--s40);
  min-height: unset;
  padding: 0 1rem 0 1rem;
  line-height: var(--s16);
  border-radius: var(--s4);
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.054em;
  color: var(--color70);
}
._default_1gbyu_1._dense_1gbyu_2 {
  font-weight: normal;
  letter-spacing: var(--letter_spacing_body_small);
  height: var(--s28);
  min-width: var(--s28);
  padding: 0 0.75rem 0 0.75rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.018em;
  color: var(--color70);
}
._card_1gbyu_64 {
  font-size: var(--font_size_caption);
  font-family: var(--font_family_button);
  line-height: var(--s16);
  text-align: center;
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  min-width: var(--s80);
  height: var(--s56);
  display: initial;
  word-break: break-all;
  padding: var(--s8) 0;
  border-radius: var(--s4);
}
._card_1gbyu_64 p {
  margin: 0;
}
._card_1gbyu_64 > span {
  margin-right: 0;
}
._card_1gbyu_64 > span[class*=icon] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  margin-bottom: 0.25rem;
}
._card_1gbyu_64[class*=button] > div[class*=spinner] {
  position: initial;
  margin-top: 0.625rem;
}
._dense_1gbyu_2._card_1gbyu_64 {
  min-width: var(--s56);
  height: var(--s40);
  padding: var(--s6) 0;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._dense_1gbyu_2._card_1gbyu_64 > span[class*=icon] {
  margin-bottom: 0;
}
._dense_1gbyu_2._card_1gbyu_64[class*=button] > div[class*=spinner] {
  margin-top: 0.375rem;
}
._circle_1gbyu_117 {
  height: var(--s56);
  width: var(--s56);
  border-radius: 99px;
  outline: none;
}
._dense_1gbyu_2._circle_1gbyu_117 {
  height: var(--s40);
  width: var(--s40);
}
._action_1gbyu_130 {
  font-family: var(--font_family_button);
  font-style: normal;
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  height: var(--s56);
  border-radius: 99px;
  padding: 0.75rem 1rem;
}
._action_1gbyu_130 span {
  margin: 0;
}
._action_1gbyu_130 span:first-child[class*=icon] {
  text-align: left;
  margin-right: 0.75rem;
}
._action_1gbyu_130 span:last-child[class*=icon] {
  margin-left: 0.75rem;
}
._dense_1gbyu_2._action_1gbyu_130 {
  font-weight: normal;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body_bold);
  height: var(--s40);
  border-radius: 99px;
  padding: 0.5rem 0.75rem;
}
._dense_1gbyu_2._action_1gbyu_130 span:first-child[class*=icon] {
  margin-right: 0.625rem;
}
._dense_1gbyu_2._action_1gbyu_130 span:last-child[class*=icon] {
  margin-left: 0.625rem;
}
._icon_1gbyu_172 {
  width: var(--s36);
  height: var(--s36);
  min-width: 0;
  min-height: 0;
  padding: 0;
  outline: none;
}
._dense_1gbyu_2._icon_1gbyu_172 {
  width: var(--s28);
  height: var(--s28);
}
._fill_1gbyu_186 {
  width: 100%;
  justify-content: space-between;
}
._leftIcon_1gbyu_191 {
  margin-right: 14px;
}

/* temp_stylePlugin:ni:sha-256;fL27lw8MeMiCG_OivAlAiLHumJ6rfeo8HXToEK-cCDI */
._flat_1dhbf_2,
._flat_1dhbf_2[class*=button],
._flat_1dhbf_2:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._flat_1dhbf_2:hover,
._flat_1dhbf_2[class*=button]:hover,
._flat_1dhbf_2:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._flat_1dhbf_2:active,
._flat_1dhbf_2[class*=button]:active,
._flat_1dhbf_2[class*=button][class*=active],
._flat_1dhbf_2[class*=button]._focused_1dhbf_22 {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._flat_1dhbf_2[class*=button] svg {
  color: var(--color70);
}
._flat_1dhbf_2[class*=disabled],
._flat_1dhbf_2[class*=disabled] svg,
._flat_1dhbf_2[class*=disabled]:hover,
._flat_1dhbf_2[class*=disabled]:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0) !important;
  box-shadow: none;
}
._filled_1dhbf_44[class*=button],
._filled_1dhbf_44[class*=button]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._filled_1dhbf_44[class*=button] svg {
  color: #ffffff;
}
._filled_1dhbf_44[class*=button]:hover {
  background-color: var(--color70) !important;
  color: var(--color0);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._filled_1dhbf_44[class*=button]:active,
._filled_1dhbf_44[class*=button][class*=active]:active,
._filled_1dhbf_44[class*=button]._focused_1dhbf_22 {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._filled_1dhbf_44[class*=disabled]:hover,
._filled_1dhbf_44[class*=disabled] {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
._filled_1dhbf_44[class*=disabled] svg {
  color: var(--color40);
}
._smooth_1dhbf_85[class*=button],
._smooth_1dhbf_85[class*=button]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._smooth_1dhbf_85[class*=button] svg {
  color: var(--color70);
}
._smooth_1dhbf_85[class*=button]:hover {
  color: var(--color80);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: none;
}
._smooth_1dhbf_85[class*=button]:active,
._smooth_1dhbf_85[class*=button]:active,
._smooth_1dhbf_85[class*=button][class*=active],
._smooth_1dhbf_85[class*=button]._focused_1dhbf_22 {
  background: var(--color20);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._smooth_1dhbf_85[class*=button]:hover svg,
._smooth_1dhbf_85[class*=button]:active svg {
  color: var(--color80);
}
._smooth_1dhbf_85[class*=disabled],
._smooth_1dhbf_85[class*=disabled]:hover {
  color: var(--color30) !important;
  background-color: var(--color5) !important;
  opacity: 1;
}
._smooth_1dhbf_85[class*=disabled] svg,
._smooth_1dhbf_85[class*=disabled]:hover svg {
  color: var(--color30);
}
._outlined_1dhbf_132[class*=button],
._outlined_1dhbf_132[class*=button]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._outlined_1dhbf_132[class*=button] svg {
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._outlined_1dhbf_132[class*=button]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1dhbf_132[class*=button]:active,
._outlined_1dhbf_132[class*=button]._focused_1dhbf_22 {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1dhbf_132[class*=button][class*=active] {
  color: var(--color100);
  background-color: #ffffff;
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1dhbf_132[class*=disabled]:hover,
._outlined_1dhbf_132[class*=disabled] {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
._raised_1dhbf_175[class*=button],
._raised_1dhbf_175[class*=button]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._raised_1dhbf_175[class*=button] svg {
  color: var(--color70);
}
._raised_1dhbf_175[class*=button]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._raised_1dhbf_175[class*=button]:active,
._raised_1dhbf_175[class*=button]._focused_1dhbf_22 {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._raised_1dhbf_175[class*=disabled]:hover,
._raised_1dhbf_175[class*=disabled] {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
._minimal_1dhbf_209[class*=button] {
  background: none;
}
._minimal_1dhbf_209[class*=button] svg {
  color: var(--color70);
}
._minimal_1dhbf_209[class*=button]:hover {
  color: var(--color80);
  background: var(--color5);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: none;
}
._minimal_1dhbf_209[class*=button]:active,
._minimal_1dhbf_209[class*=button]:active,
._minimal_1dhbf_209[class*=button][class*=active],
._minimal_1dhbf_209[class*=button]._focused_1dhbf_22 {
  background: var(--color10);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._minimal_1dhbf_209[class*=button]:hover svg,
._minimal_1dhbf_209[class*=button]:active svg {
  color: var(--color80);
}
._minimal_1dhbf_209[class*=button][class*=disabled] svg,
._minimal_1dhbf_209[class*=button][class*=disabled]:hover svg {
  color: var(--color5);
}

/* temp_stylePlugin:ni:sha-256;6qfOfcPl0B_FwtGC_lhmw1lQBePLi9T3Q1oQNkrIe7M */
:root {
  --hide: 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  --show: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;
}
._container_6xhjf_7 {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
}
._wrapper_6xhjf_14 {
  min-width: var(--min-width);
  height: var(--height);
  box-sizing: border-box;
  padding: 0;
  border-radius: 99px;
  z-index: 1;
  transition: all 250ms ease-in-out, transform var(--animation);
}
._position_6xhjf_25 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  justify-content: center;
  align-content: center;
  align-items: center;
  transform-origin: var(--originX) var(--originY);
  transform: scale(var(--scale)) translate(var(--translateX), var(--translateY));
}
._right_6xhjf_37 {
  --translateX: calc(var(--min-width) / 2);
  --originX: 100%;
  right: 0;
}
._top_6xhjf_43 {
  --translateY: -50%;
  --originY: 0%;
  top: 0;
}
._left_6xhjf_49 {
  --translateX: calc(var(--min-width) / -2);
  --originX: 0%;
  left: 0;
}
._bottom_6xhjf_55 {
  --translateY: 50%;
  --originY: 100%;
  bottom: 0;
}
._show_6xhjf_63 {
  --scale: 1;
  --animation: var(--show);
}
._hide_6xhjf_68 {
  --scale: 0;
  --animation: var(--hide);
}
._default_6xhjf_75 {
  --min-width: 1.25rem;
  --height: var(--min-width);
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}
._dense_6xhjf_84 {
  --min-width: 14px;
  --height: 14px;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._dot_6xhjf_93 {
  --min-width: 0.875rem;
  --height: var(--min-width);
}

/* temp_stylePlugin:ni:sha-256;a2NcX8xIZt2a4I1gH8DJE6RFGfUJfrS2l1Rt1Ix_oYU */
._filled_1trco_1 {
  background-color: var(--color50);
  box-shadow: var(--shadow8dp);
  color: var(--color0);
}
._smooth_1trco_7 {
  background-color: var(--color20);
  box-shadow: var(--shadow8dp);
  color: var(--color90);
}
._outlined_1trco_13 {
  background-color: var(--color0);
  box-shadow: var(--shadow8dp);
  color: var(--color70);
  border: 1px solid var(--color40);
}

/* temp_stylePlugin:ni:sha-256;BiT4WUzfokf03X3jl3xuRb4TmRQHBguOBgv2TIwpf5U */
._bread_13kj5_1 ._bp4-breadcrumb-current_13kj5_1 {
  color: var(--color70);
}
._default_13kj5_6 {
  text-transform: capitalize;
}
._default_13kj5_6 > li::after {
  margin: 0 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
}
._default_13kj5_6 span[class*=icon] {
  margin-right: 0.5rem;
}
._default_13kj5_6 span[class*=breadcrumbs-collapsed]::before {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
._default_13kj5_6 span[class*=breadcrumbs-collapsed] {
  margin-right: 0;
  width: 2rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0;
}
._default_13kj5_6 a[class*=breadcrumb] {
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}

/* temp_stylePlugin:ni:sha-256;RTyFRd6KSBxySXlHWvIHDnyBtj4O8pH2-ksTugvUqas */
._primary_k014g_2 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._success_k014g_6 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._warning_k014g_10 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._danger_k014g_14 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._default_k014g_18 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._primary_alt_k014g_22 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._success_alt_k014g_26 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._warning_alt_k014g_30 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._danger_alt_k014g_34 span[class*=breadcrumbs-collapsed]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ffffff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>") center no-repeat;
}
._primary_k014g_2 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._success_k014g_6 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._warning_k014g_10 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._danger_k014g_14 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._default_k014g_18 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._primary_alt_k014g_22 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._success_alt_k014g_26 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._warning_alt_k014g_30 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._danger_alt_k014g_34 > li::after {
  background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%234A4B57'/></svg>");
}
._smooth_k014g_77 {
  color: var(--color70);
}
._smooth_k014g_77 span[class*=breadcrumbs-collapsed] {
  background: var(--color60);
  color: #ffffff;
}
._smooth_k014g_77 span[class*=breadcrumb-current] {
  color: var(--color80);
}
._smooth_k014g_77 a[class*=breadcrumb]:hover {
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._smooth_k014g_77 a[class*=breadcrumb]:hover > span[class*=icon] {
  color: var(--color50);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._popover_k014g_100 div[class*=popover-content] a[class*=menu-item]:hover {
  background-color: var(--color5);
}
._popover_k014g_100 div[class*=popover-arrow] {
  display: none;
}

/* temp_stylePlugin:ni:sha-256;rhYEnFTIWlMg3YGkgGGMXhvOa-pKKcGn7qdVvQvqs6c */
._default_1en8h_3,
._icon_1en8h_4 {
  height: 2.5rem;
  align-items: center;
}
._default_1en8h_3 {
  border-radius: 0.25rem !important;
}
._dense_1en8h_13._default_1en8h_3,
._dense_1en8h_13._icon_1en8h_4 {
  height: 1.75rem;
}
._default_1en8h_3[class*=button-group] > button[class*=button] {
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
._dense_1en8h_13._default_1en8h_3[class*=button-group] > button[class*=button] {
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
._dense_1en8h_13 [class*=popover2-target],
._dense_1en8h_13 [class*=button],
._dense_1en8h_13 [class*=anchor-button] {
  overflow: visible;
}
._right_1en8h_34 {
  float: right;
}
._bp4-divider_1en8h_38 {
  height: 100%;
}

/* temp_stylePlugin:ni:sha-256;MVATvhhgRVitf4bz2Tq5sVJbsssZwIpWw3jtmSr27xM */
._flat_120gh_2 {
  background: none;
  box-shadow: none;
}
._flat_120gh_2[class*=button-group] > button[class*=button] {
  color: var(--color70);
  box-shadow: none;
}
._flat_120gh_2 > button[class*=button]:hover {
  color: var(--color90);
  background: none;
  box-shadow: var(--shadow2dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._flat_120gh_2 > button[class*=button][class*=focused] {
  background-color: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._filled_120gh_28 {
  background: none;
  box-shadow: none;
  background-color: var(--color10);
}
._filled_120gh_28[class*=button-group] > button[class*=button] {
  color: var(--color50);
  background-color: inherit;
  box-shadow: none;
}
._filled_120gh_28 > button[class*=button]:hover {
  color: var(--color100);
  background-color: var(--color20);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._filled_120gh_28 > button[class*=button][class*=focused] {
  background-color: var(--color50);
  color: var(--white);
}
._smooth_120gh_54 {
  background: none;
  box-shadow: none;
  background-color: var(--color5);
}
._smooth_120gh_54[class*=button-group] > button[class*=button] {
  color: var(--color40);
  background-color: inherit;
  box-shadow: none;
}
._smooth_120gh_54 > button[class*=button]:hover {
  color: var(--color100);
  background-color: var(--color10);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._smooth_120gh_54 > button[class*=button][class*=focused] {
  background-color: var(--color20);
  color: var(--color100);
}
._outlined_120gh_80 {
  background: none;
  background-color: var(--white);
  box-shadow: 0 0 0 1px var(--color20);
}
._outlined_120gh_80[class*=button-group] > button[class*=button] {
  color: var(--color50);
  background-color: inherit;
  box-shadow: none;
  z-index: 1;
}
._outlined_120gh_80 > button[class*=button]:hover {
  color: var(--color90);
  background-color: var(--color0);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._outlined_120gh_80 > button[class*=button][class*=focused] {
  background-color: var(--white);
  color: var(--color90);
  box-shadow: 0 0 0 2px var(--color30);
  z-index: 6;
  transition: box-shadow background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._raised_120gh_110 {
  background: none;
  box-shadow: none;
  background-color: var(--color0);
}
._raised_120gh_110[class*=button-group] > button[class*=button] {
  color: var(--color40);
  background-color: inherit;
  box-shadow: none;
  z-index: 1;
}
._raised_120gh_110 > button[class*=button]:hover {
  color: var(--color90);
  background-color: var(--color5);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._raised_120gh_110 > button[class*=button][class*=focused] {
  background-color: var(--white);
  color: var(--color90);
  box-shadow: var(--shadow2dp);
  z-index: 6;
  transition: box-shadow background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

/* temp_stylePlugin:ni:sha-256;LtOefKzC4fscn06GPPkTClLz7ERjMb3NqmyhD7tG4E8 */
._def_u9j51_2[class*=callout] {
  padding: 12px 16px 12px 56px;
  border-radius: 0.5rem;
  font-size: var(--font_size_body);
}
._dense_u9j51_8[class*=callout] {
  padding: 0.5rem 12px 0.5rem 3rem;
  border-radius: 0.25rem;
  font-size: var(--font_size_body_small);
}
._def_u9j51_2[class*=callout] ._callout_u9j51_14 span[class*=icon]:first-child {
  float: left;
  position: absolute;
  top: 18px;
  left: 22px;
}
._dense_u9j51_8[class*=callout] ._callout_u9j51_14 span[class*=icon]:first-child {
  float: left;
  position: absolute;
  top: 14px;
  left: 18px;
}
._def_u9j51_2[class*=callout] h4[class*=heading] {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--grey100);
  line-height: 2.25rem;
  margin-bottom: 0.5rem;
}
._dense_u9j51_8[class*=callout] h4[class*=heading] {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.0075em;
  color: var(--grey100);
  line-height: 1.875rem;
  margin-bottom: 0.25rem;
}

/* temp_stylePlugin:ni:sha-256;K0uzPwAeqy4moLB2RbMHdP7sTlx6lYOzao6ruBFKUTc */
._filled_1994q_3[class*=callout],
._filled_1994q_3[class*=callout] h4[class*=heading] {
  background-color: var(--color70);
  color: var(--color0);
}
._filled_1994q_3[class*=callout] span[class*=icon]:first-child > svg,
._filled_1994q_3[class*=callout] span[class*=icon]:first-child {
  color: var(--color20);
}
._smooth_1994q_15[class*=callout],
._smooth_1994q_15[class*=callout] h4[class*=heading] {
  background-color: var(--color10);
  color: var(--color100);
}
._smooth_1994q_15[class*=callout] span[class*=icon]:first-child > svg,
._smooth_1994q_15[class*=callout] span[class*=icon]:first-child {
  color: var(--color70);
}
._outlined_1994q_27[class*=callout] {
  background-color: var(--white);
  color: var(--color100);
  border: 2px solid var(--color30);
}
._outlined_1994q_27[class*=callout] h4[class*=heading] {
  color: var(--color100);
}
._outlined_1994q_27[class*=callout] span[class*=icon]:first-child > svg,
._outlined_1994q_27[class*=callout] span[class*=icon]:first-child {
  color: var(--color70);
}
._raised_1994q_43[class*=callout] {
  background-color: var(--color0);
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
._raised_1994q_43[class*=callout] h4[class*=heading] {
  color: var(--color100);
}
._raised_1994q_43[class*=callout] span[class*=icon]:first-child > svg,
._raised_1994q_43[class*=callout] span[class*=icon]:first-child {
  color: var(--color70);
}

/* temp_stylePlugin:ni:sha-256;MnAZysclaNqjQHFYqdPXfMqHDQwkSdOPr56iWzk0ujY */
._card_lxbsj_1 {
  border-radius: 8px;
  padding: 8px;
  background-color: var(--color0);
}
._card_lxbsj_1._active_lxbsj_7 {
  background-color: var(--color5);
}
._interactive_lxbsj_11 {
  cursor: pointer;
}
._interactive_lxbsj_11:hover {
  background-color: var(--color5);
}
._header_lxbsj_18 {
  height: 28px;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: 8px;
}
._header_lxbsj_18 > div {
  display: flex;
  align-items: center;
}
._header_lxbsj_18._unfolded_lxbsj_29 {
  margin-bottom: 16px;
}
._title_lxbsj_33 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  flex: 1 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
._actions_lxbsj_47 {
  font-size: 12px;
  color: var(--grey50);
  font-weight: 400;
}

/* temp_stylePlugin:ni:sha-256;2xVB8AsVW5DqU82fMo_blczpbW7iK8b6ahKrhi9Knxg */
._list_b6e93_1 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* temp_stylePlugin:ni:sha-256;b9h7gUHyd05h69DdOUfxMEytqG_wuduVphl-yEicwTs */
._container_60mkl_3 {
  width: fit-content;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
._container_60mkl_3 label[class*=control] {
  display: flex;
  padding: 0;
  margin: 0;
}
._container_60mkl_3._fill_60mkl_16 {
  width: 100%;
}
._box_layout_60mkl_20 {
  position: absolute;
  border-radius: 0.25rem;
}
._text_60mkl_25 {
  width: 100%;
  white-space: nowrap;
}
._container_60mkl_3 label[class*=control]._right_60mkl_31 {
  flex-direction: row-reverse;
}
._def_60mkl_37 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
  align-items: center;
  height: 40px;
  border-radius: 0.25rem;
}
._def_60mkl_37._with_helper_60mkl_50 {
  height: 2.75rem;
  line-height: 2.75rem;
}
._def_60mkl_37 ._box_layout_60mkl_20 {
  width: 100%;
  height: 100%;
}
._def_60mkl_37._with_helper_60mkl_50 ._box_layout_60mkl_20 {
  top: 0.125rem;
}
._def_60mkl_37 ._text_60mkl_25 {
  margin-left: 8px;
  margin-right: 0.75rem;
  z-index: 1;
}
._def_60mkl_37[class*=control] span[class*=control-indicator],
._def_60mkl_37[class*=control] span[class*=control-indicator]::before {
  padding: 0;
  margin: auto 0.6875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.125rem;
  z-index: 1;
  outline: none;
}
._def_60mkl_37._checked_60mkl_81,
._dense_60mkl_82._checked_60mkl_81 {
  font-weight: var(--font_weight_body_medium);
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._def_60mkl_37._checked_60mkl_81 input:checked ~ span[class*=control-indicator]::before {
  margin: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e");
}
._def_60mkl_37._checked_60mkl_81 input:indeterminate ~ span[class*=control-indicator]::before,
._def_60mkl_37 input:indeterminate ~ span[class*=control-indicator]::before {
  margin: 0;
}
._dense_60mkl_82 {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  align-items: center;
  height: 28px;
  border-radius: 0.25rem;
}
._dense_60mkl_82._with_helper_60mkl_50 {
  height: 2.5rem;
  line-height: 2.5rem;
}
._dense_60mkl_82 ._box_layout_60mkl_20 {
  width: 1.75rem;
  height: 1.75rem;
}
._dense_60mkl_82._with_helper_60mkl_50 ._box_layout_60mkl_20 {
  top: 0.375rem;
}
._dense_60mkl_82 ._text_60mkl_25 {
  margin-left: 6px;
  margin-right: 0.5rem;
}
._dense_60mkl_82[class*=control] span[class*=control-indicator],
._dense_60mkl_82[class*=control] span[class*=control-indicator]:before {
  padding: 0;
  margin: auto 0.40625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 0.125rem;
  z-index: 1;
  outline: none;
}
._dense_60mkl_82._indeterminate_60mkl_141 span::before {
  margin: 0 !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='%23fff'/%3E%3C/svg%3E") !important;
}
._dense_60mkl_82._checked_60mkl_81 input:checked ~ span::before {
  margin: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e") !important;
}
._helperBox_60mkl_153 {
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._helperBox_60mkl_153 > ._helperText_60mkl_159 {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}
._rtl_60mkl_167 {
  text-align: right;
}

/* temp_stylePlugin:ni:sha-256;IQzJOcEPX5Whqqij04hhH1_RQz6_AkHLozhzH3YcOew */
._flat_1jx9u_2 {
  color: var(--color100);
}
._flat_1jx9u_2 ._box_layout_1jx9u_6 {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._flat_1jx9u_2:hover ._box_layout_1jx9u_6 {
  background: var(--color5);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._flat_1jx9u_2[class*=control] span[class*=control-indicator] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._flat_1jx9u_2._indeterminate_1jx9u_23,
._flat_1jx9u_2._checked_1jx9u_24._has_label_1jx9u_24 {
  background: var(--color5);
}
._flat_1jx9u_2._indeterminate_1jx9u_23 span[class*=control-indicator],
._flat_1jx9u_2._checked_1jx9u_24 span[class*=control-indicator] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._flat_1jx9u_2._indeterminate_1jx9u_23 input ~ span::before,
._flat_1jx9u_2._indeterminate_1jx9u_23 span[class*=control-indicator]::before,
._flat_1jx9u_2._checked_1jx9u_24 input:checked ~ span::before,
._flat_1jx9u_2._checked_1jx9u_24 span[class*=control-indicator]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._flat_1jx9u_2:hover input:checked ~ span[class*=control-indicator] {
  background-color: var(--color70);
  box-shadow: none;
}
._flat_1jx9u_2._disabled_1jx9u_50 {
  color: var(--color40) !important;
}
._flat_1jx9u_2._checked_1jx9u_24._disabled_1jx9u_50 span[class*=control-indicator] {
  background-color: var(--color40) !important;
}
._flat_1jx9u_2._disabled_1jx9u_50 span[class*=control-indicator] {
  border-color: var(--color40) !important;
}
._flat_1jx9u_2._disabled_1jx9u_50._checked_1jx9u_24 span {
  border: none !important;
  background-color: none !important;
}
._flat_1jx9u_2._disabled_1jx9u_50:hover input:checked ~ span {
  background-color: none !important;
}
._flat_1jx9u_2._disabled_1jx9u_50:hover ._box_layout_1jx9u_6,
._flat_1jx9u_2._checked_1jx9u_24._disabled_1jx9u_50 {
  background: transparent;
}
._flat_1jx9u_2 ._helperText_1jx9u_77,
._flat_1jx9u_2._disabled_1jx9u_50 ._helperText_1jx9u_77 {
  color: var(--color60);
}
._smooth_1jx9u_84 {
  color: var(--color100);
  background: var(--color0);
}
._smooth_1jx9u_84 ._box_layout_1jx9u_6 {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_1jx9u_84:hover {
  background: var(--color5);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_1jx9u_84[class*=control] span[class*=control-indicator] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._smooth_1jx9u_84._checked_1jx9u_24 {
  background: var(--color10);
}
._smooth_1jx9u_84._indeterminate_1jx9u_23 span[class*=control-indicator],
._smooth_1jx9u_84._checked_1jx9u_24 span[class*=control-indicator] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._smooth_1jx9u_84._indeterminate_1jx9u_23 input:checked ~ span::before,
._smooth_1jx9u_84._indeterminate_1jx9u_23 span[class*=control-indicator]::before,
._smooth_1jx9u_84._checked_1jx9u_24 input:checked ~ span::before,
._smooth_1jx9u_84._checked_1jx9u_24 span[class*=control-indicator]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._smooth_1jx9u_84:hover input:checked ~ span[class*=control-indicator] {
  background-color: var(--color70);
  box-shadow: none;
}
._smooth_1jx9u_84 input:not(:checked) ~ span[class*=control-indicator]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
._smooth_1jx9u_84._disabled_1jx9u_50,
._smooth_1jx9u_84._checked_1jx9u_24._disabled_1jx9u_50 {
  color: var(--color40) !important;
  background: var(--color0);
}
._smooth_1jx9u_84._checked_1jx9u_24._disabled_1jx9u_50 span[class*=control-indicator] {
  background-color: var(--color40) !important;
}
._smooth_1jx9u_84._disabled_1jx9u_50 span[class*=control-indicator] {
  border-color: var(--color40) !important;
}
._smooth_1jx9u_84._disabled_1jx9u_50._checked_1jx9u_24 span {
  border: none !important;
  background-color: none !important;
}
._smooth_1jx9u_84._disabled_1jx9u_50:hover input:checked ~ span {
  background-color: none !important;
}
._smooth_1jx9u_84._disabled_1jx9u_50:hover ._box_layout_1jx9u_6 {
  background: transparent;
}
._smooth_1jx9u_84 ._helperText_1jx9u_77,
._smooth_1jx9u_84._disabled_1jx9u_50 ._helperText_1jx9u_77 {
  color: var(--color60);
}
._outlined_1jx9u_172 {
  color: var(--color100);
  box-shadow: 0 0 0 1px var(--color20);
}
._outlined_1jx9u_172 ._box_layout_1jx9u_6 {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1jx9u_172:hover {
  box-shadow: 0 0 0 1px var(--color40);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1jx9u_172[class*=control] span[class*=control-indicator] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._outlined_1jx9u_172._checked_1jx9u_24 {
  box-shadow: 0 0 0 2px var(--color40);
}
._outlined_1jx9u_172._checked_1jx9u_24 span[class*=control-indicator] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._outlined_1jx9u_172._checked_1jx9u_24 input:checked ~ span::before,
._outlined_1jx9u_172._checked_1jx9u_24._indeterminate_1jx9u_23 span[class*=control-indicator]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._outlined_1jx9u_172:hover input:checked ~ span[class*=control-indicator] {
  background-color: var(--color70);
  box-shadow: none;
}
._outlined_1jx9u_172 input:not(:checked) ~ span[class*=control-indicator]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
._outlined_1jx9u_172._disabled_1jx9u_50,
._outlined_1jx9u_172._checked_1jx9u_24._disabled_1jx9u_50 {
  color: var(--color40) !important;
  box-shadow: 0 0 0 1px var(--color10);
}
._outlined_1jx9u_172._checked_1jx9u_24._disabled_1jx9u_50 span[class*=control-indicator] {
  background-color: var(--color40) !important;
}
._outlined_1jx9u_172._disabled_1jx9u_50 span[class*=control-indicator] {
  border-color: var(--color40) !important;
}
._outlined_1jx9u_172._disabled_1jx9u_50._checked_1jx9u_24 span {
  border: none !important;
  background-color: none !important;
}
._outlined_1jx9u_172._disabled_1jx9u_50:hover input:checked ~ span {
  background-color: none !important;
}
._outlined_1jx9u_172._disabled_1jx9u_50:hover ._box_layout_1jx9u_6 {
  background: transparent;
}
._outlined_1jx9u_172 ._helperText_1jx9u_77,
._outlined_1jx9u_172._disabled_1jx9u_50 ._helperText_1jx9u_77 {
  color: var(--color60);
}
._raised_1jx9u_257 {
  color: var(--color100);
  background: var(--color0);
  box-shadow: var(--shadow2dp);
}
._raised_1jx9u_257 ._box_layout_1jx9u_6 {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_1jx9u_257:hover {
  background: var(--white);
  box-shadow: var(--shadow4dp);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_1jx9u_257[class*=control] span[class*=control-indicator] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._raised_1jx9u_257._checked_1jx9u_24 {
  background: var(--color5);
  box-shadow: none;
}
._raised_1jx9u_257._checked_1jx9u_24 span[class*=control-indicator] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._raised_1jx9u_257._checked_1jx9u_24 input:checked ~ span::before,
._raised_1jx9u_257._checked_1jx9u_24._indeterminate_1jx9u_23 span[class*=control-indicator]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._raised_1jx9u_257:hover input:checked ~ span[class*=control-indicator] {
  background-color: var(--color70);
  box-shadow: none;
}
._raised_1jx9u_257._disabled_1jx9u_50,
._raised_1jx9u_257._checked_1jx9u_24._disabled_1jx9u_50 {
  color: var(--color40) !important;
  background: var(--color0);
  box-shadow: none;
}
._raised_1jx9u_257._checked_1jx9u_24._disabled_1jx9u_50 span[class*=control-indicator] {
  background-color: var(--color40) !important;
}
._raised_1jx9u_257._disabled_1jx9u_50 span[class*=control-indicator] {
  border-color: var(--color40) !important;
}
._raised_1jx9u_257._disabled_1jx9u_50._checked_1jx9u_24 span {
  border: none !important;
  background-color: none !important;
}
._raised_1jx9u_257._disabled_1jx9u_50:hover input:checked ~ span {
  background-color: none !important;
}
._raised_1jx9u_257._disabled_1jx9u_50:hover ._box_layout_1jx9u_6 {
  background: transparent;
}
._raised_1jx9u_257 ._helperText_1jx9u_77,
._raised_1jx9u_257._disabled_1jx9u_50 ._helperText_1jx9u_77 {
  color: var(--color60);
}
._disabled_1jx9u_50 {
  font-weight: 400;
}

/* temp_stylePlugin:ni:sha-256;lLagEEatzRh6e33gn8VCH99Oe5EfpZwtKN_Lv5DA4xA */
._default_1fwp3_3 {
  width: var(--s28);
  height: var(--s28);
  min-width: unset;
  min-height: unset;
  border-radius: 99%;
  padding: 0;
}
._square_1fwp3_12 {
  border-radius: var(--s4);
}
._button_1fwp3_17.bp4-button {
  border-radius: 50%;
}
._button_1fwp3_17 svg {
  color: var(--color70);
}
._dense_1fwp3_24 {
  min-width: unset;
  min-height: unset;
  width: var(--s20);
  height: var(--s20);
}
._horizontal_1fwp3_31 {
  display: flex;
  align-items: center;
}
._vertical_1fwp3_36 {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  height: fit-content;
}
._value_1fwp3_43 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.054em;
  color: var(--color70);
  padding-left: var(--s4);
  padding-right: var(--s4);
  margin-top: auto;
  margin-bottom: auto;
  color: var(--color100);
  text-align: center;
}
._verticalValue_1fwp3_59 {
  padding-left: 0;
  padding-right: 0;
  padding-top: var(--s4);
  padding-bottom: var(--s4);
}
._denseValue_1fwp3_66 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.018em;
  color: var(--color70);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

/* temp_stylePlugin:ni:sha-256;D-R1r7fFMwlP1WxEWW9gmXZMQusHYPz-8n91KhmLUCk */
._flat_1eku8_2,
._flat_1eku8_2:not([class*=bp4-intent-]),
._flat_1eku8_2:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._flat_1eku8_2:hover,
._flat_1eku8_2:not([class*=bp4-intent-]):hover,
._flat_1eku8_2:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._flat_1eku8_2:active,
._flat_1eku8_2:not([class*=bp4-intent-]):active,
._flat_1eku8_2:not([class*=bp4-intent-])[class*=active],
._flat_1eku8_2:not([class*=bp4-intent-])._focused_1eku8_23 {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._flat_1eku8_2:disabled,
._flat_1eku8_2:disabled > span > svg,
._flat_1eku8_2:disabled:hover,
._flat_1eku8_2:disabled:active {
  color: var(--color30) !important;
  opacity: 1 !important;
  box-shadow: none !important;
  background: var(--color0) !important;
}
._filled_1eku8_41,
._filled_1eku8_41:visited {
  box-shadow: var(--shadow2dp) !important;
  background: var(--color60) !important;
  color: var(--color0) !important;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._filled_1eku8_41:hover {
  background: var(--color70) !important;
  box-shadow: var(--shadow8dp) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._filled_1eku8_41:active,
._filled_1eku8_41._focused_1eku8_23 {
  background: var(--color80) !important;
  box-shadow: none !important;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._filled_1eku8_41:disabled {
  color: var(--color40) !important;
  background: var(--color5) !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
._smooth_1eku8_71[class*=button],
._smooth_1eku8_71[class*=button]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._smooth_1eku8_71[class*=button]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._smooth_1eku8_71[class*=button]:active,
._smooth_1eku8_71:not([class*=bp4-intent-]):active,
._smooth_1eku8_71:not([class*=bp4-intent-])[class*=active],
._smooth_1eku8_71[class*=button]._focused_1eku8_23 {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._smooth_1eku8_71:disabled,
._smooth_1eku8_71:disabled:hover {
  color: var(--color30) !important;
  background: var(--color0) !important;
  opacity: 1 !important;
}
._outlined_1eku8_102,
._outlined_1eku8_102:not([class*=bp4-intent-]),
._outlined_1eku8_102:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._outlined_1eku8_102:not([class*=bp4-intent-]):hover {
  box-shadow: 0 0 0 2px var(--color30);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1eku8_102:not([class*=bp4-intent-]):active,
._outlined_1eku8_102._focused_1eku8_23 {
  color: var(--color100);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1eku8_102:not([class*=bp4-intent-]):disabled {
  background: none;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
._raised_1eku8_132,
._raised_1eku8_132:not([class*=bp4-intent-]),
._raised_1eku8_132:not([class*=bp4-intent-]):visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._raised_1eku8_132:not([class*=bp4-intent-]):hover {
  background: var(--white);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._raised_1eku8_132:not([class*=bp4-intent-]):active,
._raised_1eku8_132:not([class*=bp4-intent-])._focused_1eku8_23 {
  box-shadow: var(--shadow2dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._raised_1eku8_132:not([class*=bp4-intent-]):disabled {
  color: var(--color40);
  background: var(--color5);
  box-shadow: none;
}

/* temp_stylePlugin:ni:sha-256;hFl6UQLsAU4CplxELwIuEz3GITYGvEZ4NRdQQUmJWtA */
._default_1a3kb_1 {
  width: fit-content;
  border-radius: 0.5rem;
}
._default_1a3kb_1 div[class*=DayPicker-Day],
._default_1a3kb_1 div[class*=DayPicker-Day] > div[class*=day-wrapper] {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  line-height: 2.5rem;
}
._default_1a3kb_1 div[class*=DayPicker-Day]:focus,
._default_1a3kb_1 div[class*=DayPicker-Day] > div[class*=day-wrapper]:focus {
  outline: none;
}
._default_1a3kb_1 div[class*=DayPicker-Weekday] {
  padding: unset;
  width: 2.5rem;
  height: 1.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
}
._default_1a3kb_1 div[class*=datepicker-caption] {
  height: 2.5rem;
  margin: 0 2rem;
}
._default_1a3kb_1 div[class*=datepicker-navbar],
._default_1a3kb_1 div[class*=datepicker-navbar] > button {
  height: 2.5rem;
}
._default_1a3kb_1 div[class*=datepicker-navbar] > button svg {
  width: 1.25rem;
  height: 1.25rem;
}
._default_1a3kb_1 div[class*=datepicker-month-select] > select,
._default_1a3kb_1 div[class*=datepicker-year-select] > select {
  font-family: inherit;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  height: 2.5rem;
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
}
._default_1a3kb_1 div[class*=datepicker-year-select] > select {
  min-width: 68px;
}
._default_1a3kb_1 div[class*=datepicker-month-select] span > svg,
._default_1a3kb_1 div[class*=datepicker-year-select] span > svg {
  height: 2.5rem;
  width: 1.25rem;
}
._default_1a3kb_1 div[class*=datepicker-month-select] span,
._default_1a3kb_1 div[class*=datepicker-year-select] span {
  top: unset;
}
._default_1a3kb_1 div[class*=datepicker-footer] > button {
  line-height: unset;
  height: 2.5rem;
  padding: 0 1rem;
}
._default_1a3kb_1 ul[class*=daterangepicker-shortcuts] {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
}
._default_1a3kb_1 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  height: 2.5rem;
  padding: 0;
  margin: 0.25rem;
  border-radius: 0.25rem;
}
._default_1a3kb_1 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] > div {
  margin: auto 1rem;
}

/* temp_stylePlugin:ni:sha-256;s2JTmBlcW17otxBC5VicWS19vtb2J9yTkbC8UYAakdQ */
._flat_17pyn_2 {
  color: var(--color100);
}
._flat_17pyn_2 div[class*=DayPicker-Day] > div[class*=day-wrapper] {
  border: none !important;
  padding: unset;
}
._flat_17pyn_2 div[class*=DayPicker-Day][class*=DayPicker-Day--selected],
._flat_17pyn_2 div[class*=DayPicker-Day][class*=DayPicker-Day--selected]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}
._flat_17pyn_2 div[class*=divider] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}
._flat_17pyn_2 div[class*=DayPicker-Day--isToday] {
  color: var(--color100);
}
._flat_17pyn_2 div[class*=DayPicker-Day--isToday] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._flat_17pyn_2 div[class*=DayPicker-Day--selected] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._flat_17pyn_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  color: var(--color70);
}
._flat_17pyn_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active],
._flat_17pyn_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item]:hover {
  background: var(--color5);
}
._flat_17pyn_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active] {
  font-weight: 500;
}
._flat_17pyn_2 div[class*=datepicker-navbar] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._flat_17pyn_2 div[class*=datepicker-navbar] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
._flat_17pyn_2 div[class*=datepicker-month-select] > select,
._flat_17pyn_2 div[class*=datepicker-year-select] > select {
  color: var(--color70);
}
._flat_17pyn_2 div[class*=datepicker-month-select] > select:hover,
._flat_17pyn_2 div[class*=datepicker-year-select] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._flat_17pyn_2 div[class*=datepicker-month-select] > span[class*=icon],
._flat_17pyn_2 div[class*=datepicker-year-select] > span[class*=icon] {
  color: var(--color70);
}
._flat_17pyn_2 div[class*=datepicker-footer] > button:hover {
  background: var(--color5);
}
._flat_17pyn_2 div[class*=DayPicker-Day--outside] > div[class*=day-wrapper] {
  color: var(--color40);
}
._flat_17pyn_2 div[class*=DayPicker-Day]:hover {
  background: var(--color0);
}
._smooth_17pyn_101 {
  color: var(--color100);
  background: var(--color0);
}
._smooth_17pyn_101 div[class*=DayPicker-Day] > div[class*=day-wrapper] {
  border: none !important;
  padding: unset;
}
._smooth_17pyn_101 div[class*=DayPicker-Day][class*=DayPicker-Day--selected],
._smooth_17pyn_101 div[class*=DayPicker-Day][class*=DayPicker-Day--selected]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}
._smooth_17pyn_101 div[class*=divider] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}
._smooth_17pyn_101 div[class*=DayPicker-Day--isToday] {
  color: var(--color100);
}
._smooth_17pyn_101 div[class*=DayPicker-Day--isToday] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._smooth_17pyn_101 div[class*=DayPicker-Day--selected] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._smooth_17pyn_101 ul[class*=daterangepicker-shortcuts] {
  background: var(--color0);
}
._smooth_17pyn_101 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  color: var(--color70);
}
._smooth_17pyn_101 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active],
._smooth_17pyn_101 ul[class*=daterangepicker-shortcuts] a[class*=menu-item]:hover {
  background: var(--color0);
  box-shadow: 0 0 0 1px var(--color30);
}
._smooth_17pyn_101 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active] {
  font-weight: 500;
}
._smooth_17pyn_101 div[class*=datepicker-navbar] > button {
  width: 2.5rem;
  border-radius: 4px;
}
._smooth_17pyn_101 div[class*=datepicker-navbar] > button svg {
  color: var(--color70);
}
._smooth_17pyn_101 div[class*=datepicker-navbar] > button:hover {
  color: var(--color100);
  background-color: transparent;
  box-shadow: var(--shadow2dp);
}
._smooth_17pyn_101 div[class*=datepicker-month-select] select,
._smooth_17pyn_101 div[class*=datepicker-month-select],
._smooth_17pyn_101 div[class*=datepicker-year-select] select,
._smooth_17pyn_101 div[class*=datepicker-year-select] {
  color: var(--color70);
}
._smooth_17pyn_101 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical,
._smooth_17pyn_101 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical,
._smooth_17pyn_101 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical,
._smooth_17pyn_101 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical {
  right: 5px !important;
}
._smooth_17pyn_101 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical path,
._smooth_17pyn_101 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical path,
._smooth_17pyn_101 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical path,
._smooth_17pyn_101 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical path {
  d: path("M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z");
}
._smooth_17pyn_101 div[class*=datepicker-month-select] > select,
._smooth_17pyn_101 div[class*=datepicker-year-select] > select {
  color: var(--color70);
}
._smooth_17pyn_101 div[class*=datepicker-month-select] > select:hover,
._smooth_17pyn_101 div[class*=datepicker-year-select] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._smooth_17pyn_101 div[class*=datepicker-month-select] > span[class*=icon],
._smooth_17pyn_101 div[class*=datepicker-year-select] > span[class*=icon] {
  color: var(--color70);
}
._smooth_17pyn_101 div[class*=datepicker-footer] > button:hover {
  background: var(--color5);
}
._smooth_17pyn_101 div[class*=DayPicker-Day--outside] > div[class*=day-wrapper] {
  color: var(--color40);
}
._smooth_17pyn_101 div[class*=DayPicker-Day]:hover {
  background: var(--color0);
}
._raised_17pyn_231 {
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow16dp);
}
._raised_17pyn_231 div[class*=DayPicker-Day] > div[class*=day-wrapper] {
  border: none !important;
  padding: unset;
}
._raised_17pyn_231 div[class*=DayPicker-Day][class*=DayPicker-Day--selected],
._raised_17pyn_231 div[class*=DayPicker-Day][class*=DayPicker-Day--selected]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}
._raised_17pyn_231 div[class*=divider] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}
._raised_17pyn_231 div[class*=DayPicker-Day--isToday] {
  color: var(--color100);
}
._raised_17pyn_231 div[class*=DayPicker-Day--isToday] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._raised_17pyn_231 div[class*=DayPicker-Day--selected] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._raised_17pyn_231 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  color: var(--color70);
}
._raised_17pyn_231 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active],
._raised_17pyn_231 ul[class*=daterangepicker-shortcuts] a[class*=menu-item]:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
._raised_17pyn_231 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active] {
  font-weight: 500;
}
._raised_17pyn_231 div[class*=datepicker-navbar] > button {
  width: 2.5rem;
  border-radius: 4px;
}
._raised_17pyn_231 div[class*=datepicker-navbar] > button svg {
  color: var(--color70);
}
._raised_17pyn_231 div[class*=datepicker-navbar] > button:hover {
  box-shadow: var(--shadow2dp);
  background-color: transparent;
}
._raised_17pyn_231 div[class*=datepicker-navbar] > button:hover svg {
  color: var(--color100);
}
._raised_17pyn_231 div[class*=datepicker-month-select] select,
._raised_17pyn_231 div[class*=datepicker-month-select],
._raised_17pyn_231 div[class*=datepicker-year-select] select,
._raised_17pyn_231 div[class*=datepicker-year-select] {
  color: var(--color70);
}
._raised_17pyn_231 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical,
._raised_17pyn_231 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical,
._raised_17pyn_231 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical,
._raised_17pyn_231 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical {
  right: 5px !important;
}
._raised_17pyn_231 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical path,
._raised_17pyn_231 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical path,
._raised_17pyn_231 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical path,
._raised_17pyn_231 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical path {
  d: path("M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z");
}
._raised_17pyn_231 div[class*=datepicker-month-select] > select:hover,
._raised_17pyn_231 div[class*=datepicker-year-select] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._raised_17pyn_231 div[class*=datepicker-month-select] > span[class*=icon],
._raised_17pyn_231 div[class*=datepicker-year-select] > span[class*=icon] {
  color: var(--color70);
}
._raised_17pyn_231 div[class*=datepicker-footer] > button:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
._raised_17pyn_231 div[class*=DayPicker-Day--outside] > div[class*=day-wrapper] {
  color: var(--color40);
}
._raised_17pyn_231 div[class*=DayPicker-Day]:hover {
  background: var(--color0);
}

/* temp_stylePlugin:ni:sha-256;bG1BBd8zoq0GfM9lg5sKBz0kwX0iJ0i2E1ML3wItaw8 */
._default_1c1cr_1 {
  width: max-content;
  border-radius: 0.5rem;
}
._default_1c1cr_1 div[class*=DayPicker-Day],
._default_1c1cr_1 div[class*=DayPicker-Day] > div[class*=day-wrapper] {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: unset;
}
._default_1c1cr_1 div[class*=DayPicker-Weekday] {
  width: 2.5rem;
  height: 1.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
}
._default_1c1cr_1 div[class*=datepicker-caption] {
  height: 2.5rem;
  margin: 0 2rem;
}
._default_1c1cr_1 div[class*=datepicker-navbar][class*=DayPicker-NavBar] {
  left: 0 !important;
}
._default_1c1cr_1 div[class*=datepicker-navbar],
._default_1c1cr_1 div[class*=datepicker-navbar] > button {
  height: 2.5rem;
}
._default_1c1cr_1 div[class*=datepicker-navbar] > button svg {
  width: 1.25rem;
  height: 1.25rem;
}
._default_1c1cr_1 div[class*=datepicker-month-select] > select,
._default_1c1cr_1 div[class*=datepicker-year-select] > select {
  font-family: inherit;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  height: 2.5rem;
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
}
._default_1c1cr_1 div[class*=datepicker-year-select] > select {
  min-width: 68px;
}
._default_1c1cr_1 div[class*=datepicker-month-select] span > svg,
._default_1c1cr_1 div[class*=datepicker-year-select] span > svg {
  height: 2.5rem;
  width: 1.25rem;
}
._default_1c1cr_1 div[class*=datepicker-month-select] span,
._default_1c1cr_1 div[class*=datepicker-year-select] span {
  top: unset;
}
._default_1c1cr_1 div[class*=datepicker-footer] > button {
  line-height: unset;
  height: 2.5rem;
  padding: 0 1rem;
}
._default_1c1cr_1 ul[class*=daterangepicker-shortcuts] {
  padding-left: 1rem;
  padding-right: 1rem;
}
._default_1c1cr_1 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  height: 2.5rem;
  padding: 0;
  margin: 0.25rem;
  border-radius: 0.25rem;
}
._default_1c1cr_1 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] > div {
  margin: auto 1rem;
}

/* temp_stylePlugin:ni:sha-256;bY8pgVLlYIYNA16P1r1HqFufHMmXsfklIP9KVpMuRSM */
._flat_1fxrg_2 {
  color: var(--color100);
}
._flat_1fxrg_2 div[class*=DayPicker-Day] > div[class*=day-wrapper] {
  border: none !important;
  padding: unset;
}
._flat_1fxrg_2 div[class*=DayPicker-Day][class*=DayPicker-Day--selected],
._flat_1fxrg_2 div[class*=DayPicker-Day][class*=DayPicker-Day--selected]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 0;
}
._flat_1fxrg_2 div[class*=divider] {
  border-bottom: 1px solid var(--color5);
  border-right: 1px solid var(--color5);
  margin: 0.5rem;
}
._flat_1fxrg_2 div[class*=--selected-range-start][aria-selected=true],
._flat_1fxrg_2 div[class*=--selected-range-end][class*=--hovered-range-start][aria-selected=true] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._flat_1fxrg_2 div[class*=--selected-range-end][aria-selected=true],
._flat_1fxrg_2 div[class*=--selected-range-start][class*=--hovered-range-end][aria-selected=true] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._flat_1fxrg_2 div[class*=--selected-range-start][aria-selected=true]:hover,
._flat_1fxrg_2 div[class*=--selected-range-end][aria-selected=true]:hover {
  background-color: var(--color5);
}
._flat_1fxrg_2 div [class*=--selected-range-start][class*=--selected-range-end],
._flat_1fxrg_2 div [class*=--selected-range-start][class*=--selected-range-end]:hover {
  border-radius: 50% !important;
  background-color: var(--color0);
  color: var(--color100);
}
._flat_1fxrg_2 div[class*=--hovered-range-start]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._flat_1fxrg_2 div[class*=--hovered-range-end]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._flat_1fxrg_2 div[class*=--selected-range][aria-selected=false] {
  background-color: var(--color5);
}
._flat_1fxrg_2 div[class*=--selected-range][aria-selected=false]:hover {
  background-color: var(--color10);
}
._flat_1fxrg_2 div[class*=--hovered-range][aria-selected=false]:not([class*=range-start]):not([class*=range-end]) {
  background-color: var(--color5) !important;
}
._flat_1fxrg_2 div[class*=DayPicker][aria-selected=false]:focus {
  background: transparent;
  color: var(--color100);
}
._flat_1fxrg_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  color: var(--color70);
}
._flat_1fxrg_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active],
._flat_1fxrg_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item]:hover {
  background: var(--color5);
}
._flat_1fxrg_2 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active] {
  font-weight: 500;
}
._flat_1fxrg_2 div[class*=datepicker-navbar] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._flat_1fxrg_2 div[class*=datepicker-navbar] > button svg {
  color: var(--color70);
  fill: currentColor;
}
._flat_1fxrg_2 div[class*=datepicker-navbar] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
._flat_1fxrg_2 div[class*=datepicker-month-select] > select,
._flat_1fxrg_2 div[class*=datepicker-year-select] > select {
  color: var(--color70);
}
._flat_1fxrg_2 div[class*=datepicker-month-select] > select:hover,
._flat_1fxrg_2 div[class*=datepicker-year-select] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._flat_1fxrg_2 div[class*=datepicker-month-select] > span[class*=icon],
._flat_1fxrg_2 div[class*=datepicker-year-select] > span[class*=icon] {
  color: var(--color70);
}
._flat_1fxrg_2 div[class*=datepicker-footer] > button:hover {
  background: var(--color5);
}
._flat_1fxrg_2 div[class*=DayPicker-Day--outside] > div[class*=day-wrapper] {
  color: var(--color40);
}
._flat_1fxrg_2 div[class*=DayPicker-Day]:hover {
  background: var(--color0);
}
._smooth_1fxrg_149 {
  color: var(--color100) !important;
  background: var(--color0) !important;
}
._smooth_1fxrg_149 div[class=DayPicker-Day] > div[class*=day-wrapper] {
  border: none !important;
  padding: unset;
}
._smooth_1fxrg_149 div[class*=--selected-range-start][aria-selected=true],
._smooth_1fxrg_149 div[class*=--selected-range-end][class*=--hovered-range-start][aria-selected=true] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color60) !important;
}
._smooth_1fxrg_149 div[class*=--selected-range-end][aria-selected=true],
._smooth_1fxrg_149 div[class*=--selected-range-start][class*=--hovered-range-end][aria-selected=true] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color60) !important;
}
._smooth_1fxrg_149 div[class*=--selected-range-start][aria-selected=true]:hover,
._smooth_1fxrg_149 div[class*=--selected-range-end][aria-selected=true]:hover {
  background-color: var(--color70) !important;
}
._smooth_1fxrg_149 div [class*=--selected-range-start][class*=--selected-range-end],
._smooth_1fxrg_149 div [class*=--selected-range-start][class*=--selected-range-end]:hover {
  border-radius: 50% !important;
  background-color: var(--color60);
  color: var(--color0);
}
._smooth_1fxrg_149 div[class*=--hovered-range-start]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._smooth_1fxrg_149 div[class*=--hovered-range-end]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._smooth_1fxrg_149 div[class*=--selected-range][aria-selected=false] {
  background-color: var(--color10);
}
._smooth_1fxrg_149 div[class*=--selected-range][aria-selected=false]:hover {
  background-color: var(--color20);
}
._smooth_1fxrg_149 div[class*=--hovered-range][aria-selected=false]:not([class*=range-start]):not([class*=range-end]) {
  background-color: var(--color10) !important;
}
._smooth_1fxrg_149 div[class*=DayPicker][aria-selected=false]:focus {
  background: transparent;
  color: var(--color100);
}
._smooth_1fxrg_149 ul[class*=daterangepicker-shortcuts] {
  background: var(--color0);
}
._smooth_1fxrg_149 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  color: var(--color70);
}
._smooth_1fxrg_149 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active],
._smooth_1fxrg_149 ul[class*=daterangepicker-shortcuts] a[class*=menu-item]:hover {
  background: var(--color0);
  box-shadow: 0 0 0 1px var(--color30);
}
._smooth_1fxrg_149 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active] {
  font-weight: 500;
}
._smooth_1fxrg_149 div[class*=divider] {
  border-bottom: 1px solid var(--color10);
  border-right: 1px solid var(--color10);
  margin: 0.5rem;
}
._smooth_1fxrg_149 div[class*=datepicker-navbar] > button {
  width: 2.5rem;
  border-radius: 4px;
  color: var(--color70);
}
._smooth_1fxrg_149 div[class*=datepicker-navbar] > button svg {
  color: var(--color70);
  fill: currentColor;
}
._smooth_1fxrg_149 div[class*=datepicker-navbar] > button:hover {
  background-color: transparent;
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
._smooth_1fxrg_149 div[class*=datepicker-month-select] select,
._smooth_1fxrg_149 div[class*=datepicker-month-select],
._smooth_1fxrg_149 div[class*=datepicker-year-select] select,
._smooth_1fxrg_149 div[class*=datepicker-year-select] {
  color: var(--color70);
}
._smooth_1fxrg_149 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical,
._smooth_1fxrg_149 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical,
._smooth_1fxrg_149 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical,
._smooth_1fxrg_149 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical {
  right: 0px !important;
}
._smooth_1fxrg_149 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical path,
._smooth_1fxrg_149 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical path,
._smooth_1fxrg_149 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical path,
._smooth_1fxrg_149 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical path {
  d: path("M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z");
}
._smooth_1fxrg_149 div[class*=html-select]:hover select {
  background-color: var(--color5);
  color: var(--color100);
}
._smooth_1fxrg_149 div[class*=datepicker-month-select] > span[class*=icon],
._smooth_1fxrg_149 div[class*=datepicker-year-select] > span[class*=icon] {
  color: var(--color70);
}
._smooth_1fxrg_149 div[class*=datepicker-footer] > button:hover {
  background: var(--color5);
}
._smooth_1fxrg_149 div[class*=DayPicker-Day--outside] > div[class*=day-wrapper] {
  color: var(--color40);
}
._smooth_1fxrg_149 div[class*=DayPicker-Day]:hover {
  background: var(--color0);
}
._raised_1fxrg_307 {
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow16dp);
}
._raised_1fxrg_307 div[class*=DayPicker-Day] > div[class*=day-wrapper] {
  border: none !important;
  padding: unset;
}
._raised_1fxrg_307 div[class*=DayPicker-Day][class*=DayPicker-Day--selected],
._raised_1fxrg_307 div[class*=DayPicker-Day][class*=DayPicker-Day--selected]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 0;
}
._raised_1fxrg_307 div[class*=divider] {
  border-bottom: 1px solid var(--color5);
  border-right: 1px solid var(--color5);
  margin: 0.5rem;
}
._raised_1fxrg_307 div[class*=--selected-range-start][aria-selected=true],
._raised_1fxrg_307 div[class*=--selected-range-end][class*=--hovered-range-start][aria-selected=true] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color0) !important;
  box-shadow: inset 0 0 0 2px var(--color60);
}
._raised_1fxrg_307 div[class*=--selected-range-end][aria-selected=true],
._raised_1fxrg_307 div[class*=--selected-range-start][class*=--hovered-range-end][aria-selected=true] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color0) !important;
  box-shadow: inset 0 0 0 2px var(--color60);
}
._raised_1fxrg_307 div[class*=--selected-range-start][aria-selected=true]:hover,
._raised_1fxrg_307 div[class*=--selected-range-end][aria-selected=true]:hover {
  background-color: var(--color5);
}
._raised_1fxrg_307 div [class*=--selected-range-start][class*=--selected-range-end],
._raised_1fxrg_307 div [class*=--selected-range-start][class*=--selected-range-end]:hover {
  border-radius: 50% !important;
  background-color: var(--color0);
  color: var(--color100);
}
._raised_1fxrg_307 div[class*=--hovered-range-start]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color0);
}
._raised_1fxrg_307 div[class*=--hovered-range-end]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color0);
}
._raised_1fxrg_307 div[class*=--selected-range][aria-selected=false] {
  background-color: var(--color5) !important;
}
._raised_1fxrg_307 div[class*=--selected-range][aria-selected=false]:hover {
  background-color: var(--color10) !important;
}
._raised_1fxrg_307 div[class*=--hovered-range][aria-selected=false]:not([class*=range-start]):not([class*=range-end]) {
  background-color: var(--color5) !important;
}
._raised_1fxrg_307 div[class*=DayPicker][aria-selected=false]:focus {
  background: transparent;
  color: var(--color100);
}
._raised_1fxrg_307 ul[class*=daterangepicker-shortcuts] a[class*=menu-item] {
  color: var(--color70);
}
._raised_1fxrg_307 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active],
._raised_1fxrg_307 ul[class*=daterangepicker-shortcuts] a[class*=menu-item]:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
._raised_1fxrg_307 ul[class*=daterangepicker-shortcuts] a[class*=menu-item][class*=active] {
  font-weight: 500;
}
._raised_1fxrg_307 div[class*=datepicker-navbar] > button {
  width: 2.5rem;
  border-radius: 4px;
}
._raised_1fxrg_307 div[class*=datepicker-navbar] > button svg {
  color: var(--color70);
}
._raised_1fxrg_307 div[class*=datepicker-navbar] > button:hover {
  box-shadow: var(--shadow2dp);
  background-color: transparent;
}
._raised_1fxrg_307 div[class*=datepicker-navbar] > button:hover svg {
  color: var(--color100);
}
._raised_1fxrg_307 div[class*=datepicker-month-select] select,
._raised_1fxrg_307 div[class*=datepicker-month-select],
._raised_1fxrg_307 div[class*=datepicker-year-select] select,
._raised_1fxrg_307 div[class*=datepicker-year-select] {
  color: var(--color70);
}
._raised_1fxrg_307 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical,
._raised_1fxrg_307 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical,
._raised_1fxrg_307 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical,
._raised_1fxrg_307 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical {
  right: 5px !important;
}
._raised_1fxrg_307 div[class*=datepicker-month-select] select .bp4-icon-double-caret-vertical path,
._raised_1fxrg_307 div[class*=datepicker-month-select] .bp4-icon-double-caret-vertical path,
._raised_1fxrg_307 div[class*=datepicker-year-select] select .bp4-icon-double-caret-vertical path,
._raised_1fxrg_307 div[class*=datepicker-year-select] .bp4-icon-double-caret-vertical path {
  d: path("M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z");
}
._raised_1fxrg_307 div[class*=datepicker-month-select] > select,
._raised_1fxrg_307 div[class*=datepicker-year-select] > select {
  color: var(--color70);
}
._raised_1fxrg_307 div[class*=datepicker-month-select] > select:hover,
._raised_1fxrg_307 div[class*=datepicker-year-select] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._raised_1fxrg_307 div[class*=datepicker-month-select] > span[class*=icon],
._raised_1fxrg_307 div[class*=datepicker-year-select] > span[class*=icon] {
  color: var(--color70);
}
._raised_1fxrg_307 div[class*=datepicker-footer] > button:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
._raised_1fxrg_307 div[class*=DayPicker-Day--outside] > div[class*=day-wrapper] {
  color: var(--color40);
}
._raised_1fxrg_307 div[class*=DayPicker-Day]:hover {
  background: var(--color0);
}

/* temp_stylePlugin:ni:sha-256;Y3ZjJY34Kq74neliKryjeXXvsIhu5q5eJEE_vFX_Ijc */
._icon_18jrp_1 {
  display: inline;
}
._icon_18jrp_1 svg {
  margin-right: 7px;
}
._clickable_18jrp_8 {
  cursor: pointer;
}
._none_18jrp_12 svg {
  color: currentColor;
  color: var(--grey70);
}
._primary_18jrp_17 svg {
  color: currentColor;
  color: var(--blue70);
}
._success_18jrp_22 svg {
  color: currentColor;
  color: var(--green70);
}
._warning_18jrp_27 svg {
  color: currentColor;
  color: var(--orange70);
}
._danger_18jrp_32 svg {
  color: currentColor;
  color: var(--red70);
}

/* temp_stylePlugin:ni:sha-256;LHWvbKCWexbkfIZeQPQozW-3pKTQRLX-TD9BFloU4XI */
._default_1aqu5_3 {
  width: 600px;
  padding: 24px;
  border-radius: 1.5rem;
}
._default_1aqu5_3._full_page_1aqu5_9 {
  width: 100vw;
  height: calc(100vh - 32px);
  margin: 16px;
}
._default_1aqu5_3._full_page_1aqu5_9 ._content_1aqu5_14 {
  overflow-y: auto;
}
._title_1aqu5_18 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--color100);
}
._header_1aqu5_27 {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}
._content_1aqu5_14 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
  color: var(--color100) !important;
  flex: 1;
}
._header_1aqu5_27 > span[class*=icon] {
  margin-right: 1rem;
}
._footer_1aqu5_49 {
  display: flex;
  margin-left: auto;
  margin-top: 32px;
}
._footer_1aqu5_49 button[class*=button] + button[class*=button] {
  margin-left: 40px;
}
._dense_1aqu5_58 > ._footer_1aqu5_49 {
  margin-top: 16px;
}
._dense_1aqu5_58 {
  width: 98%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
}
._dense_1aqu5_58._header_1aqu5_27 {
  margin-bottom: 0.75rem;
}
._dense_1aqu5_58._header_1aqu5_27 > span[class*=icon] {
  margin-right: 0.75rem;
}
._dense_1aqu5_58 ._title_1aqu5_18 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--color100);
}
._dense_1aqu5_58 ._content_1aqu5_14 {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}
._dense_1aqu5_58._footer_1aqu5_49 {
  margin-top: 16px;
}
._close-button_1aqu5_99 {
  margin-left: auto;
}
._close-button_1aqu5_99 .bp4-icon-cross svg {
  margin-right: 0 !important;
}
._hint_1aqu5_106 {
  color: var(--blue100);
  margin-bottom: 16px;
}

/* temp_stylePlugin:ni:sha-256;S7jZs1Ob_dyKlf8W3bCx3TDxVGShFBaLX4XdPJo_dTg */
._smooth_u5eqw_3 {
  background: var(--color5);
  box-shadow: none;
}
._raised_u5eqw_9 {
  background: var(--white);
  box-shadow: var(--shadow24dp);
}
._filled_u5eqw_15 {
  background: var(--color50);
  box-shadow: none;
}
._filled_u5eqw_15 > div[class*=header] > div,
._filled_u5eqw_15 > div[class*=body] {
  color: var(--color0);
}
._filled_u5eqw_15 > div[class*=header] span[class*=icon] svg,
._raised_u5eqw_9 > div[class*=header] span[class*=icon] svg,
._smooth_u5eqw_3 > div[class*=header] span[class*=icon] svg {
  fill: var(--color30);
  margin-right: 20px;
}
._filled_u5eqw_15 > div[class*=header] div[class*=close-button] svg,
._raised_u5eqw_9 > div[class*=header] div[class*=close-button] svg,
._smooth_u5eqw_3 > div[class*=header] div[class*=close-button] svg {
  fill: var(--color30);
  transition: all 150ms ease-in-out;
}
._raised_u5eqw_9 > div[class*=header] div[class*=close-button]:hover svg,
._smooth_u5eqw_3 > div[class*=header] div[class*=close-button]:hover svg {
  fill: var(--color60);
  transition: all 150ms ease-in-out;
}
._filled_u5eqw_15 > div[class*=header] div[class*=close-button]:hover svg {
  fill: var(--color5);
  transition: all 150ms ease-in-out;
}
._filled_u5eqw_15 > div[class*=header] div[class*=close-button]:hover,
._raised_u5eqw_9 > div[class*=header] div[class*=close-button]:hover,
._smooth_u5eqw_3 > div[class*=header] div[class*=close-button]:hover {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
._backdrop-0_u5eqw_60 {
  background-color: rgba(25, 25, 29, 0);
}
._backdrop-10_u5eqw_64 {
  background-color: rgba(25, 25, 29, 0.1);
}
._backdrop-20_u5eqw_68 {
  background-color: rgba(25, 25, 29, 0.2);
}
._backdrop-30_u5eqw_72 {
  background-color: rgba(25, 25, 29, 0.3);
}
._backdrop-40_u5eqw_76 {
  background-color: rgba(25, 25, 29, 0.4);
}
._backdrop-50_u5eqw_80 {
  background-color: rgba(25, 25, 29, 0.5);
}
._backdrop-60_u5eqw_84 {
  background-color: rgba(25, 25, 29, 0.6);
}
._backdrop-70_u5eqw_88 {
  background-color: rgba(25, 25, 29, 0.7);
}
._backdrop-80_u5eqw_92 {
  background-color: rgba(25, 25, 29, 0.8);
}
._backdrop-90_u5eqw_96 {
  background-color: rgba(25, 25, 29, 0.9);
}
._backdrop-100_u5eqw_100 {
  background-color: #19191d;
}

/* temp_stylePlugin:ni:sha-256;ay3sIDGVbSkPQVXuhsISDsjb-yOrNbLSYHe1mEeUXts */
._default_886xy_1 {
  width: 100%;
  margin: 0.5rem 0;
}
._default_886xy_1._vertical_886xy_6 {
  margin: 0 0.5rem;
  min-height: 28px;
  height: 100%;
}

/* temp_stylePlugin:ni:sha-256;FhANUfSvGUks6Z54j-noylmur181_-G9DyLxBiGhlXg */
._color0_5znae_1 {
  background: var(--color0);
}
._color5_5znae_5 {
  background: var(--color5);
}
._color10_5znae_9 {
  background: var(--color10);
}
._color20_5znae_13 {
  background: var(--color20);
}
._color30_5znae_17 {
  background: var(--color30);
}
._color40_5znae_21 {
  background: var(--color40);
}
._color50_5znae_25 {
  background: var(--color50);
}
._color60_5znae_29 {
  background: var(--color60);
}
._color70_5znae_33 {
  background: var(--color70);
}
._color80_5znae_37 {
  background: var(--color80);
}
._color90_5znae_41 {
  background: var(--color90);
}
._color100_5znae_45 {
  background: var(--color100);
}

/* temp_stylePlugin:ni:sha-256;QrL54bke6SOiKSKI4e7Qyqs31iSrTUboQoXeldl-tPc */
._dropdown_1dq0l_1,
._dropdown--dense_1dq0l_3,
._dropdown_1dq0l_1:after,
._dropdown--dense_1dq0l_3::after {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._dropdown_1dq0l_1[disabled] {
  cursor: default;
}
._dropdown_1dq0l_1 {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}
._dropdown--dense_1dq0l_3 {
  height: 1.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: var(--font_size_body_small);
}
._dropdown--dense_1dq0l_3::after,
._dropdown_1dq0l_1::after {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.35em solid transparent;
  border-bottom: 0;
  border-left: 0.35em solid transparent;
  border-radius: 0.125rem;
}
._dropdown_1dq0l_1,
._dropdown--dense_1dq0l_3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._fill_1dq0l_89 {
  width: 100%;
}

/* temp_stylePlugin:ni:sha-256;wlsKp68qAgq3aR0neVwPTL-tePSk_a2qfUM9gVuYLLA */
._popover_18e28_1[class*=minimal] {
  box-shadow: none;
  margin-top: 4px !important;
  background: transparent;
}
._popover_18e28_1 > div[class*=popover-content] {
  background: transparent;
}
._outlined_18e28_19::after,
._raised_18e28_21::after,
._smooth_18e28_23::after {
  color: var(--color40);
}
._outlined_18e28_19:not(:disabled):hover::after,
._raised_18e28_21:hover::after,
._smooth_18e28_23:hover::after {
  color: var(--color60);
}
._outlined_18e28_19:not(:disabled):active::after,
._outlined_18e28_19:not(:disabled):focus::after,
._raised_18e28_21:active::after,
._raised_18e28_21:focus::after,
._smooth_18e28_23:active::after,
._smooth_18e28_23:focus::after {
  color: var(--color90);
}
._filled_18e28_55::after {
  color: var(--color10);
}
._filled_18e28_55:hover::after {
  color: var(--color5);
}
._filled_18e28_55:active::after,
._filled_18e28_55:focus::after {
  color: var(--color0);
}
._outlined_18e28_19 {
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 1px var(--color30);
}
._outlined_18e28_19:not(:disabled):hover {
  box-shadow: 0 0 0 1px var(--color80);
}
._outlined_18e28_19:not(:disabled):active,
._outlined_18e28_19:not(:disabled):focus {
  box-shadow: 0 0 0 2px var(--color80);
}
._outlined_18e28_19:disabled {
  color: var(--color40);
}
._raised_18e28_21 {
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow2dp);
}
._raised_18e28_21:hover {
  box-shadow: var(--shadow4dp);
}
._raised_18e28_21:active,
._raised_18e28_21:focus {
  box-shadow: var(--shadow8dp);
}
._smooth_18e28_23 {
  border: none;
  color: var(--color100);
  background: var(--color5);
}
._smooth_18e28_23:hover {
  background: var(--color10);
}
._smooth_18e28_23:active,
._smooth_18e28_23:focus {
  background: var(--color20);
}
._filled_18e28_55 {
  border: none;
  color: var(--color0);
  background: var(--color40);
}
._filled_18e28_55:hover {
  background: var(--color50);
}
._filled_18e28_55:active,
._filled_18e28_55:focus {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
}

/* temp_stylePlugin:ni:sha-256;avjbDlrEXeBf38tfRDusIYI5jQwnMW9gy9wPjBBruLw */
._dense_662w8_1 {
  padding-right: 22px;
}
._icon_662w8_5 {
  cursor: pointer;
  position: absolute;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  right: 5px;
  top: -1px;
}
._icon_662w8_5 svg {
  color: var(--color30);
}
._wrapper_662w8_16 {
  display: inline-block;
  max-width: 100%;
  position: relative;
  line-height: 21px;
}
._wrapper_662w8_16._multiline_662w8_22 {
  display: block;
}
._wrapper_662w8_16:hover ._icon_662w8_5 svg {
  color: var(--color60);
}
._isFill_662w8_29 {
  width: 100%;
}
._dense_662w8_1[class*=editable-text-editing]::before {
  box-shadow: 0 0 0 1px var(--color70);
}
._dense_662w8_1[class*=editable-text-editing] ~ ._icon_662w8_5 {
  display: none;
}
._dense_662w8_1 input {
  border: none;
  border-radius: 0.25rem;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}

/* temp_stylePlugin:ni:sha-256;Sx5odY3GX2K4WaBrbqzxvJmtH-e3OJ7SNcyAmcl_4dU */
._outlined_1uw97_3[class*=editable-text] {
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1uw97_3[class*=editable-text]:hover:before {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1uw97_3[class*=editable-text]:active:before {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* temp_stylePlugin:ni:sha-256;koXmOjjYiQp6l_BMVanHpv4AGtMt_BAvAJIB4jw7hFE */
._label_11ejv_1 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
  margin-bottom: 3px;
}
._inline_label_11ejv_12 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
  margin-bottom: 0;
}
._container_11ejv_23 {
  margin-bottom: 10px;
}
._inline_container_11ejv_27 {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* temp_stylePlugin:ni:sha-256;6UqYiWsUfnxFJq9NVFxocz4VmiLy0pA4bEcwAJMEECA */
._input_19u7a_1 {
  display: flex;
  flex-direction: column;
}
._input_19u7a_1 ._label_19u7a_5 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
  color: var(--color40);
}
._input_19u7a_1 ._fileCounter_19u7a_15 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
  align-self: flex-end;
  color: var(--color40);
}
._input_19u7a_1 span::after {
  height: 28px;
  margin: 6px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  color: var(--color70);
  background: var(--color5);
  border: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._input_19u7a_1:hover span::after {
  background-color: var(--color10);
  border: none;
  box-shadow: none;
}
._input_19u7a_1 .bp4-file-upload-input {
  height: 40px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_19u7a_1 .bp4-file-upload-input label {
  height: 36px;
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._input_19u7a_1 .bp4-file-upload-input > span {
  font-size: 16px;
}
._input_19u7a_1 .bp4-file-upload-input span._value_19u7a_62 {
  color: var(--color100);
}
._input_19u7a_1 .bp4-file-upload-input span._placehodler_19u7a_65 {
  color: var(--color40);
}
._input_19u7a_1 .bp4-file-upload-input:hover {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_19u7a_1 .bp4-file-upload-input:hover label {
  color: var(--color70);
}
._input_19u7a_1 .bp4-file-upload-input:hover span._value_19u7a_62 {
  color: var(--color70);
}
._input_19u7a_1 .bp4-file-upload-input:active {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_19u7a_1 .bp4-file-upload-input:active input[class*=input] {
  box-shadow: 0 0 0 2px var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_19u7a_1 .bp4-file-upload-input:active span {
  background: var(--color20);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._input_19u7a_1 .bp4-file-input {
  height: 36px;
}

/* temp_stylePlugin:ni:sha-256;DVryF0Mac-UBFdLnxYu9RJ33PK7tvSX9bHDZ8_V57HU */
._H1_1eieb_1 {
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.026em;
  color: var(--blue100);
}
._H2_1eieb_11 {
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.025em;
  color: var(--blue100);
}
._H3_1eieb_21 {
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: var(--blue100);
}

/* temp_stylePlugin:ni:sha-256;T6d7MxLtXL42EHwGfwbsbB4QhQ0te3pK6l2Khgvkl08 */
._highlighted_lil43_1 {
  background-color: var(--blue10);
}

/* temp_stylePlugin:ni:sha-256;QOV8H3UhEnbpzQTpeo2h_04yS6taySCRohZgHwiTvzk */
._reverseFlex_eptb1_1 {
  display: flex;
  flex-direction: column-reverse;
}
._reverseFlex_eptb1_1 ._error-helper-text_eptb1_5._def_eptb1_5 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
}
._reverseFlex_eptb1_1 ._error-helper-text_eptb1_5._dense_eptb1_14 {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.06em;
  color: var(--grey50);
}
._reverseFlex_eptb1_1._fill_eptb1_23 {
  width: auto;
}
._def_eptb1_5:not(._fill_eptb1_23),
._dense_eptb1_14:not(._fill_eptb1_23) {
  width: fit-content;
}
._def_eptb1_5:placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}
._dense_eptb1_14:placeholder {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}
._fill_eptb1_23 {
  width: 100% !important;
}
._def_eptb1_5[class*=input-group] input[class*=input] {
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem;
}
._def_eptb1_5[class*=input-group] input[class*=input] {
  height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}
._def_eptb1_5 label {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
._dense_eptb1_14[class*=input-group] input[class*=input] {
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.375rem;
}
._dense_eptb1_14[class*=input-group] input[class*=input] {
  height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
}
._dense_eptb1_14 label {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
._def_eptb1_5[class*=input-group] > span[class*=input-action],
._dense_eptb1_14[class*=input-group] > span[class*=input-action] {
  margin: 0 !important;
}
._def_eptb1_5[class*=input-group] > span[class*=input-action] button,
._dense_eptb1_14[class*=input-group] > span[class*=input-action] button {
  margin: 0;
}
._def_eptb1_5._clearButton_eptb1_111[class*=input-group] span[class*=input-action] button {
  height: 2.5rem;
  padding: 0;
  margin: 0;
  margin-right: 0.5rem;
  margin-top: 0;
}
._dense_eptb1_14._clearButton_eptb1_111[class*=input-group] span[class*=input-action] button {
  height: 1.75rem;
  padding: 0;
  margin: 0;
  margin-right: 0.125rem;
}
._dense_eptb1_14._clearButton_eptb1_111[class*=input-group] span[class*=input-action] > button svg {
  width: 1rem;
  height: 1rem;
}
._clearButton_eptb1_111[class*=input-group] span[class*=input-action] {
  top: unset;
}
._def_eptb1_5._right-icon_eptb1_136[class*=input-group] span[class*=input-action] {
  padding: 0;
  margin: 0;
}
._def_eptb1_5._right-icon_eptb1_136[class*=input-group] span[class*=input-action] > div {
  margin-right: 0.625rem;
  margin-top: 0.625rem;
}
._dense_eptb1_14._right-icon_eptb1_136[class*=input-group] span[class*=input-action] {
  padding: 0;
  margin: 0;
}
._dense_eptb1_14._right-icon_eptb1_136[class*=input-group] span[class*=input-action] > div {
  margin-right: 0.375rem;
}
._right-icon_eptb1_136[class*=input-group] span[class*=input-action] {
  top: unset;
}
._def_eptb1_5 ._error-helper-text_eptb1_5 {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  animation: _shake-horizontal_eptb1_1 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
._dense_eptb1_14 ._error-helper-text_eptb1_5 {
  position: absolute;
  bottom: -0.875rem;
  z-index: 1;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  font-weight: var(--font_weight_caption_small);
  animation: _shake-horizontal_eptb1_1 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@keyframes _shake-horizontal_eptb1_1 {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

/* temp_stylePlugin:ni:sha-256;pOeRmaoZWbjiXzZNLN9KFt97xHhDNattoHxRVIZXVWo */
._smooth_lfm7d_3[class*=input-group] input[class*=input] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_lfm7d_3[class*=input-group]._active_lfm7d_10 input[class*=input],
._smooth_lfm7d_3[class*=input-group] input[class*=input]:hover {
  color: var(--color70);
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_lfm7d_3[class*=input-group]._active_lfm7d_10 input[class*=input]::placeholder {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_lfm7d_3[class*=input-group] input[class*=input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._smooth_lfm7d_3[class*=input-group] ~ label {
  opacity: 0;
  color: var(--color50);
}
._smooth_lfm7d_3[class*=input-group]._active_lfm7d_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._smooth_lfm7d_3[class*=input-group] input[class*=input]:focus {
  box-shadow: none;
}
._smooth_lfm7d_3[class*=input-group] > span svg {
  color: var(--color40);
  margin-left: 7px;
}
._smooth_lfm7d_3[class*=input-group]._active_lfm7d_10 > span svg {
  color: var(--color60);
}
._outlined_lfm7d_56[class*=input-group] input[class*=input] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_lfm7d_56[class*=input-group] input[class*=input]:hover {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_lfm7d_56[class*=input-group]:active input[class*=input] {
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_lfm7d_56[class*=input-group]._active_lfm7d_10 input[class*=input] {
  box-shadow: 0 0 0 2px var(--color80);
}
._outlined_lfm7d_56._active_lfm7d_10[class*=input-group] input[class*=input] {
  box-shadow: 0 0 0 1px var(--color70);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_lfm7d_56[class*=input-group] input[class*=input]:active {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_lfm7d_56[class*=input-group] input[class*=input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._outlined_lfm7d_56[class*=input-group] input[class*=input]:hover::placeholder {
  color: var(--color70);
}
._outlined_lfm7d_56[class*=input-group] ~ label {
  opacity: 0;
  color: var(--color50);
}
._outlined_lfm7d_56[class*=input-group]._active_lfm7d_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._outlined_lfm7d_56[class*=input-group] > span svg {
  color: var(--color40);
  margin-left: 7px;
}
._outlined_lfm7d_56[class*=input-group]._active_lfm7d_10 > span svg {
  color: var(--color60);
}
._outlined_lfm7d_56[class*=input-group][class*=disabled] input[class*=input],
._outlined_lfm7d_56[class*=input-group][class*=disabled]:hover input[class*=input] {
  box-shadow: 0 0 0 1px var(--color30);
  color: var(--color30);
}
._outlined_lfm7d_56[class*=input-group][class*=disabled] input[class*=input]::placeholder,
._outlined_lfm7d_56[class*=input-group][class*=disabled]:hover input[class*=input]::placeholder {
  color: var(--color30);
}
._raised_lfm7d_133[class*=input-group] input[class*=input] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_lfm7d_133[class*=input-group] input[class*=input]:hover {
  color: var(--color100);
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_lfm7d_133._active_lfm7d_10[class*=input-group] input[class*=input],
._raised_lfm7d_133._active_lfm7d_10._success_lfm7d_147[class*=input-group] input[class*=input],
._raised_lfm7d_133._active_lfm7d_10._error-helper-text_lfm7d_148[class*=input-group] input[class*=input] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_lfm7d_133[class*=input-group]._active_lfm7d_10 input[class*=input] {
  box-shadow: var(--shadow8dp);
}
._raised_lfm7d_133[class*=input-group] input[class*=input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._raised_lfm7d_133[class*=input-group] input[class*=input]:hover::placeholder {
  color: var(--color60);
}
._raised_lfm7d_133[class*=input-group] ~ label {
  color: var(--color50);
  opacity: 0;
}
._raised_lfm7d_133[class*=input-group]._active_lfm7d_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._raised_lfm7d_133[class*=input-group] > span svg {
  color: var(--color40);
  margin-left: 7px;
}
._raised_lfm7d_133[class*=input-group]._active_lfm7d_10 > span svg {
  color: var(--color60);
}
._filled_lfm7d_191[class*=input-group] input[class*=input] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_lfm7d_191[class*=input-group] input[class*=input]:hover {
  color: var(--color0);
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_lfm7d_191._active_lfm7d_10[class*=input-group] input[class*=input] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_lfm7d_191[class*=input-group]._active_lfm7d_10 input[class*=input] {
  box-shadow: none;
  background: var(--color70);
}
._filled_lfm7d_191[class*=input-group] input[class*=input]::placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._filled_lfm7d_191[class*=input-group] ~ label {
  opacity: 0;
}
._filled_lfm7d_191[class*=input-group]._active_lfm7d_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._filled_lfm7d_191[class*=input-group] > span svg {
  color: var(--color40);
  margin-left: 7px;
}
._filled_lfm7d_191[class*=input-group]._active_lfm7d_10 > span svg {
  color: var(--color60);
}
._error-helper-text_lfm7d_148 {
  color: var(--red60) !important;
}
._error-helper-text_lfm7d_148 span[class*=input-action] svg {
  color: var(--red60);
}
._filled_lfm7d_191._error-helper-text_lfm7d_148 span[class*=input-action] svg {
  color: var(--color0);
}
._success_lfm7d_147 span[class*=input-action] svg {
  color: var(--green60);
}
._filled_lfm7d_191._success_lfm7d_147 span[class*=input-action] svg {
  color: var(--red60);
}
._clearButton_lfm7d_268[class*=input-group] > input + span[class*=input-action] > button {
  cursor: pointer;
}
._clearButton_lfm7d_268[class*=input-group]._active_lfm7d_10 span[class*=input-action] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._clearButton_lfm7d_268[class*=input-group]._active_lfm7d_10 span[class*=input-action] > button[class*=button],
._clearButton_lfm7d_268[class*=input-group]._active_lfm7d_10 span[class*=input-action] > button[class*=button] > span[class*=icon] {
  color: var(--color40) !important;
  opacity: 1;
  visibility: visible;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_lfm7d_191._clearButton_lfm7d_268[class*=input-group]:hover span[class*=input-action] > button[class*=button] > span[class*=icon] {
  color: var(--color5) !important;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* temp_stylePlugin:ni:sha-256;Ob4I0i96a4ZxcBbp4DAgEUH9kqlsZb77-KXQrqy4T6w */
._def_11mla_3,
._def_11mla_3 div[class*=popover-content] > ul[class*=menu] {
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._def_11mla_3 div[class*=popover-content] {
  border-radius: 0.5rem;
}
._def_11mla_3 > li:not([class*=menu-divider]),
._def_11mla_3 div[class*=popover-content] > ul[class*=menu] > li:not([class*=menu-divider]) {
  margin: 0;
}
._def_11mla_3 > li:first-of-type,
._def_11mla_3 div[class*=popover-content] > ul[class*=menu] > li:first-of-type {
  padding-top: 0.5rem;
}
._def_11mla_3 > li:last-of-type,
._def_11mla_3 div[class*=popover-content] > ul[class*=menu] > li:last-of-type {
  padding-bottom: 0.5rem;
}
._def_11mla_3 div[class*=popover][class*=submenu],
._dense_11mla_32 div[class*=popover][class*=submenu] {
  padding: 0;
  margin-left: -0.0625rem;
}
._def_11mla_3 div[class*=popover][class*=submenu],
._dense_11mla_32 div[class*=popover][class*=submenu] {
  animation: _slide-fwd-right_11mla_1 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
._def_11mla_3 > li[class*=menu-header]:not([class*=menu-divider]),
._dense_11mla_32 > li[class*=menu-header]:not([class*=menu-divider]) {
  margin-top: 0.5rem;
}
._dense_11mla_32,
._dense_11mla_32 div[class*=popover-content] > ul[class*=menu] {
  border-radius: 0.375rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}
._dense_11mla_32 div[class*=popover-content] {
  border-radius: 0.375rem;
}
._dense_11mla_32 > li:not([class*=menu-divider]),
._dense_11mla_32 div[class*=popover-content] > ul[class*=menu] > li:not([class*=menu-divider]) {
  margin: 0;
}
._dense_11mla_32 > li:first-of-type,
._dense_11mla_32 div[class*=popover-content] > ul[class*=menu] > li:first-of-type {
  padding-top: 0.5rem;
}
._dense_11mla_32 > li:last-of-type,
._dense_11mla_32 div[class*=popover-content] > ul[class*=menu] > li:last-of-type {
  padding-bottom: 0.5rem;
}
@keyframes _slide-fwd-right_11mla_1 {
  0% {
    transform: scale(0.9) translateX(-20%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

/* temp_stylePlugin:ni:sha-256;iTqOAUQgz5v10DtucpxQlVuBdZq5oWlgMan1aGo21eE */
._smooth_15hc4_3,
._smooth_15hc4_3 div[class*=popover-content] > ul[class*=menu] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  background: var(--color0);
}
._smooth_15hc4_3 div[class*=popover] > div[class*=popover-content] {
  box-shadow: none;
}
._smooth_15hc4_3 svg {
  fill: currentColor;
  color: var(--color70);
}
._smooth_15hc4_3 li:not([class*=menu-divider]) a {
  background-color: unset;
}
._smooth_15hc4_3 li:not([class*=menu-divider]) a:hover:not([class*=disabled]) {
  background-color: var(--white);
}
._smooth_15hc4_3 a[class*=disabled] > div,
._smooth_15hc4_3 a > span[class*=label],
._smooth_15hc4_3 a[class*=disabled] > span[class*=item-label],
._smooth_15hc4_3 a[class*=disabled] svg,
._smooth_15hc4_3 a > span[class*=label] > span > svg {
  fill: currentColor;
  color: var(--color40) !important;
}
._smooth_15hc4_3 li[class*=submenu] span[class*=popover-wrapper] > span[class*=popover-open] > a:not(:hover) {
  background-color: var(--color5);
}
._outlined_15hc4_41,
._outlined_15hc4_41 div[class*=popover-content] > ul[class*=menu] {
  box-shadow: none;
  color: var(--color100);
  background: var(--white);
  border: 0.125rem solid var(--color30);
}
._outlined_15hc4_41 div[class*=popover] > div[class*=popover-content] {
  box-shadow: none;
}
._outlined_15hc4_41 svg {
  fill: currentColor;
  color: var(--color70);
}
._outlined_15hc4_41 li:not([class*=menu-divider]) a {
  background-color: unset;
}
._outlined_15hc4_41 li:not([class*=menu-divider]) a:hover:not([class*=disabled]) {
  background-color: var(--color5);
}
._outlined_15hc4_41 a[class*=disabled] > div,
._outlined_15hc4_41 a > span[class*=label],
._outlined_15hc4_41 a[class*=disabled] > span[class*=item-label],
._outlined_15hc4_41 a[class*=disabled] svg,
._outlined_15hc4_41 a > span[class*=label] > span > svg {
  fill: currentColor;
  color: var(--color40) !important;
}
._outlined_15hc4_41 li[class*=submenu] span[class*=popover-wrapper] > span[class*=popover-open] > a:not(:hover) {
  background-color: var(--color5);
}
._raised_15hc4_81,
._raised_15hc4_81 div[class*=popover-content] > ul[class*=menu] {
  box-shadow: var(--shadow8dp);
  color: var(--color100);
  background: var(--white);
}
._raised_15hc4_81 div[class*=popover] > div[class*=popover-content] {
  box-shadow: none;
}
._raised_15hc4_81 svg {
  fill: currentColor;
  color: var(--color70);
}
._raised_15hc4_81 li:not([class*=menu-divider]) a {
  background-color: unset;
}
._raised_15hc4_81 li[class*=menu-divider] {
  border-top-color: var(--color10);
}
._raised_15hc4_81 li:not([class*=menu-divider]) a:hover:not([class*=disabled]) {
  background-color: var(--color5);
}
._raised_15hc4_81 a[class*=disabled] > div,
._raised_15hc4_81 a > span[class*=label],
._raised_15hc4_81 a[class*=disabled] > span[class*=item-label],
._raised_15hc4_81 a[class*=disabled] svg,
._raised_15hc4_81 a > span[class*=label] > span > svg {
  fill: currentColor;
  color: var(--color40) !important;
}
._raised_15hc4_81 li[class*=submenu] span[class*=popover-wrapper] > span[class*=popover-open] > a:not(:hover) {
  background-color: var(--color5);
}
._filled_15hc4_124,
._filled_15hc4_124 div[class*=popover-content] > ul[class*=menu] {
  box-shadow: var(--shadow8dp);
  color: var(--color0);
  background: var(--color70);
}
._filled_15hc4_124 div[class*=popover] > div[class*=popover-content] {
  box-shadow: none;
}
._filled_15hc4_124 svg {
  fill: currentColor;
  color: var(--color40);
}
._filled_15hc4_124 li:not([class*=menu-divider]) a {
  background-color: transparent;
}
._filled_15hc4_124 li[class*=menu-divider] {
  border-top-color: var(--color80);
}
._filled_15hc4_124 li:not([class*=menu-divider]) a:hover:not([class*=disabled]) {
  background-color: var(--color90);
}
._filled_15hc4_124 a[class*=disabled] > div,
._filled_15hc4_124 a > span[class*=label],
._filled_15hc4_124 a[class*=disabled] > span[class*=item-label],
._filled_15hc4_124 a[class*=disabled] svg,
._filled_15hc4_124 a > span[class*=label] > span > svg {
  fill: currentColor;
  color: var(--color40) !important;
}
._filled_15hc4_124 li[class*=submenu] span[class*=popover-wrapper] > span[class*=popover-open] > a:not(:hover) {
  background-color: var(--color90);
}
._default_15hc4_165,
._default_15hc4_165 div[class*=popover-content] > ul[class*=menu] {
  color: var(--color100);
  background: var(--white);
}
._default_15hc4_165 div[class*=popover] > div[class*=popover-content] {
  box-shadow: none;
}
._default_15hc4_165 svg {
  fill: currentColor;
  color: var(--color70);
}
._default_15hc4_165 li:not([class*=menu-divider]) a {
  background-color: unset;
}
._default_15hc4_165 li:not([class*=menu-divider]) a:hover:not([class*=disabled]) {
  background-color: var(--color5);
}
._default_15hc4_165 a[class*=disabled] > div,
._default_15hc4_165 a > span[class*=label],
._default_15hc4_165 a[class*=disabled] > span[class*=item-label],
._default_15hc4_165 a[class*=disabled] svg,
._default_15hc4_165 a > span[class*=label] > span > svg {
  fill: currentColor;
  color: var(--color40) !important;
}
._outlined_15hc4_41 li[class*=submenu] span[class*=popover-wrapper] > span[class*=popover-open] > a:not(:hover) {
  background-color: var(--color5);
}

/* temp_stylePlugin:ni:sha-256;3rsRar0ykWZkAGbbox04yJOfFyoi0WO4yp-mfxUo0cE */
._def_p3dpo_2,
._dense_p3dpo_3 {
  margin: 0.5rem;
  padding: 0;
}
._def_p3dpo_2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
._dense_p3dpo_3 {
  margin-top: 4px;
  margin-bottom: 4px;
}
._def_p3dpo_2 > h6 {
  line-height: 2.5rem;
  padding: 0;
  margin-left: 0.5rem;
}
._dense_p3dpo_3 > h6 {
  line-height: 1.75rem;
  padding: 0;
  margin-left: 0.5rem;
}

/* temp_stylePlugin:ni:sha-256;iHbcsrLdh9X9dYhPVkVTSrYIEL5sC8r0Sjit3vSnRBk */
._smooth_2xo6u_2 > h6,
._outlined_2xo6u_3 > h6 {
  color: var(--color80);
}
._filled_2xo6u_7 > h6 {
  color: var(--color30);
}
._smooth_2xo6u_2,
._outlined_2xo6u_3 {
  border-top: 0.0625rem solid var(--color10);
}

/* temp_stylePlugin:ni:sha-256;e5g8WeOmqVS3fMdh910OFx2WJo68yhexv-BYo9rIVkA */
._def_ci072_3,
._dense_ci072_4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
._def_ci072_3 > span[class*=icon],
._dense_ci072_4 > span[class*=icon] {
  margin: 0;
}
._def_ci072_3 > *,
._dense_ci072_4 > * {
  margin: 0;
}
._def_ci072_3 > span[class*=icon] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
._dense_ci072_4 {
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  gap: 0.375rem;
}

/* temp_stylePlugin:ni:sha-256;LFoA20JW2Jo6PPIBVLO28-zWR5AAFr4av1TRBfNo1fI */
._smooth_1ktsp_2._note_1ktsp_2,
._outlined_1ktsp_3._note_1ktsp_2,
._raised_1ktsp_4._note_1ktsp_2 {
  color: var(--color80);
}
._filled_1ktsp_8._note_1ktsp_2 {
  color: var(--color10);
}
._has_colored_text_1ktsp_12 {
  color: var(--color70) !important;
}
._outlined_1ktsp_3,
._raised_1ktsp_4,
._filled_1ktsp_8,
._smooth_1ktsp_2 {
  color: var(--color100);
}
._outlined_1ktsp_3.bp4-active,
._raised_1ktsp_4.bp4-active,
._filled_1ktsp_8.bp4-active,
._smooth_1ktsp_2.bp4-active {
  fill: currentColor;
  background-color: var(--color5) !important;
  color: var(--color100) !important;
  font-weight: 500;
}
._outlined_1ktsp_3.bp4-active:hover,
._raised_1ktsp_4.bp4-active:hover,
._filled_1ktsp_8.bp4-active:hover,
._smooth_1ktsp_2.bp4-active:hover {
  background-color: var(--color20) !important;
}
._outlined_1ktsp_3.bp4-disabled,
._raised_1ktsp_4.bp4-disabled,
._filled_1ktsp_8.bp4-disabled,
._smooth_1ktsp_2.bp4-disabled {
  fill: currentColor;
  color: var(--color40) !important;
}

/* temp_stylePlugin:ni:sha-256;QlvYdY_KZHUg6d2B-NlL9mAUYFIY939HOF6gdnFNWvM */
._steps_1trk9_1 {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
  margin-top: -8px;
  min-height: 40px;
}
._step_1trk9_1 {
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: var(--blue30);
}
._step_1trk9_1 ._step_num_1trk9_18 {
  color: white;
  background-color: var(--blue30);
  width: 24px;
  height: 24px;
  flex: none;
  border-radius: 100%;
  text-align: center;
}
._step_1trk9_1._active_1trk9_27 {
  color: var(--blue100);
}
._step_1trk9_1._active_1trk9_27 ._step_num_1trk9_18 {
  background-color: var(--blue70);
}

/* temp_stylePlugin:ni:sha-256;6-SsXTbtkiVehzOFvUPKGjdc9l_jSwKPA5NkQX1k4Ls */
._reverseFlex_4an30_1 {
  display: flex;
  flex-direction: column-reverse;
}
._reverseFlex_4an30_1._fill_4an30_5 {
  width: auto;
}
._def_4an30_10:not(._fill_4an30_5),
._dense_4an30_11:not(._fill_4an30_5) {
  width: fit-content;
}
._def_4an30_10:placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}
._dense_4an30_11:placeholder {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}
._fill_4an30_5 {
  width: 100% !important;
}
._def_4an30_10[class*=numeric-input] input[class*=input] {
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
._def_4an30_10[class*=numeric-input] input[class*=input] {
  height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}
._def_4an30_10 label {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
._dense_4an30_11[class*=numeric-input] input[class*=input] {
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.375rem;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}
._dense_4an30_11[class*=numeric-input] input[class*=input] {
  height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
}
._dense_4an30_11 label {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
@keyframes _shake-horizontal_4an30_1 {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

/* temp_stylePlugin:ni:sha-256;xXeEl9ofYoT0fJcr1xZQKiW0xNYhLDNGHrV1hK1T_2Y */
._outlined_1qwyi_1[class*=numeric-input] input[class*=input] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1qwyi_1[class*=numeric-input] input[class*=input]:hover {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1qwyi_1[class*=numeric-input]:active input[class*=input] {
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1qwyi_1[class*=numeric-input]._active_1qwyi_19 input[class*=input] {
  box-shadow: 0 0 0 2px var(--color80);
}
._outlined_1qwyi_1._active_1qwyi_19[class*=numeric-input] input[class*=input] {
  box-shadow: 0 0 0 1px var(--color70);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1qwyi_1[class*=numeric-input] input[class*=input]:active {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_1qwyi_1[class*=numeric-input] input[class*=input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._outlined_1qwyi_1[class*=numeric-input] input[class*=input]:hover::placeholder {
  color: var(--color70);
}
._outlined_1qwyi_1[class*=numeric-input] ~ label {
  opacity: 0;
  color: var(--color50);
}
._outlined_1qwyi_1[class*=numeric-input]._active_1qwyi_19 ~ label {
  color: var(--color50);
  opacity: 1;
}
._outlined_1qwyi_1[class*=numeric-input] > span svg {
  color: var(--color40);
  margin-left: 7px;
}
._outlined_1qwyi_1[class*=numeric-input]._active_1qwyi_19 > span svg {
  color: var(--color60);
}
._outlined_1qwyi_1 [class*=button],
._outlined_1qwyi_1 [class*=button]:visited {
  margin-left: 1px;
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._outlined_1qwyi_1 [class*=button] svg {
  color: var(--color70);
}
._outlined_1qwyi_1 [class*=button]:hover {
  color: var(--color80);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: none;
}
._outlined_1qwyi_1 [class*=button]:active,
._outlined_1qwyi_1 [class*=button]:active,
._outlined_1qwyi_1 [class*=button][class*=active],
._outlined_1qwyi_1 [class*=button]._focused_1qwyi_89 {
  background: var(--color20);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._outlined_1qwyi_1 [class*=button]:hover svg,
._outlined_1qwyi_1 [class*=button]:active svg {
  color: var(--color80);
}
._outlined_1qwyi_1 [class*=disabled]:disabled,
._outlined_1qwyi_1 [class*=disabled]:disabled:hover {
  color: var(--color30);
  background-color: var(--color5);
  opacity: 1;
}
._outlined_1qwyi_1 [class*=button]:disabled svg,
._outlined_1qwyi_1 [class*=button]:disabled:hover svg {
  color: var(--color30);
}

/* temp_stylePlugin:ni:sha-256;bj86Gm01-EeoP86unlv67WWP64vnVKJv46kdpoR2dN4 */
._container_q78r1_3 {
  display: flex;
  align-items: center;
  box-shadow: none;
  border-radius: 99px;
}
._def_q78r1_11,
._dense_q78r1_12 {
  width: fit-content;
  padding: 0;
  display: flex;
}
._inline_q78r1_18._def_q78r1_11,
._inline_q78r1_18._dense_q78r1_12 {
  display: inline-flex;
}
._def_q78r1_11._fill_q78r1_23,
._dense_q78r1_12._fill_q78r1_23 {
  width: 100%;
}
._def_q78r1_11 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
  align-items: center;
  height: 40px;
}
._def_q78r1_11 input {
  background-color: red;
  margin-top: 20px;
}
._def_q78r1_11[class*=container] > label[class*=control],
._dense_q78r1_12[class*=container] > label[class*=control] {
  padding: 0;
  margin: 0;
  display: flex;
}
._def_q78r1_11[class*=container] > label[class*=control] > span[class*=indicator] {
  margin: auto 0.625rem auto 0.625rem;
}
._def_q78r1_11 input:checked ~ span[class*=indicator]::before,
._def_q78r1_11 input:not(:checked) ~ span[class*=indicator]::before {
  height: 0.5rem;
  width: 0.5rem;
  margin: auto;
  margin-top: 0.25rem;
  border-radius: 99px;
}
._def_q78r1_11 input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_bold);
  font-weight: var(--font_weight_body_medium);
}
._dense_q78r1_12 {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  align-items: center;
  height: 28px;
}
._dense_q78r1_12[class*=container] > label[class*=control] > span[class*=indicator] {
  margin: auto 0.375rem auto 0.375rem;
}
._dense_q78r1_12 input:checked ~ span[class*=indicator]::before,
._dense_q78r1_12 input:not(:checked) ~ span[class*=indicator]::before {
  height: 0.375rem;
  width: 0.375rem;
  margin: auto;
  margin-top: 0.1875rem;
  border-radius: 99px;
}
._dense_q78r1_12 input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
}
._def_q78r1_11._with_helper_q78r1_100 {
  height: 2.75rem;
  line-height: 2.75rem;
}
._dense_q78r1_12._with_helper_q78r1_100 {
  height: 2.5rem;
  line-height: 2.5rem;
}
._helperBox_q78r1_111 {
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._text_q78r1_117 {
  margin-right: 1rem;
}
._helperBox_q78r1_111 > ._helperText_q78r1_121 {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}
._leftPosition_q78r1_129[class*=container] > label[class*=control] {
  flex-direction: row-reverse;
  margin-left: 1rem;
  margin-right: 0;
}
._def_q78r1_11._leftPosition_q78r1_129[class*=container] > label[class*=control] > span[class*=indicator] {
  margin: auto 0.625rem auto 1.125rem;
}
._dense_q78r1_12._leftPosition_q78r1_129[class*=container] > label[class*=control] > span[class*=indicator] {
  margin: auto 0.375rem auto 0.75rem;
}
._rtl_q78r1_145 > div {
  text-align: right;
}

/* temp_stylePlugin:ni:sha-256;6k3znPTBKnXRrAQ3XP-mFPJ5rOg9JYvuxmRtXa3y6Bk */
._flat_dfud_3 {
  color: var(--color100);
}
._flat_dfud_3 > label[class*=control] span[class*=indicator] {
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._flat_dfud_3:hover:not(._disabled_dfud_14) > label[class*=control] > span[class*=indicator] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.625rem var(--color5);
  transition: box-shadow ease 150ms;
}
._flat_dfud_3[class*=dense]:hover > label[class*=control] > span[class*=indicator] {
  box-shadow: 0 0 0 0.375rem var(--color5);
  background-color: var(--color5);
}
._flat_dfud_3._checked_dfud_25._has_label_dfud_25[class*=dense] > label[class*=control] > span[class*=indicator] {
  box-shadow: 0 0 0 0.375rem var(--color5);
  background-color: var(--color5);
}
._flat_dfud_3._checked_dfud_25 {
  background-color: transparent;
  transition: background ease 250ms;
}
._flat_dfud_3._checked_dfud_25._has_label_dfud_25 {
  background-color: var(--color5);
}
._flat_dfud_3[class*=dense]._checked_dfud_25 {
  transition: background ease 250ms;
}
._flat_dfud_3._checked_dfud_25 input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._flat_dfud_3:hover input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
._flat_dfud_3 input:not(:checked) ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
._flat_dfud_3._disabled_dfud_14 > label[class*=control] > div {
  color: var(--color40);
}
._flat_dfud_3._checked_dfud_25._disabled_dfud_14 > label[class*=control] > span[class*=indicator] {
  background-color: var(--color5);
}
._flat_dfud_3._disabled_dfud_14:hover > label[class*=control] > span[class*=indicator] {
  background-color: initial;
  box-shadow: none;
}
._flat_dfud_3[class*=dense]._checked_dfud_25._disabled_dfud_14 > label[class*=control] > span[class*=indicator] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.375rem var(--color5);
}
._flat_dfud_3 ._helperText_dfud_80,
._flat_dfud_3._disabled_dfud_14 ._helperText_dfud_80 {
  color: var(--color60);
}
._smooth_dfud_87 {
  color: var(--color100);
  background: var(--color0);
}
._smooth_dfud_87:hover {
  background: var(--color5);
  transition: background ease 250ms;
}
._smooth_dfud_87._checked_dfud_25 {
  background: var(--color10);
  transition: background ease 250ms;
}
._smooth_dfud_87 > label[class*=control] span[class*=indicator] {
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._smooth_dfud_87:hover:not(._disabled_dfud_14) > label[class*=control] > span[class*=indicator] {
  background-color: var(--color5);
  box-shadow: none;
  transition: all ease 150ms;
}
._smooth_dfud_87._checked_dfud_25 > label[class*=control] > span[class*=indicator],
._smooth_dfud_87._checked_dfud_25:hover > label[class*=control] > span[class*=indicator] {
  background-color: var(--color10);
  transition: all ease 150ms;
}
._smooth_dfud_87._checked_dfud_25 input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._smooth_dfud_87:hover input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
._smooth_dfud_87 input:not(:checked) ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
._smooth_dfud_87._disabled_dfud_14 > label[class*=control] > div {
  color: var(--color40);
}
._smooth_dfud_87._checked_dfud_25._disabled_dfud_14,
._smooth_dfud_87._disabled_dfud_14:hover,
._smooth_dfud_87._checked_dfud_25._disabled_dfud_14 > label[class*=control] > span[class*=indicator] {
  background-color: var(--color0);
}
._smooth_dfud_87._disabled_dfud_14._checked_dfud_25 > label[class*=control] > span[class*=indicator] {
  background-color: initial;
  box-shadow: none;
}
._smooth_dfud_87 ._helperText_dfud_80,
._smooth_dfud_87._disabled_dfud_14 ._helperText_dfud_80 {
  color: var(--color60);
}
._raised_dfud_162 {
  color: var(--color100);
  background: var(--color0);
  box-shadow: var(--shadow2dp);
}
._raised_dfud_162:hover {
  background: var(--white);
  box-shadow: var(--shadow4dp);
  transition: background ease 250ms;
}
._raised_dfud_162._checked_dfud_25 {
  background: var(--color5);
  box-shadow: none;
  transition: background ease 250ms;
}
._raised_dfud_162 > label[class*=control] span[class*=indicator] {
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._raised_dfud_162:hover:not(._disabled_dfud_14) > label[class*=control] > span[class*=indicator] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
._raised_dfud_162._checked_dfud_25 > label[class*=control] > span[class*=indicator],
._raised_dfud_162._checked_dfud_25:hover > label[class*=control] > span[class*=indicator] {
  background-color: var(--color5);
  transition: all ease 150ms;
}
._raised_dfud_162._checked_dfud_25 input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._raised_dfud_162:hover input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
._raised_dfud_162 input:not(:checked) ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
._raised_dfud_162._disabled_dfud_14 > label[class*=control] > div {
  color: var(--color40);
}
._raised_dfud_162._checked_dfud_25._disabled_dfud_14,
._raised_dfud_162._disabled_dfud_14:hover,
._raised_dfud_162._checked_dfud_25._disabled_dfud_14 > label[class*=control] > span[class*=indicator] {
  background-color: var(--color0);
  box-shadow: var(--shadow2dp);
}
._raised_dfud_162._disabled_dfud_14._checked_dfud_25 > label[class*=control] > span[class*=indicator] {
  background-color: initial;
  box-shadow: none;
}
._raised_dfud_162 ._helperText_dfud_80,
._raised_dfud_162._disabled_dfud_14 ._helperText_dfud_80 {
  color: var(--color60);
}
._outlined_dfud_241 {
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 0.0625rem var(--color20);
}
._outlined_dfud_241:hover {
  box-shadow: 0 0 0 0.0625rem var(--color40);
  transition: all ease 250ms;
}
._outlined_dfud_241._checked_dfud_25 {
  box-shadow: 0 0 0 0.125rem var(--color40);
  background: var(--white);
  transition: all ease 250ms;
}
._outlined_dfud_241 > label[class*=control] span[class*=indicator] {
  background: var(--white);
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._outlined_dfud_241:hover:not(._disabled_dfud_14) > label[class*=control] > span[class*=indicator] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
._outlined_dfud_241._checked_dfud_25 > label[class*=control] > span[class*=indicator] {
  background-color: var(--white);
  background: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
._outlined_dfud_241._checked_dfud_25 input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._outlined_dfud_241:hover input:checked ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
._outlined_dfud_241 input:not(:checked) ~ span[class*=indicator]::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
._outlined_dfud_241._disabled_dfud_14 > label[class*=control] > div {
  color: var(--color40);
}
._outlined_dfud_241._checked_dfud_25._disabled_dfud_14,
._outlined_dfud_241._disabled_dfud_14:hover,
._outlined_dfud_241._checked_dfud_25._disabled_dfud_14 > label[class*=control] > span[class*=indicator] {
  box-shadow: 0 0 0 0.0625rem var(--color10);
}
._outlined_dfud_241._disabled_dfud_14._checked_dfud_25 > label[class*=control] > span[class*=indicator] {
  background-color: initial;
  box-shadow: none;
}
._outlined_dfud_241 ._helperText_dfud_80,
._outlined_dfud_241._disabled_dfud_14 ._helperText_dfud_80 {
  color: var(--color60);
}
._minimal_dfud_318._checked_dfud_25 {
  background-color: transparent;
}

/* temp_stylePlugin:ni:sha-256;KtoTvLzrN5AVevZbhTDMmrHd99T2qkRwtmkN8M3fv7A */
._searchInput_18u5s_1 .bp4-input-left-container {
  top: 10px;
  left: 4px !important;
}
._searchInput_18u5s_1 input {
  padding-left: 35px !important;
}
._searchInput_18u5s_1._isDense_18u5s_8 .bp4-input-left-container {
  top: 2px;
}
._searchInput_18u5s_1._isDense_18u5s_8 input {
  padding-left: 31px !important;
}

/* temp_stylePlugin:ni:sha-256;BW4Yc20mN-bW5GyZWAXrykCPQALsEkPZOvojHLQxE0Q */
._contentScroll_1lkww_1 {
  padding-bottom: 8px;
  max-height: 240px;
  overflow-y: auto;
}
._contentScroll_1lkww_1._no_search_1lkww_6 {
  padding-top: 8px;
}
._menu_1lkww_10 {
  margin-top: 8px;
  box-shadow: var(--shadow8dp);
  max-height: none;
}
._menu_1lkww_10 ._search_1lkww_15 {
  padding: 8px;
}
._no_results_1lkww_19 {
  margin-left: 8px;
}

/* temp_stylePlugin:ni:sha-256;QL2StYhvdyN3tBI9cxqGb2bTDrbGNK6mOEyj8eOfFC0 */
._popover_10tsh_1 {
  border: none;
  box-shadow: none;
  padding: 0;
}
._popover_10tsh_1 .bp4-popover-content {
  padding: 0;
}
._default_10tsh_10._input_tag_10tsh_10 div[class*=bp4-tag-input] {
  height: 40px;
  font-size: 16px;
}
._default_10tsh_10._input_tag_10tsh_10 div[class*=bp4-tag-input] > div[class*=tag-input-values] {
  margin-top: 2.5px;
}
._input_tag_10tsh_10 > * > * > div[class*=bp4-tag-input] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_10tsh_10 > * > * > div[class*=bp4-tag-input]:hover {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_10tsh_10 > * > * > div[class*=bp4-tag-input]:active {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_10tsh_10 > * > * > div[class*=bp4-active] {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_10tsh_10 input::placeholder {
  color: var(--color30);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._input_tag_10tsh_10 input:hover::placeholder {
  color: var(--color70);
}
._input_tag_10tsh_10._disabled_10tsh_48 input {
  cursor: not-allowed;
  color: var(--color30);
}
._input_tag_10tsh_10._disabled_10tsh_48 input:hover::placeholder {
  color: var(--color30);
}
._input_tag_10tsh_10 span[class*=bp4-tag] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  min-width: 0px;
  text-transform: none;
  justify-content: left;
  width: fit-content;
  border-radius: 99px;
  color: var(--color70);
  background-color: var(--color5);
  transition: all 180ms ease-in;
}
._input_tag_10tsh_10 span[class*=bp4-tag][class*=bp4-minimal] {
  height: 1.25rem;
  min-height: 1.25rem;
  padding: 0.125rem 0.375rem;
}
._input_tag_10tsh_10 span[class*=bp4-tag] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.25rem;
}
._input_tag_10tsh_10 span[class*=bp4-tag] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.25rem;
}
._input_tag_10tsh_10 span[class*=bp4-tag] span[icon]:first-of-type > svg {
  height: 1rem;
  width: 1rem;
}
._input_tag_10tsh_10 span[class*=bp4-tag] span[class*=icon-tick]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  transform: scale(0);
  transition: all 200ms ease-in;
}
._input_tag_10tsh_10 span[class*=bp4-tag] ._dense_10tsh_95._active_10tsh_95[class*=tag] span[class*=icon-tick]:first-of-type {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  transform: scaleX(1);
  transition: all 200ms ease-in;
}
._input_tag_10tsh_10 span[class*=bp4-tag] button[class*=tag-remove] > span[class*=small-cross] {
  margin-right: 0;
  margin-left: 0.125rem;
}
._input_tag_10tsh_10 span[class*=bp4-tag] button[class*=tag-remove] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._input_tag_10tsh_10 span[class*=bp4-tag] span[class*=text] {
  margin-right: 0;
}
._input_tag_10tsh_10 span[class*=bp4-tag]:visited {
  color: var(--color70);
  background-color: var(--color5);
  transition: all 180ms ease-in;
}
._input_tag_10tsh_10 span[class*=bp4-tag] svg {
  color: var(--color40);
}
._input_tag_10tsh_10 span[class*=bp4-tag] button[class*=tag-remove] {
  opacity: 1;
}
._input_tag_10tsh_10 span[class*=bp4-tag] button[class*=tag-remove] > span[class*=small-cross] > svg {
  color: var(--color50);
}
._input_tag_10tsh_10 span[class*=bp4-tag] button[class*=tag-remove]:hover > span[class*=small-cross] > svg {
  color: var(--color70);
  opacity: 1;
  transition: all 180ms ease-in;
}
._divider_10tsh_134 {
  padding: 0 8px;
}
._divider_10tsh_134 ._isDense_10tsh_137 {
  padding: 0 12px;
}

/* temp_stylePlugin:ni:sha-256;XNN2X6hjbLpfCWN2r-Ys7JJY3mtjdkcsGO_a2nPjQ1o */
._select_button_sw7hd_1 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}
._select_button_sw7hd_1 span {
  white-space: nowrap;
}
._select_button_sw7hd_1._outlined_sw7hd_13 span {
  color: var(--grey100);
}
._select_button_sw7hd_1._outlined_sw7hd_13._disabled_sw7hd_16 span {
  color: var(--grey30);
}
._select_button_sw7hd_1._filled_sw7hd_19 {
  background-color: var(--color40);
}
._select_button_sw7hd_1._isFill_sw7hd_22 {
  width: 100%;
  justify-content: space-between;
}
._popover_sw7hd_27 {
  border: none;
  box-shadow: none;
}
._popover_sw7hd_27:not(._filterable_sw7hd_31) li:first-child {
  margin-top: 8px;
}
._popover_sw7hd_27 .bp4-popover-content {
  padding: 0;
}
._popover_sw7hd_27 ._divider_sw7hd_37 {
  padding: 0 8px;
}
._popover_sw7hd_27 ._divider_sw7hd_37 ._isDense_sw7hd_40 {
  padding: 0 12px;
}
._menu_item_sw7hd_44 {
  margin-bottom: 2px;
}

/* temp_stylePlugin:ni:sha-256;8wrhej3dwPLUe_HelUQpB5x4MmvrhB9ezds9MDFKhHw */
._def_14pzv_2 {
  min-width: 0;
}
._def_14pzv_2 div[class*=slider-track] {
  height: 0.25rem;
  top: 0.625rem;
  border-radius: 99px;
}
._def_14pzv_2 span[class*=slider-handle] {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 99px;
}
._def_14pzv_2 span[class*=slider-handle] > span[class*=slider-label] {
  margin-left: 0.75rem;
  border-radius: 0.25rem;
  top: 0.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  min-width: 40px;
  height: 20px;
  padding: 4px 6px;
  text-align: center;
  line-height: 100%;
}
._def_14pzv_2 div[class*=slider-axis] > div[class*=slider-label] {
  border-radius: 0.25rem;
  top: 0.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body);
  letter-spacing: var(--letter_spacing_body_small);
  min-width: 40px;
  height: 20px;
  padding: 4px 6px;
  text-align: center;
  line-height: 100%;
}
._def_14pzv_2[class*=vertical] div[class*=slider-axis] > div[class*=slider-label] {
  top: initial;
  left: 0.25rem;
}
._def_14pzv_2[class*=vertical] span[class*=slider-handle] > span[class*=slider-label] {
  top: initial;
  left: 0.5rem;
}
._def_14pzv_2[class*=vertical] span[class*=slider-handle] {
  left: -0.25rem;
}

/* temp_stylePlugin:ni:sha-256;Uf19MMoMGy4H350_WR0IyDv0eDB6b_Afm0H6gri0bYs */
._filled_1m93u_2 div[class*=slider-progress]:first-of-type,
._filled_1m93u_2 div[class*=slider-progress]:last-of-type {
  background: var(--color20);
}
._filled_1m93u_2 div[class*=slider-progress]:nth-of-type(2) {
  background-color: var(--color70);
}
._filled_1m93u_2 div[class*=slider-axis] > div[class*=slider-label] {
  color: var(--color40);
}
._filled_1m93u_2 span[class*=slider-handle] {
  background: var(--color0);
  box-shadow: var(--shadow2dp);
  transition: box-shadow 200ms ease-in-out;
}
._filled_1m93u_2:hover span[class*=slider-handle] {
  background: var(--white);
  background-color: var(--white);
  box-shadow: var(--shadow4dp);
  transition: box-shadow 200ms ease-in-out;
}
._filled_1m93u_2 span[class*=slider-handle][class*=active] {
  background: var(--color50);
  background-color: var(--color50);
  box-shadow: none;
  transition: box-shadow 200ms ease-in-out;
}
._filled_1m93u_2 span[class*=slider-handle] > span[class*=slider-label] {
  background: var(--color5);
  color: var(--color100);
  box-shadow: none;
}

/* temp_stylePlugin:ni:sha-256;3uUibDRwo1iZffw-32J-6RQBhHg8cCCgpB8zDRkv154 */
._container_4day5_3 {
  align-items: center;
  box-shadow: none;
  border-radius: 99px;
}
._container_4day5_3 > label > div {
  width: -webkit-fill-available;
}
._def_4day5_14,
._dense_4day5_15 {
  width: fit-content;
  padding: 8;
  display: flex;
}
._inline_4day5_21._def_4day5_14,
._inline_4day5_21._dense_4day5_15 {
  display: inline-flex;
}
._fill_4day5_26,
._fill_4day5_26 > label[class*=control] {
  width: 100%;
}
._def_4day5_14 {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._def_4day5_14[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38),
._dense_4day5_15[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38) {
  padding: 0;
  margin: 0;
  display: flex;
}
._def_4day5_14[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38)._has_label_4day5_44,
._dense_4day5_15[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38)._has_label_4day5_44 {
  margin-right: 1rem;
}
._def_4day5_14[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38) > span[class*=indicator] {
  margin: 10px 1.125rem 10px 0.625rem;
  height: 14px;
  top: 2px;
  min-width: 32px;
}
._def_4day5_14 input:checked ~ span[class*=indicator]::before,
._def_4day5_14 input:not(:checked) ~ span[class*=indicator]::before {
  height: 20px;
  width: 20px;
  margin: 0px;
  margin-top: -3px;
  border-radius: 99px;
}
._def_4day5_14._i-style_4day5_66[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38) > span[class*=indicator] {
  height: 1.5rem;
  min-width: 2.5rem;
}
._def_4day5_14._i-style_4day5_66 input:checked ~ span[class*=indicator]::before,
._def_4day5_14._i-style_4day5_66 input:not(:checked) ~ span[class*=indicator]::before {
  height: 1.5rem;
  width: 1.5rem;
  margin: auto;
}
._def_4day5_14 input ~ span[class*=indicator]::before {
  left: 0 !important;
}
._def_4day5_14 input:checked ~ span[class*=indicator]::before {
  left: calc(100% - 20px) !important;
}
._def_4day5_14 input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_bold);
  font-weight: var(--font_weight_body_medium);
}
._dense_4day5_15 {
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}
._dense_4day5_15[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38) > span[class*=indicator] {
  margin: auto 1.125rem auto 0.625rem;
  height: 12px;
  min-width: 30px;
}
._dense_4day5_15 input:checked ~ span[class*=indicator]::before,
._dense_4day5_15 input:not(:checked) ~ span[class*=indicator]::before {
  height: 16px;
  width: 16px;
  margin: 0px;
  margin-top: -2px;
  border-radius: 99px;
}
._dense_4day5_15._i-style_4day5_66[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38) > span[class*=indicator] {
  height: 1.125rem;
  min-width: 2.125rem;
}
._dense_4day5_15._i-style_4day5_66 input:checked ~ span[class*=indicator]::before,
._dense_4day5_15._i-style_4day5_66 input:not(:checked) ~ span[class*=indicator]::before {
  height: 1.125rem;
  width: 1.125rem;
  margin: auto;
}
._dense_4day5_15._i-style_4day5_66 input:checked ~ span[class*=indicator]::before {
  left: calc(100% - 16px);
}
._dense_4day5_15 input:checked ~ span[class*=indicator]::before {
  left: calc(100% - 16px) !important;
}
._dense_4day5_15 input:checked ~ div::before {
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
}
._def_4day5_14._with_helper_4day5_143 {
  height: 2.75rem;
  line-height: 2.75rem;
}
._dense_4day5_15._with_helper_4day5_143 {
  height: 2.5rem;
  line-height: 2.5rem;
}
._helperBox_4day5_154 {
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._helperBox_4day5_154 > ._helperText_4day5_160 {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}
._helperBox_4day5_154 > ._helperText--dense_4day5_166 {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: normal;
}
._leftPosition_4day5_174[class*=container] > label[class*=control]:not(._bp4-align-right_4day5_38) {
  flex-direction: row-reverse;
  margin-left: 1rem;
  margin-right: 0;
}
._def_4day5_14._leftPosition_4day5_174[class*=container] > label[class*=control] > span[class*=indicator] {
  margin: auto 0.625rem auto 1.125rem;
}
._dense_4day5_15._leftPosition_4day5_174[class*=container] > label[class*=control] > span[class*=indicator] {
  margin: auto 0.375rem auto 0.75rem;
}
._rtl_4day5_190 > div {
  text-align: right;
}

/* temp_stylePlugin:ni:sha-256;Q7IIryqrtGn7S4LzAx-2YeqB8JydMdMX0NmzfeCQ-O8 */
._flat_1amjl_3 > label {
  color: var(--color100);
}
._flat_1amjl_3._checked_1amjl_7 > label {
  color: var(--color100);
}
._flat_1amjl_3 > label[class*=control] > span[class*=indicator],
._flat_1amjl_3 > label[class*=control] > input:not(:disabled):active ~ span[class*=indicator],
._flat_1amjl_3 > label[class*=control]:hover > span[class*=indicator] {
  background: var(--color10);
  transition: all ease 250ms;
}
._flat_1amjl_3._checked_1amjl_7 {
  transition: all ease 250ms;
}
._flat_1amjl_3._checked_1amjl_7._has_label_1amjl_22 {
  background: var(--color5);
}
._flat_1amjl_3._checked_1amjl_7 > label[class*=control] > span[class*=indicator],
._flat_1amjl_3._checked_1amjl_7 > label[class*=control]:hover > span[class*=indicator] {
  background: var(--color30);
  transition: all ease 250ms;
}
._flat_1amjl_3._checked_1amjl_7 > label[class*=control] > input:checked:not(:disabled):active ~ span[class*=indicator],
._flat_1amjl_3._checked_1amjl_7 > label[class*=control] > input:checked:not(:disabled) ~ span[class*=indicator] {
  background: var(--color30);
  transition: all ease 250ms;
}
._flat_1amjl_3 > label[class*=control] > input:not(:checked) ~ span::before {
  background: var(--color30);
  box-shadow: none;
  transition: all ease 250ms;
}
._flat_1amjl_3 > label[class*=control]:hover > input:not(:checked) ~ span::before {
  background: var(--color50);
  box-shadow: none;
  transition: all ease 250ms;
}
._flat_1amjl_3._checked_1amjl_7 > label[class*=control] > input:checked ~ span::before {
  background: var(--color70);
  box-shadow: none;
  transition: all ease 250ms;
}
._flat_1amjl_3._checked_1amjl_7 > label[class*=control]:hover > input:checked ~ span::before {
  background: var(--color80);
}
._flat_1amjl_3._disabled_1amjl_60 {
  background: none;
}
._smooth_1amjl_66 {
  background: var(--color0);
}
._smooth_1amjl_66:hover {
  background: var(--color5);
  transition: all ease 250ms;
}
._smooth_1amjl_66 > label {
  color: var(--color100);
}
._smooth_1amjl_66._checked_1amjl_7 > label {
  color: var(--color100);
}
._smooth_1amjl_66 > label[class*=control] > span[class*=indicator],
._smooth_1amjl_66 > label[class*=control] > input:not(:disabled):active ~ span[class*=indicator],
._smooth_1amjl_66 > label[class*=control]:hover > span[class*=indicator] {
  background: var(--color10);
  transition: all ease 250ms;
}
._smooth_1amjl_66._checked_1amjl_7 {
  background: var(--color10);
  transition: all ease 250ms;
}
._smooth_1amjl_66._checked_1amjl_7 > label[class*=control] > span[class*=indicator],
._smooth_1amjl_66._checked_1amjl_7 > label[class*=control]:hover > span[class*=indicator] {
  background: var(--color30);
  transition: all ease 250ms;
}
._smooth_1amjl_66._checked_1amjl_7 > label[class*=control] > input:checked:not(:disabled):active ~ span[class*=indicator],
._smooth_1amjl_66._checked_1amjl_7 > label[class*=control] > input:checked:not(:disabled) ~ span[class*=indicator] {
  background: var(--color30);
  transition: all ease 250ms;
}
._smooth_1amjl_66 > label[class*=control] > input:not(:checked) ~ span::before {
  background: var(--color30);
  box-shadow: none;
  transition: all ease 250ms;
}
._smooth_1amjl_66 > label[class*=control]:hover > input:not(:checked) ~ span::before {
  background: var(--color50);
  box-shadow: none;
  transition: all ease 250ms;
}
._smooth_1amjl_66._checked_1amjl_7 > label[class*=control] > input:checked ~ span::before {
  background: var(--color70);
  box-shadow: none;
  transition: all ease 250ms;
}
._smooth_1amjl_66._checked_1amjl_7 > label[class*=control]:hover > input:checked ~ span::before {
  background: var(--color80);
}
._smooth_1amjl_66._disabled_1amjl_60 {
  background: var(--color0);
}
._outlined_1amjl_135 {
  box-shadow: 0 0 0 1px var(--color20);
}
._outlined_1amjl_135:hover {
  box-shadow: 0 0 0 1px var(--color40);
  transition: all ease 250ms;
}
._outlined_1amjl_135 > label {
  color: var(--color100);
}
._outlined_1amjl_135._checked_1amjl_7 > label {
  color: var(--color100);
}
._outlined_1amjl_135 > label[class*=control] > span[class*=indicator],
._outlined_1amjl_135 > label[class*=control] > input:not(:disabled):active ~ span[class*=indicator],
._outlined_1amjl_135 > label[class*=control]:hover > span[class*=indicator] {
  background: var(--color10);
  transition: all ease 250ms;
}
._outlined_1amjl_135._checked_1amjl_7 {
  box-shadow: 0 0 0 2px var(--color40);
  transition: all ease 250ms;
}
._outlined_1amjl_135._checked_1amjl_7 > label[class*=control] > span[class*=indicator],
._outlined_1amjl_135._checked_1amjl_7 > label[class*=control]:hover > span[class*=indicator] {
  background: var(--color30);
  transition: all ease 250ms;
}
._outlined_1amjl_135._checked_1amjl_7 > label[class*=control] > input:checked:not(:disabled):active ~ span[class*=indicator],
._outlined_1amjl_135._checked_1amjl_7 > label[class*=control] > input:checked:not(:disabled) ~ span[class*=indicator] {
  background: var(--color30);
  transition: all ease 250ms;
}
._outlined_1amjl_135 > label[class*=control] > input:not(:checked) ~ span::before {
  background: var(--color0);
  box-shadow: none;
  border: 0.125rem solid var(--color30);
  transition: all ease 250ms;
}
._outlined_1amjl_135 > label[class*=control]:hover > input:not(:checked) ~ span::before {
  background: var(--color0);
  box-shadow: none;
  border: 0.125rem solid var(--color50);
  transition: all ease 250ms;
}
._outlined_1amjl_135._checked_1amjl_7 > label[class*=control] > input:checked ~ span::before {
  background: var(--color0);
  box-shadow: none;
  border: 0.25rem solid var(--color70);
  transition: all ease 250ms;
}
._outlined_1amjl_135._checked_1amjl_7 > label[class*=control]:hover > input:checked ~ span::before {
  border: 0.25rem solid var(--color80);
}
._outlined_1amjl_135._disabled_1amjl_60 {
  box-shadow: 0 0 0 1px var(--color10);
}
div[class*=container] > label[class*=disabled] {
  color: var(--color40);
}
._disabled_1amjl_60 > label[class*=disabled] > input:not(:checked):disabled ~ span[class*=ontrol-indicator],
._disabled_1amjl_60 > label[class*=disabled] > input:checked:disabled ~ span[class*=ontrol-indicator] {
  background: var(--color5);
}
._disabled_1amjl_60 > label[class*=disabled] > input:not(:checked):disabled ~ span[class*=control-indicator]::before,
._disabled_1amjl_60 > label[class*=disabled] input:checked:disabled ~ span[class*=control-indicator]::before {
  background: var(--color10);
}

/* temp_stylePlugin:ni:sha-256;kO2SirwAsGSYbkfbUjuORv5S38y_zhGYjYPowARgNSc */
._def_9a2aa_2 div[class*=bp4-tab-list] {
  height: 2.5rem;
  align-items: center;
}
._dense_9a2aa_7 div[class*=bp4-tab-list] {
  height: 1.75rem;
  align-items: center;
}
._def_9a2aa_2 div[class*=bp4-tab],
._dense_9a2aa_7 div[class*=bp4-tab] {
  margin-right: 0;
}
._def_9a2aa_2 div[class*=bp4-tab][id*=bp4-tab-title] {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}
._dense_9a2aa_7 div[class*=bp4-tab][id*=bp4-tab-title] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}
._def_9a2aa_2[class*=outlined] div[class*=bp4-tab][id*=bp4-tab-title] {
  line-height: 2.5rem;
}
._dense_9a2aa_7[class*=outlined] div[class*=bp4-tab][id*=bp4-tab-title] {
  line-height: 1.8125rem;
}
._def_9a2aa_2[class*=flat] div[class*=bp4-tab][id*=bp4-tab-title],
._def_9a2aa_2[class*=filled] div[class*=bp4-tab][id*=bp4-tab-title],
._def_9a2aa_2[class*=smooth] div[class*=bp4-tab][id*=bp4-tab-title],
._def_9a2aa_2[class*=raised] div[class*=bp4-tab][id*=bp4-tab-title] {
  line-height: 2.5rem;
}
._dense_9a2aa_7[class*=flat] div[class*=bp4-tab][id*=bp4-tab-title],
._dense_9a2aa_7[class*=filled] div[class*=bp4-tab][id*=bp4-tab-title],
._dense_9a2aa_7[class*=smooth] div[class*=bp4-tab][id*=bp4-tab-title],
._dense_9a2aa_7[class*=raised] div[class*=bp4-tab][id*=bp4-tab-title] {
  line-height: 1.75rem;
}
._def_9a2aa_2 div[class*=bp4-tab][id*=bp4-tab-title] > span[class*=icon],
._dense_9a2aa_7 div[class*=bp4-tab][id*=bp4-tab-title] > span[class*=icon] {
  margin-right: 0.5rem;
}
._def_9a2aa_2 div[class*=bp4-tab][id*=bp4-tab-title] > span[class*=icon] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
._segmented_9a2aa_71 div[class*=bp4-tab-list] {
  height: 1.75rem;
  align-items: center;
}
._segmented_9a2aa_71 div[class*=bp4-tab] {
  margin-right: 0;
}
._segmented_9a2aa_71 div[class*=bp4-tab][id*=bp4-tab-title] > span[class*=icon] {
  margin-right: 0.5rem;
}
._segmented_9a2aa_71 div[class*=bp4-tab][id*=bp4-tab-title] {
  text-transform: initial;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
._segmented_9a2aa_71 div[class*=bp4-tab][id*=bp4-tab-title] {
  line-height: 1.75rem;
}
._center_9a2aa_94 div[class*=bp4-tab-list] {
  margin: auto;
  width: fit-content;
}

/* temp_stylePlugin:ni:sha-256;yGF_B1zNwJ5ga1cQUUYnOQQVO0mq-bMhlW5m0iBUfs0 */
._segmented_1j3lv_2 div[class*=bp4-tab][aria-selected=true]:focus,
._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=true]:focus,
._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=true]:focus,
._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=true]:focus,
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=true]:focus {
  outline: none;
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-disabled=true] {
  background-color: var(--color0) !important;
  color: var(--color30);
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-disabled=true] svg {
  color: var(--color30);
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=true] {
  color: var(--color100);
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=true] svg {
  color: var(--color100);
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=true] span[class*=icon] > svg,
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=false][aria-disabled=false] :hover span[class*=icon] > svg {
  fill: var(--color100);
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=false][aria-disabled=false] {
  color: var(--color70);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=false][aria-disabled=false] span[class*=icon] > svg {
  fill: var(--color70);
}
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=false][aria-disabled=false] :hover,
._flat_1j3lv_6 div[class*=bp4-tab][aria-selected=false][aria-disabled=false] :active {
  color: var(--color100);
  background-color: var(--color0) !important;
  box-shadow: 0px -0.25rem 0px 0px var(--color10) inset !important;
  border-radius: 0.25rem 0.25rem 0 0;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._flat_1j3lv_6 div[class*=bp4-tab-indicator-wrapper] {
  z-index: 1;
}
._flat_1j3lv_6 div[class*=bp4-tab-indicator]:not([class*=bp4-tab-indicator-wrapper]) {
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: var(--color70);
}
._flat_1j3lv_6[class*=dense] div[class*=bp4-tab][aria-selected=false]:hover,
._flat_1j3lv_6[class*=dense] div[class*=bp4-tab][aria-selected=false]:active {
  box-shadow: 0px -0.125rem 0px 0px var(--color10) inset !important;
}
._flat_1j3lv_6[class*=dense] div[class*=bp4-tab-indicator]:not([class*=bp4-tab-indicator-wrapper]) {
  height: 0.125rem;
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab-indicator] {
  height: 0;
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab-indicator-wrapper] {
  z-index: 0;
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab-list] {
  background: var(--white);
  border-radius: 4px;
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab][aria-selected=false] {
  color: var(--color50);
  border-bottom: none;
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab][aria-selected=false]:hover,
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab][aria-selected=false]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color0);
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab][aria-selected=true] {
  color: var(--color90);
  font-weight: 500;
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab-indicator-wrapper] {
  border-radius: 4px;
  background-color: var(--color5);
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab][aria-selected=false] > span > svg {
  fill: var(--color40);
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab][aria-selected=false]:hover > span > svg {
  fill: var(--color90);
}
._flat_1j3lv_6[class*=segmented] div[class*=bp4-tab][aria-selected=true] > span > svg {
  fill: var(--color100);
}
._filled_1j3lv_5 div[class*=bp4-tab-list] {
  background: var(--color5);
  border-radius: 4px 4px 0 0;
}
._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=false] {
  color: var(--color60);
}
._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=false]:hover,
._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=false]:active {
  box-shadow: 1px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._filled_1j3lv_5 div[class*=bp4-tab-indicator-wrapper] {
  border: none;
  border-radius: 4px 4px 0 0;
  background: var(--color60);
}
._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=true] {
  color: var(--white);
}
._segmented_1j3lv_2._filled_1j3lv_5 div[class*=bp4-tab-list] {
  border-radius: 4px;
  border-bottom: none;
}
._segmented_1j3lv_2._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=false] {
  color: var(--grey50);
  border-bottom: none;
}
._segmented_1j3lv_2._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=false]:hover {
  color: var(--color60);
}
._segmented_1j3lv_2._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=false]:hover {
  border-bottom: none;
  box-shadow: none !important;
}
._segmented_1j3lv_2._filled_1j3lv_5 div[class*=bp4-tab][aria-selected=false]:active {
  border-bottom: none;
}
._segmented_1j3lv_2._filled_1j3lv_5 div[class*=bp4-tab-indicator-wrapper] {
  border-radius: 4px;
}
._smooth_1j3lv_4 div[class*=bp4-tab-list] {
  background: var(--color0);
  border-radius: 4px 4px 0 0;
}
._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=true] {
  color: var(--color70);
}
._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=false] {
  box-shadow: none !important;
  color: var(--color40);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=false]:hover,
._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=false]:active {
  box-shadow: 1px -2px 0px -1px var(--color70) inset !important;
  color: var(--color70);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._smooth_1j3lv_4 div[class*=bp4-tab-indicator-wrapper] {
  background-color: var(--color5);
  border-radius: 4px 4px 0 0;
}
._smooth_1j3lv_4 div[class*=bp4-tab-indicator]:not([class*=bp4-tab-indicator-wrapper]) {
  height: 3px;
  border-radius: 4px 4px 0 0;
  background-color: var(--color70);
  box-shadow: var(--shadow2dp);
}
._dense_1j3lv_216._smooth_1j3lv_4 div[class*=bp4-tab-indicator]:not([class*=bp4-tab-indicator-wrapper]) {
  height: 1px;
}
._segmented_1j3lv_2._smooth_1j3lv_4 div[class*=bp4-tab-list] {
  background: var(--color0);
  border-radius: 4px;
}
._segmented_1j3lv_2._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=false] {
  color: var(--color50);
  border-bottom: none;
}
._segmented_1j3lv_2._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=false]:hover,
._segmented_1j3lv_2._smooth_1j3lv_4 div[class*=bp4-tab][aria-selected=false]:active {
  border-bottom: none;
  color: var(--color70);
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._segmented_1j3lv_2._smooth_1j3lv_4 div[class*=bp4-tab-indicator-wrapper] {
  border-radius: 4px;
  background-color: var(--color10);
}
._outlined_1j3lv_247 div[class*=bp4-tab-list] {
}
._segmented_1j3lv_2._outlined_1j3lv_247 div[class*=bp4-tab-list] {
  border: 1px solid var(--color20);
  border-radius: 4px;
}
._outlined_1j3lv_247 div[class*=bp4-tab][aria-selected=true] {
  color: var(--color70);
  box-shadow:
    0px 2px 0px -1px var(--color40) inset,
    2px -1px 0px -1px var(--color40) inset,
    -2px -1px 0px -1px var(--color40) inset !important;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  transform-origin: 0 100%;
  animation: _bp4-tab-flip_1j3lv_1 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._segmented_1j3lv_2._outlined_1j3lv_247 div[class*=bp4-tab][aria-selected=true] {
  border: none;
  box-shadow: none !important;
  animation: none;
}
._outlined_1j3lv_247 div[class*=bp4-tab][aria-selected=false] {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  color: var(--color40);
  box-shadow: 1px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._segmented_1j3lv_2._outlined_1j3lv_247 div[class*=bp4-tab][aria-selected=false] {
  border-bottom: none;
  color: var(--color50);
  box-shadow: none !important;
}
._segmented_1j3lv_2._outlined_1j3lv_247 div[class*=bp4-tab][aria-selected=false] svg {
  color: var(--color30);
}
._outlined_1j3lv_247 div[class*=bp4-tab][aria-selected=false]:hover,
._segmented_1j3lv_2._outlined_1j3lv_247 div[class*=bp4-tab][aria-selected=false]:hover svg {
  color: var(--color70);
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._segmented_1j3lv_2._outlined_1j3lv_247 div[class*=bp4-tab-indicator-wrapper] {
  border: 1px solid var(--color40);
  border-radius: 4px;
  margin-top: 0;
  background: var(--color0);
}
._raised_1j3lv_3 div[class*=bp4-tab-list] {
  background: var(--color5);
  border-radius: 4px 4px 0 0;
}
._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=true],
._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false] {
  color: var(--color70);
}
._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=true] {
  border-radius: 4px 4px 0 0;
  transition: all 180ms ease-out;
}
._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false] {
  box-shadow: 0px -3px 0px -1px var(--color20) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._dense_1j3lv_216._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false] {
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false]:hover,
._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false]:active {
  color: var(--color70);
  box-shadow: 0px -3px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._dense_1j3lv_216._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false]:hover,
._dense_1j3lv_216._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false]:active {
  box-shadow: 0px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._raised_1j3lv_3 div[class*=bp4-tab-indicator-wrapper] {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  border-radius: 4px 4px 0 0;
}
._raised_1j3lv_3 div[class*=bp4-tab-indicator]:not([class*=bp4-tab-indicator-wrapper]) {
  height: 2px;
  background-color: var(--color60);
}
._dense_1j3lv_216._raised_1j3lv_3 div[class*=bp4-tab-indicator]:not([class*=bp4-tab-indicator-wrapper]) {
  height: 1px;
}
._segmented_1j3lv_2._raised_1j3lv_3 div[class*=bp4-tab-list] {
  border-radius: 4px;
  border-bottom: none;
}
._segmented_1j3lv_2._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false] {
  color: var(--color50);
  border-bottom: none;
  box-shadow: none !important;
}
._segmented_1j3lv_2._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false]:hover,
._segmented_1j3lv_2._raised_1j3lv_3 div[class*=bp4-tab][aria-selected=false]:active {
  border-bottom: none;
  box-shadow: none !important;
  color: var(--color60);
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._segmented_1j3lv_2._raised_1j3lv_3 div[class*=bp4-tab-indicator-wrapper] {
  border-radius: 4px;
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
}
._filled_1j3lv_5 div[class*=bp4-tab-indicator],
._outlined_1j3lv_247 div[class*=bp4-tab-indicator],
._smooth_1j3lv_4 div[class*=bp4-tab-indicator],
._segmented_1j3lv_2._raised_1j3lv_3 div[class*=bp4-tab-indicator],
._segmented_1j3lv_2._smooth_1j3lv_4 div[class*=bp4-tab-indicator] {
  height: 0;
}
._flat_1j3lv_6 div[class*=bp4-tab][id*=bp4-tab-title],
._filled_1j3lv_5 div[class*=bp4-tab][id*=bp4-tab-title],
._smooth_1j3lv_4 div[class*=bp4-tab][id*=bp4-tab-title],
._raised_1j3lv_3 div[class*=bp4-tab][id*=bp4-tab-title],
._outlined_1j3lv_247 div[class*=bp4-tab][id*=bp4-tab-title] {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}
@keyframes _bp4-tab-flip_1j3lv_1 {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

/* temp_stylePlugin:ni:sha-256;Sbd38Su-jyRMZVPgptkuF_410naZlWHCPZO2xx9UF6E */
._def_q3g06_2 div[class*=tab-list] {
  height: 2.5rem;
  align-items: center;
}
._dense_q3g06_7 div[class*=tab-list] {
  height: 1.75rem;
  align-items: center;
}
._def_q3g06_2 div[class*=tab],
._dense_q3g06_7 div[class*=tab] {
  margin-right: 0;
}
._def_q3g06_2 div[class*=tab][id*=tab-title] {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}
._dense_q3g06_7 div[class*=tab][id*=tab-title] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}
._def_q3g06_2 div[class*=tab][aria-selected=true],
._dense_q3g06_7 div[class*=tab][aria-selected=true] {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: 0.0152em;
}
._def_q3g06_2 div[class*=tab][id*=tab-title] {
  line-height: 2.5rem;
}
._dense_q3g06_7 div[class*=tab][id*=tab-title] {
  line-height: 1.75rem;
}
._def_q3g06_2 div[class*=tab][id*=tab-title] {
  padding: 0 1rem;
  text-align: center;
}
._dense_q3g06_7 div[class*=tab][id*=tab-title] {
  padding: 0 0.75rem;
  text-align: center;
}
._def_q3g06_2 div[class*=tab][id*=tab-title] > span[class*=icon] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
._def_q3g06_2 div[role=tablist][class*=tab-list],
._dense_q3g06_7 div[role=tablist][class*=tab-list] {
  margin-left: 0;
}
._right_q3g06_73 div[role=tablist][class*=tab-list] {
  margin-left: auto;
  margin-right: 0 !important;
}

/* temp_stylePlugin:ni:sha-256;EzX7AQ97VS9Vhgzqy32a28VeuENfRrdeDe8A7OiMtbk */
._flat_1ijzm_3 div[class*=tab-indicator] {
  height: 0;
}
._flat_1ijzm_3 div[class*=tab-list] {
  background: var(--white);
  border-radius: 4px;
}
._flat_1ijzm_3 div[class*=tab][aria-selected=true] {
  color: var(--color90);
  font-weight: 500;
}
._flat_1ijzm_3 div[class*=tab][aria-selected=false] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._flat_1ijzm_3 div[class*=tab][aria-selected=false]:hover,
._flat_1ijzm_3 div[class*=tab][aria-selected=false]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color0) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._flat_1ijzm_3 div[class*=tab][aria-selected=false] > span > svg {
  fill: var(--color40);
}
._flat_1ijzm_3 div[class*=tab][aria-selected=false]:hover > span > svg {
  fill: var(--color90);
}
._flat_1ijzm_3 div[class*=tab][aria-selected=true] > span > svg {
  fill: var(--color100);
}
._flat_1ijzm_3 div[class*=tab-indicator-wrapper] {
  border-radius: 4px;
  background-color: var(--color5);
}
._filled_1ijzm_52 div[class*=tab-indicator] {
  height: 0;
}
._filled_1ijzm_52 div[class*=tab-list] {
  background: var(--color10);
  border-radius: 4px;
}
._filled_1ijzm_52 div[class*=tab][aria-selected=true] {
  color: var(--white);
  font-weight: 500;
}
._filled_1ijzm_52 div[class*=tab][aria-selected=false] {
  color: var(--color70);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._filled_1ijzm_52 div[class*=tab][aria-selected=false]:hover,
._filled_1ijzm_52 div[class*=tab][aria-selected=false]:active {
  border-bottom: none;
  color: var(--color100);
  background-color: var(--color20) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._filled_1ijzm_52 div[class*=tab][aria-selected=false] > span > svg {
  fill: var(--color50);
}
._filled_1ijzm_52 div[class*=tab][aria-selected=false]:hover > span > svg {
  fill: var(--color100);
}
._filled_1ijzm_52 div[class*=tab][aria-selected=true] > span > svg {
  fill: var(--white);
}
._filled_1ijzm_52 div[class*=tab-indicator-wrapper] {
  border-radius: 4px;
  background-color: var(--color50);
}
._smooth_1ijzm_101 div[class*=tab-indicator] {
  height: 0;
}
._smooth_1ijzm_101 div[class*=tab-list] {
  background: var(--color5);
  border-radius: 4px;
}
._smooth_1ijzm_101 div[class*=tab][aria-selected=true] {
  color: var(--color100);
  font-weight: 500;
}
._smooth_1ijzm_101 div[class*=tab][aria-selected=false] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._smooth_1ijzm_101 div[class*=tab][aria-selected=false]:hover,
._smooth_1ijzm_101 div[class*=tab][aria-selected=false]:active {
  border-bottom: none;
  color: var(--color100);
  background-color: var(--color10) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._smooth_1ijzm_101 div[class*=tab][aria-selected=false] > span > svg {
  fill: var(--color40);
}
._smooth_1ijzm_101 div[class*=tab][aria-selected=false]:hover > span > svg {
  fill: var(--color90);
}
._smooth_1ijzm_101 div[class*=tab][aria-selected=true] > span > svg {
  fill: var(--color100);
}
._smooth_1ijzm_101 div[class*=tab-indicator-wrapper] {
  border-radius: 4px;
  background-color: var(--color20);
}
._outlined_1ijzm_150 div[class*=tab-indicator] {
  height: 0;
}
._outlined_1ijzm_150 div[class*=tab-list] {
  background: var(--white);
  box-shadow: 0 0 0 1px var(--color20);
  border-radius: 4px;
}
._outlined_1ijzm_150 div[class*=tab][aria-selected=true] {
  color: var(--color90);
  font-weight: 500;
  z-index: 1;
}
._outlined_1ijzm_150 div[class*=tab][aria-selected=false] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._outlined_1ijzm_150 div[class*=tab][aria-selected=false]:hover,
._outlined_1ijzm_150 div[class*=tab][aria-selected=false]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color0) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._outlined_1ijzm_150 div[class*=tab][aria-selected=false] > span > svg {
  fill: var(--color40);
}
._outlined_1ijzm_150 div[class*=tab][aria-selected=false]:hover > span > svg {
  fill: var(--color90);
}
._outlined_1ijzm_150 div[class*=tab][aria-selected=true] > span > svg {
  fill: var(--color90);
}
._outlined_1ijzm_150 div[class*=tab-indicator-wrapper] {
  border-radius: 0.25rem;
  box-shadow: 0 0 0 2px var(--color30);
  background-color: var(--white);
  z-index: 1;
}
._raised_1ijzm_203 div[class*=tab-indicator] {
  height: 0;
}
._raised_1ijzm_203 div[class*=tab-list] {
  background: var(--color0);
  border-radius: 4px;
}
._raised_1ijzm_203 div[class*=tab][aria-selected=true] {
  color: var(--color90);
  font-weight: 500;
}
._raised_1ijzm_203 div[class*=tab][aria-selected=false] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._raised_1ijzm_203 div[class*=tab][aria-selected=false]:hover,
._raised_1ijzm_203 div[class*=tab][aria-selected=false]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color5) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._raised_1ijzm_203 div[class*=tab][aria-selected=false] > span > svg {
  fill: var(--color40);
}
._raised_1ijzm_203 div[class*=tab][aria-selected=false]:hover > span > svg {
  fill: var(--color90);
}
._raised_1ijzm_203 div[class*=tab][aria-selected=true] > span > svg {
  fill: var(--color90);
}
._raised_1ijzm_203 div[class*=tab-indicator-wrapper] {
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: var(--shadow2dp);
}
._filled_1ijzm_52 div[class*=tab-indicator],
._outlined_1ijzm_150 div[class*=tab-indicator],
._smooth_1ijzm_101 div[class*=tab-indicator],
._segmented_1ijzm_255._raised_1ijzm_203 div[class*=tab-indicator],
._segmented_1ijzm_255._smooth_1ijzm_101 div[class*=tab-indicator] {
  height: 0;
}
@keyframes _tab-flip_1ijzm_1 {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

/* temp_stylePlugin:ni:sha-256;NaxX98DYhvOJhTgwTW_2N8_BLwrg-F8BvhcVPhwoHek */
._def_cwjsk_2[class*=tag] {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
  min-width: 0px;
  height: 2rem;
  text-transform: none;
  padding: 0.25rem 0.625rem;
  justify-content: left;
  width: fit-content;
}
._def_cwjsk_2[class*=tag]:focus,
._dense_cwjsk_19[class*=tag]:focus {
  outline: none !important;
}
._def_cwjsk_2[class*=tag] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.375rem;
}
._def_cwjsk_2[class*=tag] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.375rem;
}
._def_cwjsk_2[class*=tag] span[icon]:first-of-type > svg {
  height: 1.25rem;
  width: 1.25rem;
}
._def_cwjsk_2[class*=tag] button[class*=tag-remove] > span[class*=small-cross] {
  margin-right: 0;
  margin-left: 0.375rem;
}
._def_cwjsk_2[class*=tag] button[class*=tag-remove] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._def_cwjsk_2[class*=tag] span[class*=text] {
  margin-right: 0;
}
._dense_cwjsk_19[class*=tag] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  font-weight: 400;
  min-width: 0px;
  height: 1.25rem;
  min-height: 1.25rem;
  text-transform: none;
  padding: 0.125rem 0.375rem;
  justify-content: left;
  width: fit-content;
}
._dense_cwjsk_19[class*=tag] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.25rem;
}
._dense_cwjsk_19[class*=tag] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.25rem;
}
._dense_cwjsk_19[class*=tag] span[icon]:first-of-type > svg {
  height: 1rem;
  width: 1rem;
}
._dense_cwjsk_19[class*=tag] button[class*=tag-remove] > span[class*=small-cross] {
  margin-right: 0;
  margin-left: 0.125rem;
}
._dense_cwjsk_19[class*=tag] button[class*=tag-remove] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._dense_cwjsk_19[class*=tag] span[class*=text] {
  margin-right: 0;
}
._round_cwjsk_103[class*=tag] {
  border-radius: 99px;
}
._def_cwjsk_2[class*=tag]._number_cwjsk_109,
._dense_cwjsk_19[class*=tag]._number_cwjsk_109 {
  line-height: 1.25rem;
}
._def_cwjsk_2[class*=tag]._number_cwjsk_109 span > div {
  min-width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 0.375rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0.4375rem;
}
._def_cwjsk_2[class*=tag]._number_cwjsk_109._round_cwjsk_103 span > div {
  border-radius: 99px;
}
._dense_cwjsk_19[class*=tag]._number_cwjsk_109 span > div {
  min-width: 0;
  height: 1.25rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.25rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0;
  line-height: 1.25rem;
}
._def_cwjsk_2[class*=tag]._avatar_cwjsk_146,
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146 {
  border-radius: 99px;
}
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146 {
  padding-left: 0;
}
._def_cwjsk_2[class*=tag]._avatar_cwjsk_146 span[class*=text],
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146 span[class*=text] {
  line-height: 0;
}
._def_cwjsk_2[class*=tag]._avatar_cwjsk_146 span > div:first-of-type,
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146 span > div:first-of-type {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.375rem;
  vertical-align: middle;
}
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146 span > div:first-of-type {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
}
._def_cwjsk_2[class*=tag]._avatar_cwjsk_146 span > div:first-of-type > img {
  width: 1.5rem;
  height: 1.5rem;
}
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146 span > div:first-of-type > img {
  width: 1.25rem;
  height: 1.25rem;
}
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146._outlined_cwjsk_186 span > div:first-of-type > img {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.0625rem;
}
._def_cwjsk_2[class*=tag]._avatar_cwjsk_146 span > div:last-of-type,
._dense_cwjsk_19[class*=tag]._avatar_cwjsk_146 span > div:last-of-type {
  display: inline-block;
  vertical-align: middle;
}
._def_cwjsk_2 ._counter_cwjsk_198 {
  color: white;
  background-color: var(--color70);
}
._dense_cwjsk_19 ._counter_cwjsk_198 {
  color: var(--color70);
}

/* temp_stylePlugin:ni:sha-256;dZq_5btD8KzezYH2r7c_L4wL4LMtKJvR5FmnQYGXqxk */
._smooth_1xpr2_3[class*=bp4-tag],
._smooth_1xpr2_3[class*=bp4]:visited {
  color: var(--color70);
  background-color: var(--color10);
  transition: all 180ms ease-in;
}
._smooth_1xpr2_3[class*=tag]:not(._unclickable_1xpr2_10):hover {
  box-shadow: var(--shadow2dp);
  background-color: var(--color10);
  transition: all 200ms ease-in;
}
._smooth_1xpr2_3[class*=tag] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._smooth_1xpr2_3[class*=tag] button[class*=tag-remove] {
  opacity: 1;
}
._smooth_1xpr2_3[class*=tag] button[class*=tag-remove] > span[class*=small-cross] > svg {
  color: var(--color50);
}
._smooth_1xpr2_3[class*=tag] button[class*=tag-remove]:hover > span[class*=small-cross] > svg {
  color: var(--color70);
  opacity: 1;
  transition: all 180ms ease-in;
}
._smooth_1xpr2_3[class*=tag][class*=bp4-active],
._smooth_1xpr2_3[class*=tag][class*=bp4-active]:hover {
  color: var(--color0);
  background-color: var(--color40);
  transition: all 180ms ease-in;
}
._smooth_1xpr2_3[class*=tag][class*=bp4-active] svg,
._smooth_1xpr2_3[class*=tag][class*=bp4-active] svg:not(._unclickable_1xpr2_10):hover {
  background-color: transparent;
  color: var(--color0);
}
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48 svg,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48._active_1xpr2_50,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48._active_1xpr2_50 svg,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48:hover,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove]:hover > span[class*=small-cross] > svg,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove] > span[class*=small-cross] > svg,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove]:hover > span[class*=small-cross] > svg,
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove],
._smooth_1xpr2_3[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove]:not(._unclickable_1xpr2_10):hover {
  background-color: var(--color5);
  color: var(--color30);
  box-shadow: none;
  cursor: no-drop;
}
._outlined_1xpr2_66[class*=tag],
._outlined_1xpr2_66[class*=tag]:visited {
  color: var(--color70);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 100ms ease-in;
}
._outlined_1xpr2_66[class*=tag]:not(._unclickable_1xpr2_10):hover {
  box-shadow: 0 0 0 1px var(--color30);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 200ms ease-in;
}
._outlined_1xpr2_66[class*=tag]._active_1xpr2_50 {
  background-color: var(--color5);
  border: 0.0625rem solid var(--color50);
  box-shadow: 0 0 0 1px var(--color50);
  color: var(--color70);
  transition: all 150ms ease-in;
}
._outlined_1xpr2_66[class*=tag]._active_1xpr2_50 svg,
._outlined_1xpr2_66[class*=tag]._active_1xpr2_50 svg:not(._unclickable_1xpr2_10):hover {
  color: var(--color70);
  transition: all 150ms ease-in;
}
._outlined_1xpr2_66[class*=tag] button[class*=tag-remove] {
  background: transparent;
  opacity: 1;
  transition: all 150ms ease-in;
}
._outlined_1xpr2_66[class*=tag] svg {
  color: var(--color30);
  transition: all 150ms ease-in;
}
._outlined_1xpr2_66[class*=tag] button[class*=tag-remove] > span > svg {
  color: var(--color50);
}
._outlined_1xpr2_66[class*=tag] button[class*=tag-remove]:hover > span > svg {
  color: var(--color70);
  transition: all 180ms ease-in;
}
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 svg,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48._active_1xpr2_50,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48._active_1xpr2_50 svg,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48:hover,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 svg:hover,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove] > span > svg,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove]:hover > span > svg {
  background-color: transparent;
  color: var(--color30);
  border: 0.0625rem solid var(--color10);
  box-shadow: none;
  cursor: no-drop;
  opacity: 1;
}
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove] > span > svg,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 button[class*=tag-remove]:hover > span > svg,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 svg:hover,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48 svg,
._outlined_1xpr2_66[class*=tag]._disabled_1xpr2_48._active_1xpr2_50 svg {
  border: none;
}

/* temp_stylePlugin:ni:sha-256;iT6c8jENY7jpRK21-yrxUvb5ki1ipcdaQC-TPAhonnM */
._input_tag_x6jys_1 {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_x6jys_1:hover {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_x6jys_1[class*=bp4-active] {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_x6jys_1:hover {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_x6jys_1:active {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._input_tag_x6jys_1 input[class*=bp4-input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._input_tag_x6jys_1 input[class*=bp4-input]:hover::placeholder {
  color: var(--color70);
}
._input_tag_x6jys_1 input[class*=bp4-input] svg {
  color: var(--blue40);
}
._input_tag_x6jys_1 ._tag_x6jys_39[class*=bp4-tag][class*=bp4-minimal] {
  border-radius: 99px;
  padding: 0px;
}
._input_tag_x6jys_1 ._tag_x6jys_39[class*=bp4-tag][class*=bp4-minimal]._dense_x6jys_43 {
  padding: 0.125rem 0.375rem;
}
._input_tag_x6jys_1 ._tag_x6jys_39[class*=bp4-tag][class*=bp4-minimal]:not(._dense_x6jys_43) {
  padding: 0.25rem 0.625rem;
  font-size: 16px;
}
._input_tag_x6jys_1 span[class*=icon] {
  margin-top: auto;
  margin-bottom: auto;
}
._input_tag_x6jys_1 span[class*=icon] svg {
  color: var(--color40) !important;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  min-width: 0px;
  text-transform: none;
  justify-content: left;
  width: fit-content;
  color: var(--color70);
  background-color: var(--color5);
  transition: all 180ms ease-in;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.25rem;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.25rem;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] span[icon]:first-of-type > svg {
  height: 1rem;
  width: 1rem;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] span[class*=icon-tick]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  transform: scale(0);
  transition: all 200ms ease-in;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] ._dense_x6jys_43._active_x6jys_91[class*=tag] span[class*=icon-tick]:first-of-type {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  transform: scaleX(1);
  transition: all 200ms ease-in;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] button[class*=tag-remove] > span[class*=small-cross] {
  margin-right: 0;
  margin-left: 0.125rem;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] button[class*=tag-remove] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] span[class*=text] {
  margin-right: 0;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal]:visited {
  color: var(--color70);
  background-color: var(--color5);
  transition: all 180ms ease-in;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal]:hover {
  box-shadow: var(--shadow2dp);
  background-color: var(--color5);
  transition: all 200ms ease-in;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] button[class*=tag-remove] {
  opacity: 1;
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] button[class*=tag-remove] > span[class*=small-cross] > svg {
  color: var(--color50);
}
._input_tag_x6jys_1 ._tag_x6jys_39 span[class*=bp4-tag][class*=bp4-minimal] button[class*=tag-remove]:hover > span[class*=small-cross] > svg {
  color: var(--color70);
  opacity: 1;
  transition: all 180ms ease-in;
}

/* temp_stylePlugin:ni:sha-256;67s6aS4E9tzzQvtK3QvrqOjtXpgj4Ksh82ipBt0ExNo */
._reverseFlex_10w8f_1 {
  display: flex;
  flex-direction: column-reverse;
}
._reverseFlex_10w8f_1 ._error-helper-text_10w8f_5._def_10w8f_5 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.033em;
  color: var(--grey50);
}
._reverseFlex_10w8f_1 ._error-helper-text_10w8f_5._dense_10w8f_14 {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.06em;
  color: var(--grey50);
}
._reverseFlex_10w8f_1._isFull_10w8f_23 {
  width: auto;
}
._def_10w8f_5,
._dense_10w8f_14 {
  resize: none;
  width: fit-content;
}
._grow_10w8f_34 {
  resize: vertical;
}
._def_10w8f_5:placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0275em;
  color: var(--grey100);
}
._dense_10w8f_14:placeholder {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
}
._full_10w8f_57 {
  width: 100%;
}
._def_10w8f_5[class*=bp4-input] {
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
._def_10w8f_5[class*=bp4-input] {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}
._def_10w8f_5 label {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
._dense_10w8f_14[class*=bp4-input] {
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.375rem;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}
._dense_10w8f_14[class*=bp4-input] {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
}
._dense_10w8f_14 label {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
._def_10w8f_5._clearButton_10w8f_109[class*=bp4-input] span[class*=input-action] > button {
  padding: 0;
  margin: 0;
  margin-right: 0.5rem;
  margin-top: 0.125rem;
}
._dense_10w8f_14._clearButton_10w8f_109[class*=bp4-input] span[class*=input-action] > button {
  padding: 0;
  margin: 0;
  margin-right: 0.125rem;
  margin-top: 0.0625rem;
}
._dense_10w8f_14._clearButton_10w8f_109[class*=bp4-input] span[class*=input-action] > button svg {
  width: 1rem;
}
._clearButton_10w8f_109[class*=bp4-input] span[class*=input-action] {
  top: initial;
}
._def_10w8f_5._right-icon_10w8f_132[class*=bp4-input] span[class*=input-action] {
  padding: 0;
  margin: 0;
  margin-right: 0.625rem;
  margin-top: 0.75rem;
}
._dense_10w8f_14._right-icon_10w8f_132[class*=bp4-input] span[class*=input-action] {
  padding: 0;
  margin: 0;
  margin-right: 0.375rem;
  bottom: 0.4375rem;
}
._right-icon_10w8f_132[class*=bp4-input] span[class*=input-action] {
  top: initial;
}
._def_10w8f_5 ._error-helper-text_10w8f_5 {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  animation: _shake-horizontal_10w8f_1 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
._dense_10w8f_14 ._error-helper-text_10w8f_5 {
  position: absolute;
  bottom: -0.875rem;
  z-index: 1;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  font-weight: var(--font_weight_caption_small);
  animation: _shake-horizontal_10w8f_1 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@keyframes _shake-horizontal_10w8f_1 {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

/* temp_stylePlugin:ni:sha-256;8ii1pXQhcRhjQNE_4HC-_hTIoI4rjaUD-040IJWrNTo */
._smooth_113gf_3[class*=bp4-input] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_113gf_3[class*=bp4-input]._active_113gf_10,
._smooth_113gf_3[class*=bp4-input]:hover {
  color: var(--color70);
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_113gf_3[class*=bp4-input]._active_113gf_10::placeholder {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._smooth_113gf_3[class*=bp4-input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._smooth_113gf_3[class*=bp4-input] ~ label {
  opacity: 0;
  color: var(--color50);
}
._smooth_113gf_3[class*=bp4-input]._active_113gf_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._smooth_113gf_3[class*=bp4-input]:focus {
  box-shadow: none;
}
._smooth_113gf_3[class*=bp4-input] > span svg {
  color: var(--color40);
}
._smooth_113gf_3[class*=bp4-input]._active_113gf_10 > span svg {
  color: var(--color60);
}
._outlined_113gf_55[class*=bp4-input] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_113gf_55[class*=bp4-input]:hover {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_113gf_55[class*=bp4-input]:active {
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_113gf_55[class*=bp4-input]._active_113gf_10 {
  box-shadow: 0 0 0 2px var(--color80);
}
._outlined_113gf_55._active_113gf_10[class*=bp4-input] {
  box-shadow: 0 0 0 1px var(--color70);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_113gf_55[class*=bp4-input]:active {
  color: var(--color70);
  box-shadow: 0 0 0 2px var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._outlined_113gf_55[class*=bp4-input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._outlined_113gf_55[class*=bp4-input]:hover::placeholder {
  color: var(--color70);
}
._outlined_113gf_55[class*=bp4-input] ~ label {
  opacity: 0;
  color: var(--color50);
}
._outlined_113gf_55[class*=bp4-input]._active_113gf_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._outlined_113gf_55[class*=bp4-input] > span svg {
  color: var(--color40);
}
._outlined_113gf_55[class*=bp4-input]._active_113gf_10 > span svg {
  color: var(--color60);
}
._outlined_113gf_55[class*=bp4-input]:disabled,
._outlined_113gf_55[class*=bp4-input]:disabled:hover {
  box-shadow: 0 0 0 1px var(--color30);
  color: var(--color30);
}
._outlined_113gf_55[class*=bp4-input]:disabled::placeholder,
._outlined_113gf_55[class*=bp4-input]:disabled:hover::placeholder {
  color: var(--color30);
}
._raised_113gf_132[class*=bp4-input] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_113gf_132[class*=bp4-input]:hover {
  color: var(--color100);
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_113gf_132._active_113gf_10[class*=bp4-input],
._raised_113gf_132._active_113gf_10._success_113gf_146[class*=bp4-input],
._raised_113gf_132._active_113gf_10._error-helper-text_113gf_147[class*=bp4-input] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._raised_113gf_132[class*=bp4-input]._active_113gf_10 {
  box-shadow: var(--shadow8dp);
}
._raised_113gf_132[class*=bp4-input]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._raised_113gf_132[class*=bp4-input]:hover::placeholder {
  color: var(--color60);
}
._raised_113gf_132[class*=bp4-input] ~ label {
  color: var(--color50);
  opacity: 0;
}
._raised_113gf_132[class*=bp4-input]._active_113gf_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._raised_113gf_132[class*=bp4-input] > span svg {
  color: var(--color40);
}
._raised_113gf_132[class*=bp4-input]._active_113gf_10 > span svg {
  color: var(--color60);
}
._filled_113gf_189[class*=bp4-input] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_113gf_189[class*=bp4-input]:hover {
  color: var(--color0);
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_113gf_189._active_113gf_10[class*=bp4-input] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_113gf_189[class*=bp4-input]._active_113gf_10 {
  box-shadow: none;
  background: var(--color70);
}
._filled_113gf_189[class*=bp4-input]::placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: initial;
}
._filled_113gf_189[class*=bp4-input] ~ label {
  opacity: 0;
}
._filled_113gf_189[class*=bp4-input]._active_113gf_10 ~ label {
  color: var(--color50);
  opacity: 1;
}
._filled_113gf_189[class*=bp4-input] > span svg {
  color: var(--color40);
}
._filled_113gf_189[class*=bp4-input]._active_113gf_10 > span svg {
  color: var(--color60);
}
._error-helper-text_113gf_147 {
  color: var(--red60) !important;
}
._error-helper-text_113gf_147 span[class*=input-action] svg {
  color: var(--red60);
}
._filled_113gf_189._error-helper-text_113gf_147 span[class*=input-action] svg {
  color: var(--color0);
}
._success_113gf_146 span[class*=input-action] svg {
  color: var(--green60);
}
._filled_113gf_189._success_113gf_146 span[class*=input-action] svg {
  color: var(--red60);
}
._clearButton_113gf_265[class*=bp4-input] > input + span[class*=input-action] > button {
  cursor: pointer;
}
._clearButton_113gf_265[class*=bp4-input]._active_113gf_10 span[class*=input-action] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._clearButton_113gf_265[class*=bp4-input]._active_113gf_10 span[class*=input-action] > button[class*=button],
._clearButton_113gf_265[class*=bp4-input]._active_113gf_10 span[class*=input-action] > button[class*=button] > span[class*=icon] {
  color: var(--color40) !important;
  opacity: 1;
  visibility: visible;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._filled_113gf_189._clearButton_113gf_265[class*=bp4-input]:hover span[class*=input-action] > button[class*=button] > span[class*=icon] {
  color: var(--color5) !important;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* temp_stylePlugin:ni:sha-256;vLZJuCUDt_uy3dqkc4sQxljh9zb46JidDSXKmd1XPPw */
._default_1r2e3_2 div[class*=timepicker-input-row] {
  height: auto;
  border-radius: 0.25rem;
  line-height: unset;
  padding: 0;
}
._default_1r2e3_2 input[class*=timepicker-input] {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  line-height: unset;
  letter-spacing: 0.0275rem;
}
._default_1r2e3_2 span[class*=timepicker-divider-text] {
  width: 0.5rem;
}
._default_1r2e3_2 span[class*=timepicker-arrow-button] {
  width: 2.5rem;
}
._default_1r2e3_2 span[class*=timepicker-arrow-button][class*=minute],
._default_1r2e3_2 span[class*=timepicker-arrow-button][class*=second],
._default_1r2e3_2 span[class*=timepicker-arrow-button][class*=millisecond] {
  margin-left: 0.5rem;
}
._default_1r2e3_2 div[class*=timepicker-arrow-row] svg {
  width: 1.25rem;
  height: 1.25rem;
}
._default_1r2e3_2 div[class*=ampm-select] {
  margin-left: 0.5rem;
}
._default_1r2e3_2 div[class*=ampm-select] > select {
  height: 2.5rem;
  line-height: unset;
  border-radius: 0.25rem;
  padding: 0 1rem;
  letter-spacing: 0.0275rem;
  font-size: inherit;
  font-family: inherit;
}
._default_1r2e3_2 div[class*=ampm-select] > span[class*=icon] {
  position: relative;
  top: 0.125rem;
  right: unset;
  left: -36px;
  padding-right: 16px;
}
._default_1r2e3_2 div[class*=ampm-select] > span[class*=icon] > svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* temp_stylePlugin:ni:sha-256;wWD340zvLXv79n6q0Rs08iO0ZhRby7d-LXGd4zcDtqk */
._outlined_1ro76_2 div[class*=timepicker-input-row] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._outlined_1ro76_2 input[class*=timepicker-input] {
  color: var(--color100);
}
._outlined_1ro76_2 span[class*=timepicker-divider-text] {
  color: var(--color40);
}
._outlined_1ro76_2 input[class*=timepicker-input]:focus {
  border: 2px solid var(--color80);
  box-shadow: var(--shadow4dp);
}
._outlined_1ro76_2 div[class*=timepicker-arrow-row] svg {
  color: var(--color70);
}
._outlined_1ro76_2 span[class*=timepicker-arrow-button]:hover svg {
  color: var(--color100);
}
._outlined_1ro76_2 div[class*=ampm-select] > select {
  background: var(--color5);
  border: none;
  box-shadow: none;
  color: var(--color70);
}
._outlined_1ro76_2 div[class*=ampm-select] > span[class*=icon] {
  color: var(--color70);
}
._outlined_1ro76_2 input[class*=timepicker-input][class*=danger]:focus {
  border: 2px solid var(--red80);
  box-shadow: var(--redShadow4dp);
}
._outlined_1ro76_2 input[class*=timepicker-input][class*=danger] {
  border: 1px solid var(--red30);
  box-shadow: none;
}

/* temp_stylePlugin:ni:sha-256;Qj-EODQrQZMdy_QDNX-e0zmk5VYkeIwkX-5y1jPHJdY */
._def_1bfvp_2 > div {
  height: auto;
  border-radius: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 999;
}
._def_1bfvp_2 span[class*=toast-message] {
  padding: 0;
  margin: auto;
  margin-left: 1rem;
  font-size: var(--font_size_h6);
  font-family: var(--font_secondary);
  letter-spacing: var(--letter_spacing_h6);
  font-weight: bold;
}
._def_1bfvp_2 div[class*=button-group] {
  padding: 0;
  margin: auto;
  margin-right: 1rem;
}
._def_1bfvp_2 div[class*=button-group] svg {
  width: 1.5rem;
  height: 1.5rem;
}
._def_1bfvp_2 div[class*=button-group] a {
  margin-left: 0.5rem;
  font-family: var(--font_primary);
  text-transform: uppercase;
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  font-weight: var(--font_weight_button);
}
._def_1bfvp_2 span[class*=icon]:first-child {
  float: left;
  border-radius: 99px;
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
}
._def_1bfvp_2 span[class*=icon]:first-child > svg {
  margin: auto;
}
._def_1bfvp_2._no-close_1bfvp_54 div[class*=button-group] button[class*=button] {
  display: none;
}

/* temp_stylePlugin:ni:sha-256;lgeKGpyciUGb0zbz6BgVy8EIvX8PtV0UsFusMrHw5jc */
._filled_1bob5_4 > div {
  background-color: var(--color70);
  color: var(--color10);
  box-shadow: none;
}
._filled_1bob5_4 div[class*=button-group] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._filled_1bob5_4 div[class*=button-group] > button:hover svg,
._filled_1bob5_4 div[class*=button-group] > button[class*=button]:hover,
._filled_1bob5_4 div[class*=button-group] a[class*=button]:hover {
  color: var(--color10);
  background: none;
  transition: all 180ms ease-in;
}
._filled_1bob5_4 div[class*=button-group] a {
  color: var(--color30);
  transition: all 180ms ease-in;
}
._filled_1bob5_4 svg,
._filled_1bob5_4 span[class*=icon]:first-child {
  color: var(--color0);
}
._filled_1bob5_4 div > span[class*=icon]:first-child {
  background: var(--color40);
}
._smooth_1bob5_38 > div {
  background-color: var(--color5);
  color: var(--color80);
  box-shadow: none;
}
._smooth_1bob5_38 div[class*=button-group] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._smooth_1bob5_38 div[class*=button-group] > button:hover svg,
._smooth_1bob5_38 div[class*=button-group] > button[class*=button]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
._smooth_1bob5_38 div[class*=button-group] > button[class*=button] {
  color: var(--color70);
  background: var(--white);
  padding: 0.75rem;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  box-shadow: var(--shadow4dp);
  transition: all 180ms ease-in;
}
._smooth_1bob5_38 div[class*=button-group] > button[class*=button]:hover {
  color: var(--color70);
  background: var(--white);
  box-shadow: var(--shadow8dp);
}
._smooth_1bob5_38 div[class*=button-group] > button[class*=button]:active {
  box-shadow: var(--shadow2dp);
}
._smooth_1bob5_38 svg,
._smooth_1bob5_38 span[class*=icon]:first-child {
  color: var(--color80);
}
._smooth_1bob5_38 div > span[class*=icon]:first-child {
  background: var(--color20);
}
._outlined_1bob5_88 > div {
  background-color: var(--white);
  color: var(--color60);
  border: 1px solid var(--color30);
  box-shadow: none;
}
._outlined_1bob5_88 div[class*=button-group] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._outlined_1bob5_88 div[class*=button-group] > button:hover svg,
._outlined_1bob5_88 div[class*=button-group] > button[class*=button]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
._outlined_1bob5_88 div[class*=button-group] > button[class*=button] {
  color: var(--color60);
  padding: 0.75rem;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  transition: all 180ms ease-in;
}
._outlined_1bob5_88 div[class*=button-group] > button[class*=button]:hover {
  color: var(--color60);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._outlined_1bob5_88 div[class*=button-group] > button[class*=button]:active {
  box-shadow: none;
  background: var(--color10);
}
._outlined_1bob5_88 svg,
._outlined_1bob5_88 span[class*=icon]:first-child {
  color: var(--color60);
}
._outlined_1bob5_88 div > span[class*=icon]:first-child {
  background: var(--color10);
}
._raised_1bob5_141[class*=toast-container] > div[class*=toast] {
  background-color: var(--white);
  color: var(--color60);
  box-shadow: var(--shadow4dp);
}
._raised_1bob5_141[class*=toast] div[class*=button-group] svg {
  color: var(--color60);
  transition: all 180ms ease-in;
}
._raised_1bob5_141[class*=toast] div[class*=button-group] > button:hover svg,
._raised_1bob5_141 div[class*=toast] div[class*=button-group] > button[class*=button]:hover {
  color: var(--color90);
  background: none;
  transition: all 180ms ease-in;
}
._raised_1bob5_141[class*=toast] div[class*=button-group] > button[class*=button] {
  color: var(--color70) !important;
  background: var(--color5) !important;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  transition: all 180ms ease-in;
}
._raised_1bob5_141[class*=toast] div[class*=button-group] > button[class*=button]:hover {
  color: var(--color70) !important;
  background: var(--color5) !important;
  box-shadow: var(--shadow4dp) !important;
}
._raised_1bob5_141[class*=toast] div[class*=button-group] > button[class*=button]:active {
  box-shadow: none;
  background: var(--color10);
}
._raised_1bob5_141[class*=toast] svg,
._raised_1bob5_141[class*=toast] span[class*=icon]:first-child {
  color: var(--color60);
}
._raised_1bob5_141[class*=toast] div > span[class*=icon]:first-child {
  background: var(--color10);
}

/* temp_stylePlugin:ni:sha-256;WGYYv-1nnrqLdqUcezo3YVfB0DS50s0blNkus3CVnhQ */
._def_4ccho_2 > div[class*=bp4-popover2-arrow] {
  width: 21px;
  height: 21px;
}
._def_4ccho_2 > div[class*=bp4-popover2-arrow]::before {
  margin: 3px;
  border-radius: 0;
}

/* temp_stylePlugin:ni:sha-256;c2lAYwP2fS_G8p8hEX-7_KM8beFPKI8BVWaum-3GQtQ */
._filled_trbal_3 div[class*=bp4-popover2-content] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  background: var(--color70);
  color: var(--white);
}
._filled_trbal_3 div[class*=bp4-popover2-arrow] > svg > path:last-of-type {
  fill: var(--color70);
}
._filled_trbal_3 div[class*=bp4-popover2-arrow] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._filled_trbal_3 > div[class*=bp4-popover2-arrow]::before {
  box-shadow: none;
}
._filled_trbal_3 {
  box-shadow: var(--shadow8dp);
}
._smooth_trbal_32 div[class*=bp4-popover2-content] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  background: var(--color70);
  color: white;
}
._smooth_trbal_32 div[class*=bp4-popover2-arrow] > svg > path:last-of-type {
  fill: var(--color70);
}
._smooth_trbal_32 div[class*=bp4-popover2-arrow] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._smooth_trbal_32 > div[class*=bp4-popover2-arrow]::before {
  box-shadow: none;
}
._smooth_trbal_32 {
  box-shadow: none;
}
._raised_trbal_61 div[class*=bp4-popover2-content] {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.018em;
  color: var(--grey100);
  background: var(--color0);
  color: var(--color100);
}
._raised_trbal_61 div[class*=bp4-popover2-arrow] > svg > path:last-of-type {
  fill: var(--color0);
}
._raised_trbal_61 div[class*=bp4-popover2-arrow] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._raised_trbal_61 > div[class*=bp4-popover2-arrow]::before,
._raised_trbal_61 {
  box-shadow: var(--shadow4dp);
}

/* temp_stylePlugin:ni:sha-256;SJ47RVdw7lzEqFOl3yMk_oz_l1kxZyVr8EWVH6B5luI */
._def_ox70o_2 li[class*=tree-node] {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}
._dense_ox70o_9 li[class*=tree-node] {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}
._def_ox70o_2 li[class*=tree-node-expanded],
._dense_ox70o_9 li[class*=tree-node-expanded] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}
._def_ox70o_2 li[class*=selected],
._dense_ox70o_9 li[class*=selected] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}
._def_ox70o_2 li > div[class*=node-content] {
  min-height: 2.5rem;
  height: unset;
  margin-bottom: 0.25rem;
  box-shadow: none;
  border-radius: 0.25rem;
  transition: all 200ms ease-in-out;
}
._dense_ox70o_9 li > div[class*=node-content] {
  min-height: 1.75rem;
  height: unset;
  margin-bottom: 0.125rem;
  box-shadow: none;
  border-radius: 0.125rem;
  transition: all 200ms ease-in-out;
}
._def_ox70o_2 li > div[class*=node-content] > span[class*=node-caret]:not([class*=node-caret-none]) {
  width: 2rem;
  height: 2rem;
  min-width: unset;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  border-radius: 100%;
  padding: 0;
}
._dense_ox70o_9 li > div[class*=node-content] > span[class*=node-caret] {
  min-width: unset;
  margin-left: 0.375rem;
  margin-right: 0.5rem;
  padding: 0;
}
._def_ox70o_2 li > div[class*=node-content] > span[class*=node-caret] > svg,
._def_ox70o_2 span[class*=node-caret-none] ~ span[class*=node-icon] > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin: auto;
}
._def_ox70o_2 li > div[class*=node-content] > span[class*=node-caret] > svg {
  margin-top: 0.375rem;
}
._def_ox70o_2 span[class*=node-caret-none] {
  min-width: 44px;
}
._dense_ox70o_9 span[class*=node-caret-none] {
  margin-left: 0 !important;
}
._def_ox70o_2 div[with-secondary-label] {
  height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
._def_ox70o_2 div[with-secondary-label] > div {
  margin-top: 0.125rem;
}
._def_ox70o_2 li[class*=disabled] > div > span > div[with-secondary-label] > div {
}
._def_ox70o_2 li[class*=disabled] > div > span > div[with-secondary-label] > div > span {
  display: inline-block;
}
._def_ox70o_2 span[class*=node-secondary-label] > span[class*=icon] > svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* temp_stylePlugin:ni:sha-256;aTjurtlq9-dnhqwoQ_xM67DW0WnXRfodnOme2zknAZQ */
._flat_1clk0_2 {
  color: var(--color100);
}
._flat_1clk0_2 span > svg {
  color: var(--color50);
}
._flat_1clk0_2 li > div:first-of-type:hover {
  box-shadow: var(--shadow2dp);
  transition: all 150ms ease-in;
}
._flat_1clk0_2 li > div:hover {
  background-color: unset;
}
._flat_1clk0_2 li[class*=selected] > div:first-of-type {
  background-color: var(--color5);
  color: var(--color100);
  box-shadow: none;
  transition: all 150ms ease-in;
}
._flat_1clk0_2 li[class*=selected] svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}
._flat_1clk0_2 li > div:first-of-type:hover > span:first-of-type {
  background: var(--color10);
  transition: all 150ms ease-in;
}
._flat_1clk0_2[class*=dense] li > div:first-of-type:hover > span:first-of-type {
  background: none;
  transition: all 150ms ease-in;
}
._flat_1clk0_2 li:not([class*=disabled]) > div:first-of-type:hover > span[class*=node-caret]:first-of-type > svg {
  color: var(--color100);
  transition: all 150ms ease-in;
}
._flat_1clk0_2 li:hover > div:first-of-type > span[class*=none] {
  background: none;
  transition: all 150ms ease-in;
}
._flat_1clk0_2 li:hover {
  cursor: pointer;
}
._flat_1clk0_2 span[class*=node-caret-closed] > svg {
  color: var(--color40);
  transition: all 150ms ease-in;
}
._flat_1clk0_2 span[class*=node-caret-open] > svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}
._flat_1clk0_2 div[with-secondary-label] > div {
  color: var(--color60);
}
._flat_1clk0_2 li[class*=disabled],
._flat_1clk0_2 li[class*=disabled] > div:first-of-type,
._flat_1clk0_2 li[class*=disabled] > div:first-of-type:hover > span,
._flat_1clk0_2 li[class*=disabled] > div:first-of-type:hover > span > svg,
._flat_1clk0_2 li[class*=disabled] > div:first-of-type > span > svg {
  color: var(--color20);
  box-shadow: none;
  background: unset;
}
._flat_1clk0_2 li[class*=disabled] > div > span > div[with-secondary-label] > div {
  color: var(--yellow70);
}
._flat_1clk0_2 li[class*=disabled] > div > span > div[with-secondary-label] > div > span > svg {
  color: var(--yellow70);
}
