.dialog {
  width: 600px;
}

.targets {
  display: flex;
  align-items: center;

  * {
    margin-right: 16px;
  }
}
