.activity_header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    padding: 0;
    margin: 0;
  }
}

.search {
  margin-left: 8px;
}

.activityList {
  height: 370px;
  overflow: auto;
}

.activityWrapper {
  margin-bottom: 12px;

  &__hidden {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.comment_link {
  border-top: 1px solid var(--grey30);
  padding-top: 8px;
}
