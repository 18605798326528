@import "../../../../styles/font-mixins";

.entities {
  display: flex;
  flex-wrap: nowrap;
  color: var(--grey50);

  > :not(:last-child) {
    margin-right: 24px;
  }

  &:not(.edited) {
    margin-right: 48px;
  }

  &.edited {
    margin-right: 20px;
  }
}

.counter {
  svg {
    color: var(--grey50);
  }
}

.empty_counter {
  color: var(--grey30);
  svg {
    color: var(--grey30);
  }
}
