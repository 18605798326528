.no_access {
  color: var(--grey30);
  svg {
    color: var(--grey30);
  }
}

.cta:global(.bp4-button-group) {
  padding-left: 2px;

  :global(.bp4-button) {
    visibility: visible !important;
  }
}
