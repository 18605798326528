.selectable {
  user-select: text !important;
  cursor: text;
}

.spinner {
  display: flex;
}

.highlighted {
  color: var(--blue50);
}

.emphasis {
  color: var(--blue50);
}
