@import "../../../styles/font-mixins";

.centered {
  width: 100%;
  max-width: 800px;
  align-self: center;
}

.subtitle {
  @include subtitle;
  align-self: center;
}
