.lock_header {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: baseline;

  .lock_icon {
    margin-right: 7px;
  }

  &:not(:hover).lock_icon {
    color: var(--grey50);
  }
}
