@import "../../../../styles/font-mixins";

.row {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  height: 40px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .product {
    width: 190px;
    padding-right: 8px;
  }

  .passport {
    width: 240px;
  }

  .passportName {
    width: 210px;
    padding-right: 8px;
  }

  .product,
  .passport,
  .passportName {
    display: flex;
    align-items: center;
  }
}

.header {
  @include denseMedium;
  height: 23px;
  margin-bottom: 12px;

  .product {
    width: 208px;
  }

  .divider {
    margin: 0px 8px !important;
    height: 23px;
  }
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.validate {
    max-width: 170px;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.buttons {
  margin-left: 8px;
}

.card,
.callout {
  margin-bottom: 8px;
}

.no_parent {
  margin-top: 10px;
  @include inputDescriptionDense;
  color: var(--grey60);
}
