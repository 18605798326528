@import "../../../../styles/font-mixins";

.name,
.revision {
  padding: 8px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dividerColumn {
  width: 4px;
  padding: 0;
}

.name {
  flex: 3 1 350px;
}

.revision {
  flex: 1 1 120px;
}

.header {
  @include denseMedium;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.row {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: var(--grey0);
  border-radius: 5px;
  margin-bottom: 8px;
}

.divider {
  height: 24px;
}

.modify {
  margin: 0;
}
.link {
  display: flex;
}
