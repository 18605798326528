

.activities {
  margin-bottom: 64px;

  &__header {
    display: flex;
    margin-bottom: 16px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 8px;
  }
}

.tag {
  margin-right: 2px;
}
