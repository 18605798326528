@import "../../../../styles/font-mixins";

.row {
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.card {
  margin-bottom: 8px;
}

.name {
  color: var(--grey50);
  font-weight: 500;
  margin-left: 4px;
}
