.validation {
  display: flex;
  align-items: center;
  column-gap: 32px;

  .radio {
    display: flex;
    align-items: center;

    :global(.bp4-tag) {
      margin-left: 5px;
    }
  }
}

.dialog_contents {
  > :not(:last-child) {
    margin-bottom: 16px;
  }
}
