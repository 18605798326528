.dialog {
  width: 800px;
  max-width: 800px;

  .header {
    margin-top: 16px;
    margin-bottom: 4px;
  }

  .tab {
    background: white;
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid var(--grey20);
    margin-bottom: 16px;
  }
}

.row {
  display: flex;
  align-items: center;

  .disabled {
    opacity: 0.6;
  }
  .icon {
    margin-left: 5px;
  }
}
