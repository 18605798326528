.title {
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--blue100);
  margin-top: 16px;
  margin-bottom: 32px;
}

.icon {
  color: var(--blue30);
}
