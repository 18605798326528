.dialog {
  width: 700px;

  p {
    margin-bottom: 20px;
  }
}

.title {
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 7px;
  }
}
