.dialog {
  &:global(.bp4-dialog) {
    width: 60vw;
  }
}

.subtitle {
  margin-bottom: 16px;
}

.table {
  height: 40vh !important;
  overflow-y: auto;
}
