

.action_bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > :first-child {
    max-width: 350px;
    margin-right: 20px;
  }
}

.checkbox {
  width: 28px;
  padding-top: 15px;
  padding-left: 10px;

  > :first-child {
    margin-bottom: 0;
    margin-top: 3px;
  }
}

.checkboxHeader {
  width: 18px;
  margin-top: 10px;
  margin-left: 10px;
}

.name {
  flex: 2 1 22%;
}

.clientName {
  flex: 1 1 12%;
}

.stateName {
  flex: 1 1 12%;
}

.projectIteration {
  flex: 2 1 12%;
}

.options {
  flex: 3 1 32%;
  display: flex;

  &.bold {
    font-weight: 700;
    margin-top: 5px;
  }

  > :first-child {
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  :global(.bp4-tag) {
    margin: 0 1px;
  }

  :global(.bp4-text-overflow-ellipsis) {
    color: white;
  }
}

.configs {
  display: flex;
  align-items: center;
}

.visible_config {
  margin-right: 8px;
}

.hiddenConfigs {
  margin-left: auto;
}
