

.create_button {
  margin-top: 24px;
  display: flex;
}

.no_data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
