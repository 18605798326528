

.table {
  display: flex;
  flex-direction: column;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}

.name,
.project,
.iteration,
.client,
.state {
  flex: 2 1 20%;
}

.name {
  display: flex;
}

.select_cell {
  width: 40px;
  padding: 12px;
}

.filler {
  box-shadow: inset 0 -1px 0 0 rgba(16, 22, 26, 0.15);
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .passeportList {
    flex: 1;
  }
}

.searchbar {
  min-width: 140px;
}
