.editable {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 1px;

  :global(.bp4-button) {
    visibility: visible !important;
  }
}

.input {
  padding-left: 1px;
  margin-right: 8px;
}

.hover_button {
  transition: none !important;
  position: absolute;
  right: 5;
  top: 5;
}

.hover_button :global(.bp4-button) {
  visibility: visible !important;
}

.revision {
  display: flex;
  align-items: center;
  gap: 8px;
}

.revision_description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: var(--yellow50);
}

.dropdown {
  width: 100% !important;
}

.button_group {
  margin-left: 8px;
}
