@import "../../../../styles/font-mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  @include description;
  color: var(--grey50);
  svg {
    color: var(--grey50);
  }
}
