@import "../../../../styles/font-mixins";

.label {
  @include inputDescription;
}

.checkbox {
  margin-top: 8px;
}

.versionPicker {
  margin-top: 16px;
}
