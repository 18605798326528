@import "../../../../styles/font-mixins";

.revision_info {
  @include description;
  color: var(--grey50);
  align-self: center;
  flex-basis: 33%;
}

.revision_name {
  @include denseMedium;

  &:global(.bp4-editable-text) {
    @include denseMedium;
  }
}

.multi_select_wrapper {
  display: flex;
  margin-bottom: 16px;
}

.entities {
  margin-bottom: 16px;

  .entityTitle {
    @include inputDescription;
    color: var(--grey50);
    margin-bottom: 2px;
    display: flex;
    align-items: center;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.entity_list {
  width: 100%;
  align-items: center;
  background: var(--grey0);
  border-radius: 4px;
  margin-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;

  span,
  a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  :global(.bp4-button-group) {
    margin-left: auto;
  }
}

.entity_document {
  display: flex;
  gap: 8px;
}

.toggle_button {
  margin-right: 8px;
}

.unlink_menu_item {
  margin-top: 8px;
}
