.tag {
  display: flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  padding: 0 6px;

  background: var(--white);
  border: 1px solid var(--yellow30);
  border-radius: 99px;

  font-size: 12px;
  line-height: 126%;
  letter-spacing: 0.033em;

  color: var(--yellow70);
}
