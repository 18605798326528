@import "../../../../styles/font-mixins";

.container {
  padding: 0;
  margin-bottom: 30px;
}

.model_header {
  border-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 0;

  > :last-child {
    margin-left: auto;
  }
}

.model_header H3 {
  margin: 0;
}

.model_nomenclature {
  margin: 5px;
}

.action_col {
  width: 50px;
  padding: 5px 0;
  overflow: hidden;
  text-overflow: clip;

  div {
    display: flex;
    align-items: center;
  }
}

.task_table {
  td {
    background-color: white;
  }

  tr.drop_placeholder {
    background-color: var(--grey0);
    td {
      text-align: center;
      background-color: var(--grey0);
      border: none !important;
    }
  }
}

.new_process {
  td {
    border: none !important;
  }
}

.new_task {
  margin-top: 5px;
  span {
    @include button;
  }
}

.warning {
  margin-bottom: 10px;
}

.name_cell {
  border: none !important;
  padding: 6px 10px !important;
  display: flex;
  align-items: center;
}

.name_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  border-radius: 5px;
}

.drag_icon {
  margin-right: 15px;
  svg {
    color: var(--grey30);
  }
}

.color_icon {
  border-radius: 50px;
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.color_selector {
  margin-left: 8px;
}
