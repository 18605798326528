.list_wrapper {
  display: inline-flex;
  align-items: center;
}

.fill {
  width: 100%;
}

.initial.dense {
  margin-left: -18px;
}

.initial.small {
  margin-left: -8px;
}

.visible_users {
  cursor: default;
  margin-right: 10px;
  display: flex;
}

.hidden_users {
  margin-left: auto !important;
  margin-right: 10px;
}

.only_user,
.only_user :global(.bp4-popover2-target) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
