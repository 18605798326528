.cell {
  display: flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  width: 100%;
}

.revision_name {
  margin-right: 10px;
}

.hover_button:global(.bp4-button) {
  transition: none;
  position: absolute;
  right: 0;
}
