.menu {
  padding: 4px;
  max-height: 215px;
}

.link_callout {
  margin-top: 16px;
}

.dropdown_color {
  color: var(--blue40);
}
