@import "../../styles/font-mixins";

.filter_button:not(:global(.bp4-disabled)) span {
  color: var(--color100);
}

.filter_button svg {
  color: var(--color40);
}

.selectPart {
  &:global(.bp4-tag) {
    margin-left: 10px;
    background-color: var(--color70);

    span {
      color: var(--color5);
    }

    :global(.bp4-tag-remove) {
      opacity: 1 !important;

      svg {
        color: var(--color0) !important;
      }

      &:hover svg {
        opacity: 1 !important;
        color: var(--color40) !important;
      }
    }

    &:hover {
      background-color: var(--color70) !important;
    }
  }
}

.isDisabled {
  .selectPart {
    background-color: var(--color5);
    color: var(--color30);
  }

  path {
    fill: var(--color30);
  }
}

.menu {
  max-height: 500px;
  max-width: 274px;
}

.menuItem {
  display: flex;
  align-items: center;

  &__text {
    margin-left: 10px;
  }
}

.popover {
  &__inner {
    box-shadow: none;
    padding-top: 2px;

    li:not(:last-child) :global(.bp4-menu-item) {
      margin-bottom: 2px;
    }
  }

  &__search {
    padding: 8px;
  }

  &__searchContent {
    max-height: 430px;
    padding-bottom: 8px;
    overflow-x: auto;

    &.no_search {
      padding-top: 8px;
    }
  }
}

.textSeparator {
  padding: 15px;
  font-weight: 700;
}

.more_filters_description {
  @include componentDense;
  color: var(--color50);
  margin-bottom: 8px;
  width: 100%;
}

.more_filters_list {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 8px;
  }
}

.search {
  padding: 8px;
}

.text_filter {
  box-shadow: var(--blueShadow8dp);
  border-radius: 8px;
}
