.editable {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  :global(.bp4-button) {
    visibility: visible !important;
  }
}

.input {
  padding-left: 1px;
  margin-right: 8px;
}

.hover_button {
  transition: none !important;
  position: absolute;
  right: 5;
  top: 5;
}

.hover_button :global(.bp4-button) {
  visibility: visible !important;
}
