.picture {
  position: relative;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 0;
  }

  :global(.bp4-icon) {
    margin: auto;
    svg {
      color: var(--grey50);
    }
  }

  div {
    display: flex;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &:not(:hover) {
    div {
      :global(.bp4-icon) {
        opacity: 0;
      }
    }
  }
}

.picture.overlay {
  &:hover {
    div {
      cursor: pointer;
      background-color: var(--grey10);
      opacity: 0.6;
    }
  }
}
