

.picture {
  position: relative;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 0;
  }

  :global(.bp4-icon) {
    margin: auto;
  }

  div {
    display: flex;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &:not(:hover) {
    div {
      :global(.bp4-icon) {
        opacity: 0;
      }
    }
  }
}

.picture.can_update {
  &:hover {
    div {
      cursor: pointer;
      background-color: var(--grey40);
      opacity: 0.6;
    }
  }
}

.placeholder {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: var(--grey40);
  color: white;
  margin-right: 8px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;

  > * {
    margin: auto;
  }
}

.hidden {
  display: none;
}
