@import "../../../styles/font-mixins";

.button:global(.bp4-button) {
  @include buttonDense;

  // Normal
  --blue100: var(--blue0);
  --color70: var(--blue0);
  --color5: var(--aletiq90);

  // Hover
  --color10: var(--blue80);
  --color80: var(--blue0);

  // Active
  --color20: var(--aletiq90);

  &:global(.bp4-active) {
    background-color: var(--color10);
  }
}
