@import "../../../../styles/font-mixins";

.container {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 4px;
  text-align: left;

  td,
  th {
    padding: 0 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    height: 20px;
    @include denseMedium;
    border-left: solid 2px var(--grey10);
  }

  tr td:first-child {
    border-radius: 5px 0 0 5px;
  }

  tr td:last-child {
    border-radius: 0 5px 5px 0;
  }

  td {
    height: 40px;
    font-size: 14px;
    background-color: white;
  }
}

.container:not(.edited) {
  td:first-child {
    border-left: solid 8px var(--dashBlue);
  }

  &.workflow-1 td:first-child {
    border-color: var(--workflow1);
  }

  &.workflow-2 td:first-child {
    border-color: var(--workflow2);
  }

  &.workflow-3 td:first-child {
    border-color: var(--workflow3);
  }

  &.workflow-4 td:first-child {
    border-color: var(--workflow4);
  }

  &.workflow-5 td:first-child {
    border-color: var(--workflow5);
  }

  &.workflow-6 td:first-child {
    border-color: var(--workflow6);
  }

  &.workflow7 td:first-child {
    border-color: var(--workflow7);
  }

  &.workflow-8 td:first-child {
    border-color: var(--workflow8);
  }

  &.workflow-9 td:first-child {
    border-color: var(--workflow9);
  }

  &.workflow-10 td:first-child {
    border-color: var(--workflow10);
  }

  &.workflow-default td:first-child {
    border-color: var(--grey70);
  }
}
