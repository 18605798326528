@import "../../styles/font-mixins";

.EditableProperty {
  @include paragraph;

  .container {
    display: flex;
    flex-grow: 1;
    .hover_button {
      height: 100%;
      display: flex;
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }

    .container__inner {
      transition: all 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
      flex-grow: 1;
    }
  }

  &__editable:hover .hover_button {
    opacity: 1;
  }
}

.inline {
  min-height: 32px;
}

.edit_row {
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .edit_buttons {
    margin-left: 10px;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;

  .tag {
    margin-right: 8px;
  }
}
