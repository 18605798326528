.bill_link {
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.warning {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: -4px;
  right: -12px;
  background-color: var(--red50);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
