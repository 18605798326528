.action_wrapper {
  h3 {
    padding: 0;
    margin: 0;
  }

  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1px;
  padding-top: 1px;
  margin-bottom: 16px;

  .search {
    margin-left: 8px;
  }
}

.card {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--grey0);
  border-radius: 5px;
  box-shadow: none;
  border: none;
  overflow-y: hidden;
}

.scroll {
  overflow-y: auto;
}

th.date_cell,
td.date_cell {
  width: 200px;
}

th.status_cell,
td.status_cell {
  width: 175px;
}

th.filler {
  border: none;
  padding: 0;
}
