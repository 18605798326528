.counts {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey40);
  margin-left: 8px;
}

.document_count {
  display: inline-flex;
  position: relative;
}

.warning {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: -4px;
  right: -8px;
  background-color: var(--red50);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
