.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.tile {
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table {
  display: inline-block;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
