.row_tag {
  display: flex;
  row-gap: 2px;
  column-gap: 2px;
  flex-wrap: wrap;
}

.edit_row {
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .edit_buttons {
    margin-left: 10px;
  }
}
