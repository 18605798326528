.owners {
  display: flex;
  align-items: center;
  width: 100%;
}

.button:global(.bp4-button) {
  padding-right: 0;

  &:hover,
  &:target,
  &:active {
    box-shadow: 0 0 0 1px var(--color70) !important;
  }

  &:focus {
    outline: none;
  }

  :global(.bp4-button-text) {
    width: 100%;
  }
}

.pseudo_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
