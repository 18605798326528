.bom {
  background-color: white;
  position: absolute;
  left: 4px;
  top: 8px;
  height: calc(100% - 24px);
  width: 25%;
  box-shadow: var(--blueShadow4dp);
  border-radius: 5px;
  overflow-y: auto;
}

.bom_item {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 4px 0;

  .checkbox:global(.bp4-checkbox) {
    margin-right: 8px;
  }
}

.bom_header {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.cross {
  &:not(:hover) svg {
    color: var(--blue30);
  }

  &:hover svg {
    color: var(--blue70);
  }
}

.tree {
  margin: 0 8px;
}
