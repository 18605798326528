.table_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.lock_header {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: baseline;

  .lock_icon {
    margin-right: 7px;
    margin-bottom: 5px;
  }

  &:not(:hover).lock_icon {
    color: var(--grey50);
  }
}
