

.page {
  margin: 5px;
  margin-top: 0px;
  padding-bottom: 16px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 82px repeat(2, 1fr);
  overflow-y: hidden;
}

.tasks,
.shortcuts,
.activities {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.tasks {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row: 2;
  padding-bottom: 16px;
}

.shortcuts {
  padding-top: 16px;
  grid-column: 2;
  grid-row: 3;
  padding-left: 16px;
}

.activities {
  padding-top: 16px;
  grid-column: 1;
  grid-row: 3;
  padding-right: 16px;
}

div.card:global(.bp4-card) {
  border: none;
  box-shadow: none;
  background-color: var(--grey0);

  table tbody tr {
    td {
      background-color: white !important;
    }
  }
}