.form {
  width: 1000px;
}

.caption {
  margin-bottom: 16px;
}

.table {
  height: 300px;
}
