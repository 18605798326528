.dialog_contents {
  padding: 1px;
  height: calc(100% - 90px);
  // remove height of filter bar, description & pagination
}

.description {
  margin-bottom: 16px;
}

.document_name {
  color: var(--blue50);
}

.columns {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;

  .preview {
    max-width: 50%;
    flex-grow: 1;
  }

  .table_wrapper {
    width: 100%;
    min-width: 50%;
  }
}
