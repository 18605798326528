.flex {
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  .input,
  .select {
    width: 50%;
  }

  .input {
    padding-right: 8px;
  }
}

.active {
  width: 70px;
}

.default {
  width: 100px;
}

.delete {
  width: 50px;
}

.callout {
  margin-bottom: 16px;
}
