.dialog {
  max-height: calc(100vh - 60px);
  width: max-content;
  max-width: calc(100% - 60px);
}

.dialog_contents {
  overflow: auto;
}

.image {
  width: 100%;
  height: auto;
}
