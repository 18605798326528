@import "../../../styles/font-mixins";

.properties {
  border-radius: 4px;
  background-color: var(--grey0);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 8px;
}

.property {
  min-height: 32px;
}
