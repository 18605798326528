@import "../../../styles/font-mixins";

.subtitle {
  @include subtitle;
}

.hover_button:global(.bp4-button) {
  transition: none;
}

.link {
  display: flex;
  align-items: center;
}

.no_data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create_button {
  margin-top: 24px;
  display: flex;
}
