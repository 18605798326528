.container {
  height: 100vh;
  background-color: white;
  overflow: hidden;
}

.content {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
}

.panel {
  flex: none;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 auto;
  padding: 0;
  min-height: 100%;
}
