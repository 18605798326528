.checkbox_reset:global(.bp4-control):not(:global(.bp4-align-right)) {
  padding-left: 0px;
  margin-bottom: initial;

  :global(.bp4-control-indicator) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }
}
