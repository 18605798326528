.target {
  display: flex;

  > * + * {
    margin-left: 8px;
  }

  :global(.bp4-tag) svg {
    color: var(--grey70);
  }
}
