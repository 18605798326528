span.inline:global(.bp4-popover2-target) {
  display: inline;
  position: relative;
}

.relative {
  position: relative;
}

.icon {
  position: absolute;
  top: -2px;
  left: 0px;
}

.text {
  margin-left: 23px;
}
