.table_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  margin-bottom: 10px;
}

.tile {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.searchbar {
  min-width: 140px;
}
