.owners {
  width: 100%;
}

.content {
  max-height: 300px;
  overflow-y: auto;
}

.search {
  padding: 8px 8px 0 8px;
}

.current_user {
  display: flex;
  align-items: center;

  > :last-child {
    display: flex;
    align-items: center;
    margin-left: 7px;
  }
}
