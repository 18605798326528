@import "../../../../styles/font-mixins";

.dialog:global(.bp4-dialog) {
  width: 80vw;
}

.menu_item {
  display: flex;
  justify-content: space-between;
}

.selected_revision {
  margin: 0;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}

.table {
  height: 40vh !important;
}

.expand_icon {
  cursor: pointer;
}

.revision_name {
  margin-left: 8px;
}

.name_used {
  @include subtitle;
  color: var(--red50);
  margin-left: 10px;
}

.revision_cell {
  display: flex;
  align-items: center;
  padding-left: 2px;

  :global(.bp4-button) {
    visibility: visible !important;
  }
}

.private_part {
  color: var(--grey30);
  svg {
    color: var(--grey30);
  }
}
