@import "../../styles/font-mixins";

.revision_item {
  margin: 0 10px;
  border-radius: 4px;
  background-color: var(--grey0);
  margin: 0;
  margin-bottom: 8px;
  padding: 12px;
}

.revision_header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14;

  > :not(:last-child) {
    margin-right: 10px;
  }

  > span {
    display: flex;
    align-items: flex-start;

    > :not(:last-child) {
      margin-right: 10px;
    }
  }
}

.revision_info {
  @include description;
  color: var(--grey50);
  margin-bottom: 8px;
}

.duplicate_name {
  color: var(--red60);

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  em {
    font-weight: 400;
  }

  :global(.bp4-editable-text) {
    @include denseMedium;
    color: var(--red60);
  }
}

.revision_name {
  @include denseMedium;

  &:global(.bp4-editable-text) {
    @include denseMedium;
  }
}
