@import "../../styles/font-mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tile {
  flex: 1 1 auto;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 8px !important;
}

.spin_tile {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-bottom: 0%;
  z-index: 10;
  opacity: 0.7;
  background-color: white;
}

.table {
  position: relative;
  min-width: 100%;
  max-width: 500%;
  height: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.tbody {
  flex: 1 1;
  flex-basis: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: fit-content;

  &.no_data {
    background-color: var(--grey0);
  }
}

.thead {
  overflow-y: visible;
  width: 100%;
  min-width: fit-content;
  cursor: pointer;
  margin: auto;
  :global(.bp4-button) {
    visibility: visible !important;
  }
}

.th,
.td {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: baseline;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.column_title {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding-right: 30px;
  position: relative;
}

.column_title_wrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

.cell_text {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;

  &.full_width_content {
    display: flex;
    width: 100%;
  }
}

.tr {
  &:hover {
    .td:not(.sub_row):not(.first_row) {
      box-shadow: inset 0px -1px 0px var(--grey40),
        inset 0px 1px 0px var(--grey10) !important;
    }

    .td.sub_row,
    .td.first_row {
      box-shadow: inset 0px -1px 0px var(--grey40) !important;
    }
  }

  &:not(:hover) {
    :global(.bp4-button) {
      visibility: hidden;
    }
  }
}

.tr:hover + .tr {
  .td {
    box-shadow: none !important;
  }
}

.td {
  &.active {
    background-color: var(--blue0);
  }

  &:not(.sub_row):not(.first_row) {
    box-shadow: inset 0px 1px 0px var(--grey10);
  }

  &:not(:hover) {
    :global(.bp4-button):not(.open_button) {
      visibility: hidden;
    }
  }
}

.th,
.th_filler {
  &.isSorted {
    background-color: var(--grey10);
    color: var(--grey100);

    :global(.bp4-icon) svg {
      color: var(--grey100);
    }
  }

  &:not(.isSorted) {
    :global(.bp4-icon) svg {
      color: var(--grey30);
    }
  }

  &:not(.action_header):not(.sort_icon):hover {
    color: var(--grey100) !important;
  }

  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid var(--grey10) !important;
  background-color: var(--grey0);

  :global(.bp4-icon) svg {
    color: var(--grey30);
  }

  .sort_icon {
    margin-left: 10px;
    position: absolute;
    right: 5px;
    bottom: 4px;
  }
}

.resizer_bar {
  width: 2px;
  height: 100%;
  background-color: var(--grey10);

  &.invisible {
    background-color: transparent;
  }
}

.resizer_wrapper {
  padding-left: 10px;
  background-color: transparent;
  width: 25px;
  height: 100%;
  position: absolute;
  right: 0;
  transform: translateX(50%);
  z-index: 1;

  &:hover .resizer_bar:not(.invisible) {
    background-color: var(--grey50);
  }
}

.th.action_header {
  width: 100% !important;
  display: flex;

  > :not(:last-child) {
    margin-right: 10px;
  }

  :global(.bp4-button) {
    @include buttonDense;
  }

  :global(.bp4-icon) svg {
    color: var(--grey70);
  }
}

.th_filler.action_header {
  background-color: var(--grey0);
}

.open_button,
.hover_button {
  position: absolute;
  right: 5px;

  &:global(.bp4-button),
  :global(.bp4-button) {
    transition: none;
    box-shadow: 0px 1px 2px rgba(92, 99, 220, 0.24),
      0px 2px 4px rgba(92, 99, 220, 0.24);
  }
}
