@import "../../../styles/font-mixins";

.card_title {
  @include H2;
  color: var(--grey100);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 8px 8px 0;
}
