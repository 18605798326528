@import "../../../styles/font-mixins";

.spaceIcon {
  margin-left: 10px;
}

.drawer {
  padding: 0 16px;

  :global(.bp4-tab-panel) {
    margin-top: 16px;
  }

  .divider {
    margin: 16px 0 !important;
  }
}

.property {
  min-height: 32px;
  flex-wrap: nowrap;
}

.deleted {
  color: var(--grey60);
}

.flex {
  display: flex !important;
  align-items: center;
  vertical-align: middle;
  overflow: hidden;
}

.tabs {
  margin-top: 16px;

  :global(.bp4-tabs) {
    width: auto;
  }
}

.tag_list {
  display: flex;
  row-gap: 2px;
  column-gap: 2px;
}
